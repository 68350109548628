import React, { FC } from "react";
import ServerErrorModal from "./server-error-modal-body.component";

const PartnerModalSwitch: FC<Props> = ({}): JSX.Element => {
    return (
        <div>
            <ServerErrorModal />
        </div>
    );
};

export default PartnerModalSwitch;
