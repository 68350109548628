export const getBranch = () => {
    switch (process.env.ENV) {
        case "prd":
            return "main";
        case "stg":
            return "stage";
        case "dev":
            return "develop";
        case "local":
            return "local";
        default:
            return process.env.BRANCH;
    }
};
