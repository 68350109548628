import React, { useEffect, useContext, FC } from "react";
import styled from "styled-components";
import ClubCard from "./ClubCard.component";
import RostersContext, { RostersActions } from "./../../contexts/rosters/rosters.context";
import { getLookupValue } from "./../../contexts/LookupsContext";
import { LookupApi } from "../../api/LookupApi";
import PartnerLeagueApi from "../../api/PartnerLeagueApi";
import RosterTable from "./roster-table.component";
import { useAuth } from "../../contexts/AuthContext";
import { Input, PrimaryButton } from "best-common-react";
import RequiredInputWithLabelStyled from "../common/required-input-with-label-styled.component";
import { LeagueContactInfo } from "../../models/rosters/LeagueContactInfo.model";

interface Props {
    currentLeagueId: number;
}

const Rosters: FC<Props> = ({ currentLeagueId }): JSX.Element => {
    const { state, dispatchRosters } = useContext(RostersContext);

    const { partnerUserDetails, canEditLeagueId } = useAuth();

    const { formDirty } = state;

    const PrimaryButtonStyled = styled(PrimaryButton)`
        &&&& {
            font-family: Helvetica;
            font-size: 14px;
            font-weight: bold;
            margin-top: 30px;
            height: 26px;
            color: ${props => (props.disabled ? props.theme["LighterBlue"] : "#ffffff")};
            background-color: ${props => (props.disabled ? props.theme["LightGray"] : props.theme["LightBlue"])}};
            padding: 1px 15px 0px 15px !important;
            border-radius: 6px;
            border: solid 1px ${props => (props.disabled ? props.theme["LighterBlue"] : props.theme["LightBlue"])}};
        }
    `;

    useEffect(() => {
        if (currentLeagueId !== null && currentLeagueId !== undefined) {
            Promise.all([
                LookupApi.getLookups("PARTNER_DIVISION"),
                PartnerLeagueApi.getRosters(currentLeagueId),
                LookupApi.getParentLookup("PARTNER_LEAGUE_CLUB", currentLeagueId),
            ]).then(([lookups, rosters, clubs]) => {
                if (currentLeagueId !== 0) {
                    let initialClub = clubs[0];
                    if (partnerUserDetails.isClubUser && partnerUserDetails.clubIds.length > 0) {
                        const initialClubList = clubs.filter(club => Number(club.id) === partnerUserDetails.clubIds[0]);
                        if (initialClubList.length > 0) {
                            initialClub = initialClubList[0];
                        }
                    }
                    dispatchRosters({
                        type: RostersActions.INIT,
                        divisionLkup: lookups["PARTNER_DIVISION"],
                        rosters: rosters,
                        clubLookup: clubs,
                        selectedClub: initialClub,
                        userCanEditContactInfo:
                            partnerUserDetails.isEditor &&
                            partnerUserDetails.isLeaguePresident &&
                            canEditLeagueId(Number(currentLeagueId)),
                    });
                }
            });
        }
    }, []);

    const DivisionHeader = styled.h5`
        color: ${props => props.theme["Gray"]};
        font-family: Helvetica;
        font-weight: 300;
    `;

    const saveContactInfo = (info: LeagueContactInfo) => {
        PartnerLeagueApi.updateLeagueContactInfo(currentLeagueId, info).then(savedPlayer => {
            dispatchRosters({
                type: RostersActions.CONTACT_SAVED,
                value: savedPlayer,
            });
        });
    };

    const isUpdateContactInfoButtonDisabled = (currentState: LeagueContactInfo): boolean => {
        return (
            currentState.contactName === null ||
            currentState.contactName === "" ||
            currentState.contactEmail === null ||
            currentState.contactEmail === "" ||
            currentState.contactPhone === null ||
            currentState.contactPhone === ""
        );
    };

    function renderClubCards(clubs) {
        const list = [];

        for (const club of clubs) {
            list.push(
                <ClubCard
                    key={club.clubInfo.clubInfoId}
                    club={club}
                    selectClub={() => {
                        const selectedClub = { id: club.clubInfo.clubInfoId, label: club.clubInfo.clubName };
                        dispatchRosters({ type: RostersActions.SET_SELECTED_CLUB, selectedClub: selectedClub });
                    }}
                    className="col-12 col-md-6 col-lg-4 col-xl-3"
                />,
            );
        }

        return list;
    }

    function renderDivision() {
        const list = [];

        if (state.rosters.clubs) {
            for (const [divisionId, club] of Object.entries(state.rosters.clubs)) {
                list.push(
                    <div className="mt-2 mb-4" key={divisionId}>
                        <DivisionHeader>{getLookupValue(divisionId, state.divisionLkup)}</DivisionHeader>
                        <div className="d-flex row mx-0">{renderClubCards(club)}</div>
                    </div>,
                );
            }
        }

        return list;
    }

    function renderUpdateButton() {
        if (state.userCanEditContactInfo) {
            return (
                <div>
                    <PrimaryButtonStyled
                        disabled={!formDirty || isUpdateContactInfoButtonDisabled(state.rosters.contactInfo)}
                        onClick={() => saveContactInfo(state.rosters.contactInfo)}
                    >
                        Update Contact Info
                    </PrimaryButtonStyled>
                </div>
            );
        } else {
            return <div />;
        }
    }

    function renderContactInfo() {
        return (
            <div className="pl-3 d-flex flex-column">
                <div className="d-flex row mx-0">
                    {/* contact Name */}
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 pl-0 mb-1 mb-lg-2">
                        <RequiredInputWithLabelStyled
                            name="input"
                            label="League Contact Name"
                            required={state.userCanEditContactInfo}
                        >
                            <Input
                                style={{ color: "#495057" }}
                                type="text"
                                value={
                                    state.rosters.contactInfo.contactName !== null
                                        ? state.rosters.contactInfo.contactName
                                        : ""
                                }
                                onChange={event => {
                                    dispatchRosters({
                                        type: RostersActions.DATA_CHANGED,
                                        value: { contactName: event.target.value },
                                    });
                                }}
                                disabled={!state.userCanEditContactInfo}
                            />
                        </RequiredInputWithLabelStyled>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 pl-0 mb-1 mb-lg-2">
                        {/* contact Email */}
                        <RequiredInputWithLabelStyled
                            name="input"
                            label="League Contact Email"
                            required={state.userCanEditContactInfo}
                        >
                            <Input
                                style={{ color: "#495057" }}
                                type="text"
                                value={
                                    state.rosters.contactInfo.contactEmail !== null
                                        ? state.rosters.contactInfo.contactEmail
                                        : ""
                                }
                                onChange={event => {
                                    dispatchRosters({
                                        type: RostersActions.DATA_CHANGED,
                                        value: { contactEmail: event.target.value },
                                    });
                                }}
                                disabled={!state.userCanEditContactInfo}
                            />
                        </RequiredInputWithLabelStyled>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 pl-0 mb-1 mb-lg-2">
                        {/* contact Phone */}
                        <RequiredInputWithLabelStyled
                            name="input"
                            label="League Contact Phone Number"
                            required={state.userCanEditContactInfo}
                        >
                            <Input
                                style={{ color: "#495057" }}
                                type="text"
                                value={
                                    state.rosters.contactInfo.contactPhone !== null
                                        ? state.rosters.contactInfo.contactPhone
                                        : ""
                                }
                                onChange={event => {
                                    dispatchRosters({
                                        type: RostersActions.DATA_CHANGED,
                                        value: { contactPhone: event.target.value },
                                    });
                                }}
                                disabled={!state.userCanEditContactInfo}
                            />
                        </RequiredInputWithLabelStyled>
                    </div>
                    {renderUpdateButton()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderContactInfo()}
            <div className="pl-3 d-flex flex-column">{renderDivision()}</div>
            <RosterTable currentLeagueId={currentLeagueId} />
        </div>
    );
};

export default Rosters;
