import React from "react";
import NewPlayerProvider from "./../../providers/newplayer/newplayer.provider";
import NewPlayer from "./newplayer.component";

const NewPlayerContainer = ({ currentLeagueId }) => {
    return (
        <NewPlayerProvider>
            <NewPlayer currentLeagueId={currentLeagueId} />
        </NewPlayerProvider>
    );
};

export default NewPlayerContainer;
