import React, { useReducer } from "react";
import PropTypes from "prop-types";
import RostersContext, {
    initialState,
    RostersAction,
    RostersActions,
    RostersState,
} from "../../contexts/rosters/rosters.context";

const RostersProvider: React.FC = ({ children }) => {
    function flagSelected(rosters, selectedClubId) {
        for (const clubs in rosters.clubs) {
            for (const club in rosters.clubs[clubs]) {
                const theClub = rosters.clubs[clubs][club];
                theClub.isSelected = parseInt(selectedClubId) === parseInt(theClub.clubInfo.clubInfoId);
            }
        }
    }

    const reducer: React.Reducer<RostersState, RostersAction> = (state, action) => {
        switch (action.type) {
            case RostersActions.INIT:
                flagSelected(action.rosters, action.selectedClub.id);

                return {
                    ...state,
                    divisionLkup: action.divisionLkup,
                    rosters: action.rosters,
                    clubLookup: action.clubLookup,
                    selectedClub: action.selectedClub,
                    userCanEditContactInfo: action.userCanEditContactInfo,
                };

            case RostersActions.SET_SELECTED_CLUB:
                flagSelected(state.rosters, action.selectedClub.id);

                return {
                    ...state,
                    rosters: state.rosters,
                    selectedClub: action.selectedClub,
                };
            case RostersActions.SET_SELECTED_CLUB_ROSTER:
                return {
                    ...state,
                    selectedClubRoster: action.selectedClubRoster,
                };
            case RostersActions.SET_SELECTED_PLAYER:
                return {
                    ...state,
                    selectedPlayer: action.selectedPlayer,
                };
            case RostersActions.TOGGLE_REMOVE_PLAYER_MODAL:
                return {
                    ...state,
                    showRemovePlayerModal: action.showRemovePlayerModal,
                    selectedPlayer: action.selectedPlayer,
                };
            case RostersActions.DATA_CHANGED:
                state.rosters.contactInfo = { ...state.rosters.contactInfo, ...action.value };
                return { ...state, formDirty: true };
            case RostersActions.CONTACT_SAVED:
                state.rosters.contactInfo = action.value;
                return { ...state, formDirty: false };
            default:
                return { ...state };
        }
    };

    const [state, dispatchRosters] = useReducer(reducer, initialState);

    return <RostersContext.Provider value={{ state, dispatchRosters }}>{children}</RostersContext.Provider>;
};

RostersProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default RostersProvider;
