import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import config from "../config";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { UsersApi } from "../api/UsersApi";
import { RoleConstants } from "../constants/RoleConstants";
import { LookupApi } from "../api/LookupApi";
import { Lookup } from "../models/lookups/lookups.models";
import { datadogRum } from "@datadog/browser-rum";

type AccessToken = {
    accessToken: { accessToken: string };
};

type UserInfo = {
    accessToken: { accessToken: string };
    userName: string;
    userFullName: string;
    email: string;
    orgId: number;
    orgName: string;
    orgShortName: string;
    roles: Array<string>;
    userId: number;
    permissions: Array<string>;
};

// Type for passing around role information in one object
type PartnerUserDetails = {
    isEditor: boolean;
    isLeaguePresident: boolean;
    isBoc: boolean;
    isClubUser: boolean;
    leagueIds: Array<number>;
    clubIds: Array<number>;
};

type UserContext = {
    userInfo: UserInfo;
    partnerUserDetails: PartnerUserDetails;
    loggedIn: boolean;
    login: (data: UserInfo) => void;
    logout: () => void;
    permissionError: boolean;
    loginFunction: SetStateAction<void>;
    setPermissionError: Dispatch<SetStateAction<boolean>>;
    setLoginFunction: Dispatch<SetStateAction<void>>;
    getUserDetails: (userData: UserInfo, lookups: Map<string, Array<Lookup>>) => PartnerUserDetails;
    userId: () => string;
    oktaLoginFunc: { loginFunction: Function };
    setOktaLoginFunc: Dispatch<SetStateAction<Function>>;
    canEditLeagueId: (leagueId: number) => boolean;
    canEditClubId: (clubId: number) => boolean;
    canViewLeagueId: (leagueId: number) => boolean;
    canViewClubId: (clubId: number) => boolean;
};

const AuthContext = createContext<UserContext>({
    userInfo: null,
    partnerUserDetails: null,
    loggedIn: false,
    login: null,
    logout: null,
    permissionError: false,
    loginFunction: null,
    setPermissionError: null,
    setLoginFunction: null,
    getUserDetails: null,
    userId: null,
    oktaLoginFunc: null,
    setOktaLoginFunc: null,
    canEditLeagueId: null,
    canEditClubId: null,
    canViewLeagueId: null,
    canViewClubId: null,
});

const initialState = {
    userInfo: {
        accessToken: { accessToken: "" },
        userName: "",
        userFullName: "",
        email: "",
        orgId: 0,
        orgName: "",
        orgShortName: "",
        permissions: ["NONE"],
        roles: ["NONE"],
        userId: 0,
    },
    userRoleInfo: {
        isEditor: false,
        isLeaguePresident: false,
        isBoc: false,
        isClubUser: false,
        leagueIds: null,
        clubIds: null,
    },
    loggedIn: false,
};

const oktaAuth = new OktaAuth(config.oidc);

const AuthProvider: React.FC = ({ children }) => {
    const [userState, setUserState] = useState({
        userInfo: initialState.userInfo,
        userRoleInfo: initialState.userRoleInfo,
    });
    const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
    const [permissionError, setPermissionError] = useState<boolean>(false);
    const [loginFunction, setLoginFunction] = useState(null);
    const [oktaLoginFunc, setOktaLoginFunc] = useState(null);
    const { authState } = useOktaAuth();

    const userId = () => {
        return userState.userInfo.userName;
    };

    const canEditLeagueId = (leagueId: number): boolean => {
        if (!leagueId || leagueId === null) {
            return false;
        }
        const userRoleInfo: PartnerUserDetails = userState.userRoleInfo;

        return (userRoleInfo.isLeaguePresident && userRoleInfo.leagueIds.indexOf(leagueId) >= 0) || userRoleInfo.isBoc;
    };

    const canEditClubId = (clubId: number): boolean => {
        if (!clubId || clubId === null) {
            return false;
        }
        const userRoleInfo: PartnerUserDetails = userState.userRoleInfo;

        return userRoleInfo.isEditor && (userRoleInfo.isBoc || userRoleInfo.clubIds.indexOf(clubId) >= 0);
    };

    const canViewClubId = (clubId: number): boolean => {
        return canEditClubId(clubId) || userState.userRoleInfo.clubIds.indexOf(clubId) >= 0;
    };

    const canViewLeagueId = (leagueId: number): boolean => {
        return canEditLeagueId(leagueId) || userState.userRoleInfo.leagueIds.indexOf(leagueId) >= 0;
    };

    const getUserDetails = (userData: UserInfo, lookups: Map<string, Array<Lookup>>): PartnerUserDetails => {
        const leagueIds: Array<number> = [];
        const clubIds: Array<number> = [];
        const clubCodes = lookups["PARTNER_LEAGUE_CLUB_CODES"];
        const leagueCodes = lookups["PARTNER_LEAGUE_CODES"];
        const clubToLeagueMap = lookups["PARTNER_LEAGUE_CLUB_MAP"];
        if (userData.roles.indexOf(RoleConstants.CLUB_ADMIN) || userData.roles.indexOf(RoleConstants.CLUB_VIEWER)) {
            //get the user's club ids from their roles
            userData.roles.forEach((role: string) => {
                if (role.startsWith(RoleConstants.CLUB_PREFIX)) {
                    const usersClubCode: string = role.substring(RoleConstants.CLUB_PREFIX.length);
                    clubCodes.forEach((club: Lookup) => {
                        if (club.value === usersClubCode) {
                            clubIds.push(parseInt(club.id));
                            clubToLeagueMap.forEach((clubMapping: Lookup) => {
                                if (clubMapping.id === club.id) {
                                    leagueIds.push(parseInt(clubMapping.value));
                                }
                            });
                        }
                    });
                }
            });
        }

        if (userData.roles.indexOf(RoleConstants.LEAGUE_PRESIDENT)) {
            if (userData.roles.indexOf(RoleConstants.BOC) === -1) {
                userData.roles.forEach((role: string) => {
                    if (role.startsWith(RoleConstants.PRESIDENT_PREFIX)) {
                        //add all the leagues this user is president of
                        const usersLeagueCode = role.substring(RoleConstants.PRESIDENT_PREFIX.length);
                        leagueCodes.forEach((league: Lookup) => {
                            if (league.value === usersLeagueCode) {
                                leagueIds.push(parseInt(league.id));
                                //add all the club ids in this league Id
                                clubToLeagueMap.forEach((clubMapping: Lookup) => {
                                    if (clubMapping.value === league.id) {
                                        clubIds.push(parseInt(clubMapping.id));
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }

        return {
            isEditor:
                userData.roles.indexOf(RoleConstants.LEAGUE_PRESIDENT) >= 0 ||
                userData.roles.indexOf(RoleConstants.CLUB_ADMIN) >= 0,
            isLeaguePresident: userData.roles.indexOf(RoleConstants.LEAGUE_PRESIDENT) >= 0,
            isBoc: userData.roles.indexOf(RoleConstants.BOC) >= 0,
            isClubUser:
                userData.roles.indexOf(RoleConstants.CLUB_ADMIN) >= 0 ||
                userData.roles.indexOf(RoleConstants.CLUB_VIEWER) >= 0,
            leagueIds: leagueIds,
            clubIds: clubIds,
        };
    };

    const login = async (accessToken: AccessToken): Promise<void> => {
        const userInfoData = await UsersApi.getUserInfo();
        const lookups = await LookupApi.getLookups(
            "PARTNER_LEAGUE_CLUB_CODES,PARTNER_LEAGUE_CODES,PARTNER_LEAGUE_CLUB_MAP",
        );

        if (userInfoData) {
            const userData = { ...accessToken, ...userInfoData };
            setUserState({
                userInfo: userData,
                userRoleInfo: getUserDetails(userData, lookups),
            });
            datadogRum.setUser({
                id: userInfoData.userName,
                email: userInfoData.email,
                name: userInfoData.userName,
            });
            setLoggedIn(true);
        }
        setLoggedIn(true);
    };

    const logout = () => {
        localStorage.clear();
        setUserState({ userInfo: initialState.userInfo, userRoleInfo: initialState.userRoleInfo });
        setLoggedIn(false);
    };

    useEffect(() => {
        if (authState?.isAuthenticated) {
            // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
            login({ accessToken: authState.accessToken });
        }
    }, [authState]);

    return (
        <AuthContext.Provider
            value={{
                userInfo: userState.userInfo,
                partnerUserDetails: userState.userRoleInfo,
                loggedIn,
                login,
                logout,
                permissionError,
                setPermissionError,
                loginFunction,
                setLoginFunction,
                getUserDetails: getUserDetails,
                userId,
                oktaLoginFunc,
                setOktaLoginFunc,
                canEditLeagueId,
                canEditClubId,
                canViewClubId,
                canViewLeagueId,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): UserContext => {
    const authContext = useContext<UserContext>(AuthContext);
    if (authContext === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return authContext;
};

export { AuthContext, AuthProvider, oktaAuth, useAuth, PartnerUserDetails as UserRoleInfo, UserInfo };
