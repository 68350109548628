import React from "react";
import "./styles/app.scss";
import { Theme } from "./theme";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RouteConstants } from "./constants/RouteConstants";
import Login from "./components/login/login.component";
import ProtectedContents from "./components/login/ProtectedContents.component";
import { Security } from "@okta/okta-react";
import { AuthProvider, oktaAuth } from "./contexts/AuthContext";
import { createGlobalStyle } from "styled-components";
import HttpInterceptor from "./api/HttpInterceptor";
import { LoadingContextProvider } from "./contexts/LoadingContext";
import Alerts from "./modules/alerts/alerts";
import { AlertsProvider } from "./contexts/AlertsContext";
import PartnerLoadingBlocker from "./components/common/PartnerLoadingBlocker.component";
import { ModalProvider } from "./contexts/ModalContext";
import PartnerModalSwitch from "./components/common/partner-modal-switch.component";

const App: React.FC = () => {
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        window.location.replace(originalUri);
    };

    const GlobalStyle = createGlobalStyle`
        @font-face {
            font-family: 'Helvetica';
        }
    `;

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <GlobalStyle />
            <AuthProvider>
                <LoadingContextProvider>
                    <ModalProvider>
                        <HttpInterceptor>
                            <AlertsProvider>
                                <ThemeProvider theme={Theme}>
                                    <Alerts />
                                    <BrowserRouter>
                                        <PartnerModalSwitch />
                                        <PartnerLoadingBlocker />
                                        <ProtectedContents />
                                    </BrowserRouter>
                                </ThemeProvider>
                            </AlertsProvider>
                        </HttpInterceptor>
                    </ModalProvider>
                </LoadingContextProvider>
            </AuthProvider>
        </Security>
    );
};

export default App;
