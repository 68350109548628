import React, { useEffect, useContext, FC } from "react";

import styled, { ThemeContext } from "styled-components";
import { VerticalTable, PrimaryOutlineButton, Icon, AnchorLink, BestRadio } from "best-common-react";
import { useNewPlayer, NewPlayerActions } from "../../contexts/newplayer/newplayer.context";
import { formatDate, formatDateStr } from "../../modules/dates/dates";
import { Player } from "../../models/player/player.models";
import { MatchMetaData, PlayerMatch } from "./player-match.modal";
import { PartnerRosterApi } from "../../api/PartnerRosterApi";
import { useHistory } from "react-router";
import { RouteConstants } from "../../constants/RouteConstants";

interface Props {
    matchData: PlayerMatch[];
    matchMetaData: MatchMetaData;
}

const ModalText = styled.div`
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.71;
    color: ${props => props.theme["dark-grey"]};
`;

const TableWrapper = styled.div`
    padding-top: 15px;
    overflow-x: auto;
    table {
        table-layout: fixed;
    }
`;

const headers = [{ name: " " }, { name: "You Entered:" }, { name: "System Matches" }];

type Row = {
    name: string;
    key: string;
    cellOverride?: (data, rowIndex, colIndex) => JSX.Element;
};

const PlayerMatchModalBodyContent: FC<Props> = ({ matchData, matchMetaData }): JSX.Element => {
    const { newPlayerState: newPlayerState, dispatchNewPlayer } = useNewPlayer();
    const { hasEbisId, onEbisRoster, onPartnerRoster, hasHardMatch } = matchMetaData;
    const Theme = useContext(ThemeContext);
    const browserHistory = useHistory();
    function checkIfOnRoster(onRosterOf) {
        return onRosterOf && onRosterOf !== null && onRosterOf !== "";
    }

    const PlayerFormmatter = (playerId, fullName): JSX.Element => {
        if (!playerId) {
            return <div>{fullName}</div>;
        }
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", playerId);
        return <AnchorLink href={route}>{fullName}</AnchorLink>;
    };

    const rows: Array<Row> = [
        {
            name: "Match Percent",
            key: "matchPercent",
        },
        {
            name: "Team",
            key: "",
            cellOverride: (row, rowIndex, colIndex) =>
                hasHardMatch && colIndex === 0 ? (
                    <div />
                ) : !checkIfOnRoster(row.onRosterOf) ? (
                    <BestRadio
                        id="match"
                        onChange={() => dispatchNewPlayer({ type: NewPlayerActions.SELECT_MATCH, value: row.personId })}
                        checked={newPlayerState.selectedPlayerId === row.personId}
                    />
                ) : (
                    <div>{row.onRosterOf}</div>
                ),
        },
        {
            name: "Last, First",
            key: "fullName",
            cellOverride: (row, rowIndex, colIndex) => <>{PlayerFormmatter(row.personId, row.fullName)}</>,
        },
        {
            name: "Ext Last",
            key: "extendedLastName",
        },
        {
            name: "Middle",
            key: "middleName",
        },
        {
            name: "Birth Country",
            key: "birthCountry",
        },
        {
            name: "Birth Date",
            key: "",
            cellOverride: (row, rowIndex, colIndex) => <div>{formatDateStr(row.dateOfBirthAsString)}</div>,
        },
    ];

    if (hasEbisId) {
        rows.push({
            name: "EBIS ID",
            key: "personId",
        });
    }

    const TableStyle = {
        td: {
            backgroundColor: `${Theme.white} !important`,
            fontWeight: "normal !important",
        },
    };

    let message = "Is the player you're entering one of the players below?";

    if (matchMetaData.onEbisRoster || matchMetaData.onPartnerRoster) {
        message = "The player you're entering might already be active on a roster, are you sure you wish to continue?";
    }

    if (matchMetaData.hasHardMatch === true) {
        message = "The player you're entering already exists.";
    }

    return (
        <div>
            <ModalText>{message}</ModalText>
            <TableWrapper>
                <VerticalTable rows={rows} headers={headers} data={matchData} styles={TableStyle} />
            </TableWrapper>
        </div>
    );
};

export default PlayerMatchModalBodyContent;
