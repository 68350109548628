import React, { useReducer } from "react";
import PlayerProfileContext, {
    PlayerProfileState,
    PlayerProfileAction,
    PlayerProfileActions,
    initialState,
} from "./../../contexts/player-profile/player-profile.context";
import PropTypes from "prop-types";

const setLookupValues = (state: PlayerProfileState): void => {
    if (state.lookups) {
        if (state.player.birthCountryId && state.player.birthCountryId != null) {
            state.player.birthCountrySelected = state.lookups.COUNTRIES.find(
                e => parseInt(e.id) === state.player.birthCountryId,
            );
            const districtTypeId = parseInt(state.lookups.COUNTRY_ADDRESS_SUBLEVEL[state.player.birthCountryId].value);
            state.conditionalLookups.birthDistrictLabel = state.lookups.ADDRESS_SUBLEVELS[districtTypeId].value;

            state.conditionalLookups.hasSector = state.lookups.COUNTRIES_WITH_SECTOR.some(
                i => parseInt(i.id) === state.player.birthCountryId,
            );
            state.conditionalLookups.hasDropdown = state.lookups.COUNTRIES_WITH_DROPDOWNS.some(
                i => parseInt(i.id) === state.player.birthCountryId,
            );
        }

        if (state.player.birthStateId) {
            state.player.birthStateSelected = state.lookups.USSTATES.find(
                e => parseInt(e.id) === state.player.birthStateId,
            );
        }
        if (state.player.birthProvinceId) {
            state.player.birthProvinceSelected = state.lookups.PROVINCES.find(
                e => parseInt(e.id) === state.player.birthProvinceId,
            );
        }

        if (state.player.birthDistrictId) {
            state.player.birthDistrictSelected = state.lookups.DISTRICTS.find(
                e => parseInt(e.id) === state.player.birthDistrictId,
            );
        }

        if (state.player.birthCityId) {
            state.player.birthCitySelected = state.lookups.CITIES.find(
                e => parseInt(e.id) === state.player.birthCityId,
            );
        }

        if (state.player.birthSectorId) {
            state.player.birthSectorSelected = state.lookups.SECTORS.find(
                e => parseInt(e.id) === state.player.birthSectorId,
            );
        }

        if (state.player.playerPositionId && state.player.playerPositionId !== null) {
            state.player.playerPosition = state.lookups.PRO_PLAYER_POSITIONS.find(
                e => parseInt(e.id) === state.player.playerPositionId,
            );
        }

        if (state.player.height) {
            const f = Math.floor(state.player.height / 12);
            state.player.feetSelected = { value: f, label: f + " '", id: f };
            const i = state.player.height % 12;
            state.player.inchesSelected = { value: i, label: i + ' "', id: i };
        } else {
            state.player.feetSelected = { value: 4, label: 4 + " '", id: 4 };
            state.player.inchesSelected = { value: 0, label: 0 + ' "', id: 0 };
            state.player.height = 48;
        }

        if (state.player.primaryPhoneTypeId) {
            state.player.primaryPhoneType = state.lookups.PRO_PLAYER_PHONE_TYPES.find(
                e => parseInt(e.id) === state.player.primaryPhoneTypeId,
            );
        }

        if (state.player.primaryPhoneCountryId) {
            state.player.primaryPhoneCountry = state.lookups.COUNTRIES.find(
                e => parseInt(e.id) === state.player.primaryPhoneCountryId,
            );
        }

        if (state.player.primaryEmailTypeId) {
            state.player.primaryEmailType = state.lookups.EMAILTYPES.find(
                e => parseInt(e.id) === state.player.primaryEmailTypeId,
            );
        }

        if (state.player.primaryAddressTypeId) {
            state.player.primaryAddressType = state.lookups.PLAYERADDRESSTYPES.find(
                e => parseInt(e.id) === state.player.primaryAddressTypeId,
            );
        }

        if (state.player.primaryAddressCountryId) {
            state.player.primaryAddressCountry = state.lookups.COUNTRIES.find(
                e => parseInt(e.id) === state.player.primaryAddressCountryId,
            );
        }

        if (state.player.primaryAddressCountryId) {
            const districtTypeId = parseInt(
                state.lookups.COUNTRY_ADDRESS_SUBLEVEL[state.player.primaryAddressCountryId].value,
            );
            state.conditionalLookups.addressDistrictLabel = state.lookups.ADDRESS_SUBLEVELS[districtTypeId].value;

            state.conditionalLookups.addressHasSector = state.lookups.COUNTRIES_WITH_SECTOR.some(
                i => parseInt(i.id) === state.player.primaryAddressCountryId,
            );
            state.conditionalLookups.addressHasDropdown = state.lookups.COUNTRIES_WITH_DROPDOWNS.some(
                i => parseInt(i.id) === state.player.primaryAddressCountryId,
            );
            state.conditionalLookups.addressHasZip = state.lookups.COUNTRIES_WITH_ZIP.some(
                i => parseInt(i.id) === state.player.primaryAddressCountryId,
            );
        }

        if (state.player.primaryAddressDistrictId) {
            state.player.primaryAddressDistrictSelected = state.lookups.DISTRICTS.find(
                e => parseInt(e.id) === state.player.primaryAddressDistrictId,
            );
        }

        if (state.player.primaryAddressStateId) {
            state.player.primaryAddressStateSelected = state.lookups.USSTATES.find(
                e => parseInt(e.id) === state.player.primaryAddressStateId,
            );
        }

        if (state.player.primaryAddressProvinceId) {
            state.player.primaryAddressProvinceSelected = state.lookups.PROVINCES.find(
                e => parseInt(e.id) === state.player.primaryAddressProvinceId,
            );
        }

        if (state.player.primaryAddressCityId) {
            state.player.primaryAddressCitySelected = state.lookups.CITIES.find(
                e => parseInt(e.id) === state.player.primaryAddressCityId,
            );
        }

        if (state.player.primaryAddressSectorId) {
            state.player.primaryAddressSectorSelected = state.lookups.SECTORS.find(
                e => parseInt(e.id) === state.player.primaryAddressSectorId,
            );
        }
    }
};

const PlayerProfileProvider = ({ children }) => {
    const reducer: React.Reducer<PlayerProfileState, PlayerProfileAction> = (state, action) => {
        switch (action.type) {
            case PlayerProfileActions.INIT_PLAYER:
                state.player = { ...state.player, ...action.value };
                setLookupValues(state);
                state.playerLoaded = true;
                state.userCanEditProfile = action.userCanEditProfile;
                return { ...state, formDirty: false };
            case PlayerProfileActions.NEW_PLAYER:
                state.player.personId = 0;
                setLookupValues(state);
                state.playerLoaded = true;
                state.userCanEditProfile = action.userCanEditProfile;
                return { ...state, formDirty: false };
            case PlayerProfileActions.DATA_CHANGED:
                state.player = { ...state.player, ...action.value };
                return { ...state, formDirty: true };
            case PlayerProfileActions.LOOKUPS_LOADED:
                return {
                    ...state,
                    lookups: action.lookups,
                };
            case PlayerProfileActions.PLAYER_SAVED:
                state.player = { ...action.value };
                setLookupValues(state);
                return {
                    ...state,
                    formDirty: false,
                };
            case PlayerProfileActions.SET_CONDITIONAL_LOOKUPS:
                state.conditionalLookups = { ...state.conditionalLookups, ...action.value };
                return { ...state };
            case PlayerProfileActions.ATTACHED_FILE:
                state.player.fileAttachments = action.fileAttachments;
                return { ...state, formDirty: true };
            case PlayerProfileActions.CHANGE_CONFIRM_MODAL:
                state.displayCloseConfirmModal = action.value;
                return { ...state };
            default:
                return { ...state };
        }
    };

    const [state, dispatchPlayerProfile] = useReducer(reducer, initialState);

    return (
        <PlayerProfileContext.Provider value={{ state, dispatchPlayerProfile }}>
            {children}
        </PlayerProfileContext.Provider>
    );
};

PlayerProfileProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PlayerProfileProvider;
