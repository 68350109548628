import React, { useReducer } from "react";
import PropTypes from "prop-types";
import HomeContext, { initialState, HomeAction, HomeActions, HomeState } from "../../contexts/common/home.context";

const HomeProvider: React.FC = ({ children }) => {
    const reducer: React.Reducer<HomeState, HomeAction> = (state, action) => {
        switch (action.type) {
            case HomeActions.INIT:
                return {
                    ...state,
                    leagueLookup: action.leagueLookup,
                    league: action.league,
                    userInfo: action.userInfo,
                    initiated: true,
                };
                break;

            case HomeActions.LEAGUE_CHANGED:
                return {
                    ...state,
                    league: action.league,
                };
                break;

            default:
                return { ...state };
        }
    };

    const [state, dispatchHome] = useReducer(reducer, initialState);

    return <HomeContext.Provider value={{ state, dispatchHome }}>{children}</HomeContext.Provider>;
};

HomeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default HomeProvider;
