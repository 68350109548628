import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";
import { Player } from "../models/player/player.models";

const API_URL = `${SERVICES_URL}/roster`;

export const PartnerRosterApi = {
    getClubRoster: (clubInfoId: number): Promise<Array<Player>> =>
        axios.get(`${API_URL}/${clubInfoId}`).then((response: { data: any }) => response.data),
    removePlayerFromRoster: (clubInfoId: number, playerId: number): Promise<Array<Player>> =>
        axios
            .post(`${API_URL}/remove/${clubInfoId}`, playerId, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    addPlayerToRoster: (clubInfoId: number, playerId: number): Promise<Array<Player>> =>
        axios
            .post(`${API_URL}/add/${clubInfoId}`, playerId, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
};
