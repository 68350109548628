import React from "react";
import styled from "styled-components";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeaderWithTitleAndClose,
    PrimaryOutlineButton,
    StickyFooterButtons,
} from "best-common-react";
import { useRosters, RostersActions } from "./../../contexts/rosters/rosters.context";
import { useAlert } from "./../../contexts/AlertsContext";
import { PartnerRosterApi } from "../../api/PartnerRosterApi";

interface Props {
    show: boolean;
    close: any;
}

const RemovePlayerModal: React.FC<Props> = ({ show, close }) => {
    const { state, dispatchRosters } = useRosters();
    const showAlert = useAlert();

    const ModalText = styled.div`
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.71;
        color: ${props => props.theme["dark-grey"]};
    `;

    const message = `Are you sure you want to remove ${state.selectedPlayer.firstName} ${state.selectedPlayer.lastName} - ${state.selectedPlayer.position} from the roster?`;

    const removePlayerFromRoster = (clubInfoId, playerId) => {
        PartnerRosterApi.removePlayerFromRoster(clubInfoId, playerId).then(data => {
            close();
            showAlert("Removed", "success");
            dispatchRosters({ type: RostersActions.SET_SELECTED_CLUB_ROSTER, selectedClubRoster: data });
        });
    };

    return (
        <div>
            <Modal show={show}>
                <ModalHeaderWithTitleAndClose title="Remove player from roster" close={close} />
                <ModalBody>
                    <ModalText>{message}</ModalText>
                </ModalBody>
                <ModalFooter>
                    <StickyFooterButtons>
                        <PrimaryOutlineButton
                            onClick={() => {
                                removePlayerFromRoster(
                                    state.selectedPlayer.partnerClubInfoId,
                                    state.selectedPlayer.personId,
                                );
                            }}
                        >
                            Remove Player
                        </PrimaryOutlineButton>
                        <PrimaryOutlineButton onClick={close}>Cancel</PrimaryOutlineButton>
                    </StickyFooterButtons>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default RemovePlayerModal;
