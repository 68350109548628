import React, { useState, useEffect, FC } from "react";

interface Props {
    show: boolean;
    close: () => void;
}

import {
    LightButton,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeaderWithTitleAndClose,
    PrimaryButton,
    StickyFooterButtons,
} from "best-common-react";

import styled from "styled-components";
import { useHistory } from "react-router";

export const ModalBodyText = styled.div`
    font-family: Helvetica;
    color: ${props => props.theme.slate};
    margin-top: 0.1875rem;
    padding-right: 0.1875rem;
    font-size: 0.75rem;
`;

const ModalHintText = styled(ModalBodyText)`
    font-weight: 300;
    font-size: 0.75rem;
`;

const ConfirmCloseProfileModal: FC<Props> = ({ show, close }): JSX.Element => {
    const history = useHistory();
    const confirmed = (): void => {
        history.goBack();
    };

    return (
        <div>
            <Modal show={show} onClose={close} size="lg">
                <ModalHeaderWithTitleAndClose title="Warning" close={close} />
                <ModalBody>
                    <div style={{ marginBottom: "3rem" }}>
                        <ModalBodyText>You have unsaved changes. Are you sure you want to continue?</ModalBodyText>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <StickyFooterButtons>
                        <PrimaryButton onClick={() => confirmed()}>Continue</PrimaryButton>
                        <LightButton onClick={close}>Cancel</LightButton>
                    </StickyFooterButtons>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConfirmCloseProfileModal;
