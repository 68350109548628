import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LookupApi } from "../../api/LookupApi";
import { PlayerApi } from "../../api/PlayerApi";
import PlayerProfileContext, {
    PlayerProfileActions,
    PlayerProfileState,
} from "../../contexts/player-profile/player-profile.context";
import BioInformation from "./bio-information.component";
import BaseballInformation from "./baseball-information.component";
import ContactInformation from "./contact-information.component";
import PlayerFiles from "./player-files.component";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton, PrimaryOutlineButton, StickyFooterButtons } from "best-common-react";
import { useAlert } from "./../../contexts/AlertsContext";
import { RouteConstants } from "../../constants/RouteConstants";
import { PersonType, Player } from "../../models/player/player.models";
import FormHelper from "../common/form-helper";
import { AuthContext, useAuth } from "../../contexts/AuthContext";
import ConfirmCloseProfileModal from "./close-profile.modal";
import { EBIS_GLOBAL_SERVICES_URL } from "./../../constants/ApiConstants";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";
import HomeContext from "../../contexts/common/home.context";
import TradePlayerModal from "./trade-player/TradePlayerModal";
import { Lookup } from "../../models/lookups/lookups.models";

type PlayerProfileParams = {
    playerId?: string;
};

const Header = styled.div`
    background: #fff;
    border: solid 1px ${props => props.theme["LighterBlue"]};
    font-size: 18px;
    padding-left: 85px;
    padding-right: 85px;
    position: fixed;
    width: 100%;
    z-index: 5;
`;

const FormSection = styled.div`
    padding-top: 45px;
    margin-left: 20px;
    margin-right: 20px;
    @media (min-width: ${PixelBreakpointConstants.LARGE}px) {
        margin-left: 85px;
        margin-right: 85px;
    }
`;

const PrimaryButtonStyled = styled(PrimaryButton)`
    &&&& {
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        height: 26px;
        color: ${props => (props.disabled ? props.theme["LighterBlue"] : "#ffffff")};
        background-color: ${props => (props.disabled ? props.theme["LightGray"] : props.theme["LightBlue"])}};
        padding: 1px 15px 0px 15px !important;
        border-radius: 6px;
        border: solid 1px ${props => (props.disabled ? props.theme["LighterBlue"] : props.theme["LightBlue"])}};
    }
`;

export const PrimaryOutlineButtonStyled = styled(PrimaryOutlineButton)`
    &&&& {
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        height: 26px;
        color: ${props => props.theme["LightBlue"]};
        background-color: #ffffff;
        padding: 1px 15px 0px 15px;
        border-radius: 6px;
        border: solid 1px ${props => props.theme["LightBlue"]};
    }
`;

const LogoImg = styled.img`
    width: 35px;
    height: 35px;
    margin-right: 8px;
`;

const HeaderName = styled.div`
    margin-right: 20px;
    display: inline-block;
`;

const HeaderText = styled.div``;

const PlayerProfile = () => {
    const { canEditClubId } = useAuth();
    const playerProfileContext = useContext(PlayerProfileContext);
    const currentState = playerProfileContext.state;
    const { player, formDirty, playerLoaded, userCanEditProfile, displayCloseConfirmModal } = currentState;
    const history = useHistory();
    const {
        partnerUserDetails: userRoleInfo,
        canEditClubId: canUserEditClub,
        canEditLeagueId: canUserEditLeague,
    } = useContext(AuthContext);
    const [showTradePlayerModal, setShowTradePlayerModal] = useState(false);

    const { playerId } = useParams<PlayerProfileParams>();

    const showAlert = useAlert();

    const savePlayer = player => {
        PlayerApi.savePlayerInfo(player).then(savedPlayer => {
            showAlert("Saved", "success");
            playerProfileContext.dispatchPlayerProfile({
                type: PlayerProfileActions.PLAYER_SAVED,
                value: savedPlayer,
            });
        });
    };

    useEffect(() => {
        Promise.all([
            LookupApi.getLookups("COUNTRIES"),
            LookupApi.getLookups("PRO_PLAYER_POSITIONS"),
            LookupApi.getLookups("PLAYERADDRESSTYPES"),
            LookupApi.getLookups("PRO_PLAYER_PHONE_TYPES"),
            LookupApi.getLookups("EMAILTYPES"),
            LookupApi.getLookups("COUNTRY_DIAL_CODES"),
            LookupApi.getLookups("COUNTRIES_WITH_DROPDOWNS"),
            LookupApi.getLookups("COUNTRY_ADDRESS_SUBLEVEL"),
            LookupApi.getLookups("ADDRESS_SUBLEVELS"),
            LookupApi.getLookups("USSTATES"),
            LookupApi.getLookups("PROVINCES"),
            LookupApi.getLookups("COUNTRIES_WITH_SECTOR"),
            LookupApi.getLookups("SECTORS"),
            LookupApi.getLookups("DISTRICTS"),
            LookupApi.getLookups("CITIES"),
            LookupApi.getLookups("COUNTRIES_WITH_ZIP"),
            LookupApi.getLookups("PARTNER_LEAGUE"),
        ]).then(
            ([
                countries,
                positions,
                addressTypes,
                phoneTypes,
                emailTypes,
                countryDialCodes,
                countriesWithDropDowns,
                countryAddressSublevel,
                addressSublevels,
                states,
                provinces,
                countriesWithSector,
                sectors,
                districts,
                cities,
                countriesWithZip,
                partnerLeagues,
            ]) => {
                countries["COUNTRIES"].shift();
                positions["PRO_PLAYER_POSITIONS"].shift();
                states["USSTATES"].shift();
                provinces["PROVINCES"].shift();
                sectors["SECTORS"].shift();

                playerProfileContext.dispatchPlayerProfile({
                    type: PlayerProfileActions.LOOKUPS_LOADED,
                    lookups: {
                        ...countries,
                        ...positions,
                        ...addressTypes,
                        ...phoneTypes,
                        ...emailTypes,
                        ...countryDialCodes,
                        ...countryAddressSublevel,
                        ...addressSublevels,
                        ...countriesWithSector,
                        ...countriesWithDropDowns,
                        ...states,
                        ...provinces,
                        ...sectors,
                        ...districts,
                        ...cities,
                        ...countriesWithZip,
                        ...partnerLeagues,
                    },
                });
                if (RouteConstants.NEW_PROFILE_SUFFIX === playerId) {
                    Promise.all([PlayerApi.getClubLogo(currentState.player.partnerClubInfoId)]).then(
                        ([loadedClubLogo]) => {
                            playerProfileContext.dispatchPlayerProfile({
                                type: PlayerProfileActions.DATA_CHANGED,
                                value: { logoFileId: loadedClubLogo.fileIdentifier },
                            });
                        },
                    );
                    playerProfileContext.dispatchPlayerProfile({
                        type: PlayerProfileActions.NEW_PLAYER,
                        userCanEditProfile: true,
                    });
                } else {
                    //Get player if needed.
                    Promise.all([PlayerApi.getPlayer(parseInt(playerId))]).then(([loadedPlayer]) => {
                        playerProfileContext.dispatchPlayerProfile({
                            type: PlayerProfileActions.INIT_PLAYER,
                            value: { ...loadedPlayer },
                            userCanEditProfile:
                                userRoleInfo.isEditor &&
                                (canUserEditClub(loadedPlayer.partnerClubInfoId) ||
                                    canUserEditLeague(loadedPlayer.partnerLeagueId)),
                        });
                        if (loadedPlayer.birthCountryId) {
                            LookupApi.getParentLookup("DISTRICTS", loadedPlayer.birthCountryId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { birthDistricts: data },
                                });
                            });
                        }
                        if (loadedPlayer.birthDistrictId) {
                            LookupApi.getParentLookup("CITIES", loadedPlayer.birthDistrictId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { birthCities: data },
                                });
                            });
                        }
                        if (loadedPlayer.birthCityId) {
                            LookupApi.getParentLookup("SECTORS", loadedPlayer.birthCityId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { birthSectors: data },
                                });
                            });
                        }

                        if (loadedPlayer.primaryAddressCountryId) {
                            LookupApi.getParentLookup("DISTRICTS", loadedPlayer.primaryAddressCountryId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { addressDistricts: data },
                                });
                            });
                        }
                        if (loadedPlayer.primaryAddressDistrictId) {
                            LookupApi.getParentLookup("CITIES", loadedPlayer.primaryAddressDistrictId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { addressCities: data },
                                });
                            });
                        }
                        if (loadedPlayer.primaryAddressCityId) {
                            LookupApi.getParentLookup("SECTORS", loadedPlayer.primaryAddressCityId).then(data => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { addressSectors: data },
                                });
                            });
                        }
                    });
                }

                // Create an array of promises for each league's club lookup
                const clubLookupPromises: Promise<Array<Lookup>>[] = partnerLeagues["PARTNER_LEAGUE"].map(league =>
                    LookupApi.getParentLookup("PARTNER_LEAGUE_CLUB", league.id),
                );

                // Get clubs for all leagues
                Promise.all(clubLookupPromises)
                    .then(results => {
                        // results is an array containing the resolved values of each promise
                        console.log("my promise results: ", results);
                        playerProfileContext.dispatchPlayerProfile({
                            type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                            value: { allClubs: results },
                        });
                    })
                    .catch(error => {
                        // Handle errors
                        console.error("Error fetching parent lookups:", error);
                    });
            },
        );
    }, []);

    const setConfirmCloseModal = (value: boolean): void => {
        playerProfileContext.dispatchPlayerProfile({
            type: PlayerProfileActions.CHANGE_CONFIRM_MODAL,
            value: value,
        });
    };

    const isEmailValid = (value: string): boolean => (value && value !== "" ? FormHelper.isValidEmail(value) : true);

    const isSaveButtonDisabled = (currentState: PlayerProfileState): boolean => {
        const playerInfo: Player = currentState.player;

        const bioInfoNotEntered: boolean =
            playerInfo.birthCountryId === null ||
            playerInfo.dateOfBirthAsString === "" ||
            playerInfo.dateOfBirthAsString === null ||
            playerInfo.firstName === "" ||
            playerInfo.firstName === null ||
            playerInfo.lastName === "" ||
            playerInfo.lastName === null ||
            playerInfo.rosterFirstName === "" ||
            playerInfo.rosterFirstName === null ||
            playerInfo.playerPositionId === null;

        const baseballInfoNotEntered: boolean =
            playerInfo.battingSide === null ||
            playerInfo.battingSide === "" ||
            playerInfo.throwingSide === null ||
            playerInfo.throwingSide === "" ||
            playerInfo.height === null ||
            playerInfo.weight === null ||
            String(playerInfo.weight) === "";

        const phoneInfoNotEntered: boolean =
            playerInfo.primaryPhoneTypeId !== null &&
            (playerInfo.primaryPhoneCountryId === null ||
                playerInfo.primaryPhoneNumber === null ||
                playerInfo.primaryPhoneNumber === "");

        const emailInfoNotEntered: boolean =
            playerInfo.primaryEmailTypeId !== null &&
            (playerInfo.primaryEmail === null ||
                playerInfo.primaryEmail === "" ||
                !isEmailValid(playerInfo.primaryEmail));

        const addressInfoNotEntered: boolean =
            playerInfo.primaryAddressTypeId !== null &&
            (playerInfo.primaryAddressCountryId === null ||
                playerInfo.primaryAddress1 === null ||
                playerInfo.primaryAddress1 === "" ||
                (currentState.conditionalLookups.addressHasDropdown && playerInfo.primaryAddressCityId === null));

        return (
            (playerInfo.personTypeId == PersonType.PARTNER_PLAYER && bioInfoNotEntered) ||
            baseballInfoNotEntered ||
            phoneInfoNotEntered ||
            emailInfoNotEntered ||
            addressInfoNotEntered
        );
    };

    const closeOnClick = (): void => {
        if (formDirty) {
            setConfirmCloseModal(true);
        } else {
            history.goBack();
        }
    };

    const { state } = useContext(HomeContext);

    return playerLoaded ? (
        <div>
            <Header className="d-flex justify-content-between py-2">
                <HeaderText>
                    {player.logoFileId ? (
                        <LogoImg src={`${EBIS_GLOBAL_SERVICES_URL}/global/file/clubLogo/${player.logoFileId}`} />
                    ) : player.logoFileId === null && player.partnerLeagueId != null ? (
                        <LogoImg
                            src={
                                state.leagueLookup.filter(state => parseInt(state.id) == player.partnerLeagueId)[0]
                                    ?.icon
                            }
                        />
                    ) : (
                        <LogoImg />
                    )}{" "}
                    <HeaderName>
                        {player.lastName}, {player.rosterFirstName ? player.rosterFirstName : player.firstName} -{" "}
                        {player.playerPosition ? player.playerPosition.value : ""}
                    </HeaderName>
                    {canEditClubId(player.partnerClubInfoId) && (
                        <PrimaryButtonStyled onClick={() => setShowTradePlayerModal(true)}>
                            Transfer Player
                        </PrimaryButtonStyled>
                    )}
                </HeaderText>
                <div>
                    <StickyFooterButtons startsOpen={true}>
                        {userCanEditProfile && (
                            <PrimaryButtonStyled
                                disabled={!formDirty || isSaveButtonDisabled(currentState)}
                                onClick={() => savePlayer(player)}
                            >
                                Save
                            </PrimaryButtonStyled>
                        )}
                        <PrimaryOutlineButtonStyled onClick={closeOnClick}>
                            {RouteConstants.NEW_PROFILE_SUFFIX === playerId ? "Cancel" : "Close"}
                        </PrimaryOutlineButtonStyled>
                    </StickyFooterButtons>
                </div>
            </Header>
            <FormSection className="d-flex flex-column">
                <BioInformation />
                <BaseballInformation />
                <ContactInformation />
                <PlayerFiles />
            </FormSection>
            <ConfirmCloseProfileModal show={displayCloseConfirmModal} close={() => setConfirmCloseModal(false)} />
            {showTradePlayerModal && (
                <TradePlayerModal
                    player={player}
                    setShowModal={setShowTradePlayerModal}
                    showModal={showTradePlayerModal}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default PlayerProfile;
