import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";
import { PersonPartnerHistory, PersonPartnerHistoryForm } from "../models/history/history.models";
import { LeagueContactInfo } from "../models/rosters/LeagueContactInfo.model";

const API_URL = `${SERVICES_URL}/league`;

const PartnerLeagueApi = {
    getRosters: (leagueId: number): Promise<any> =>
        axios.get(`${API_URL}/${leagueId}/roster`).then((response: { data: any }) => response.data),
    getHistory: (form: PersonPartnerHistoryForm): Promise<PersonPartnerHistory[]> =>
        axios
            .post(`${API_URL}/history`, form, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    getBackEndVersion: (): Promise<any> => {
        const url = `${SERVICES_URL}/actuator/info`;
        return axios.get(url).then(response => response.data);
    },
    updateLeagueContactInfo: (leagueId: number, contactInfo: LeagueContactInfo): Promise<LeagueContactInfo> =>
        axios
            .post(`${API_URL}/${leagueId}/contactinfo`, contactInfo, {
                headers: { "Content-Type": "application/json" },
            })
            .then((response: { data: LeagueContactInfo }) => response.data),
};

export default PartnerLeagueApi;
