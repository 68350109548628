import React from "react";
import { LoadingBlocker } from "best-common-react";
import { useLoading } from "./../../contexts/LoadingContext";

const PartnerLoadingBlocker: React.FC = () => {
    const { isLoading } = useLoading();

    return <span>{isLoading && <LoadingBlocker />}</span>;
};

export default PartnerLoadingBlocker;
