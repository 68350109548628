import React from "react";
import footer from "../../assets/dirt.png";
import footerXl from "../../assets/dirt@2x.png";
import partnerLogo from "../../assets/partner-league@2x.png";
import { PrimaryButton, Icon } from "best-common-react";
import { useOktaAuth } from "@okta/okta-react";
import { RouteConstants } from "./../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { Redirect, RouteComponentProps, withRouter } from "react-router";

const Login: React.FC<RouteComponentProps> = () => {
    const { oktaAuth } = useOktaAuth();
    const { loggedIn } = useAuth();
    const loginFn = () => {
        oktaAuth.signInWithRedirect({ originalUri: RouteConstants.BASE });
    };

    return (
        <div>
            {!loggedIn ? (
                <div className="login-wrapper">
                    <div className="light-wrapper">
                        <div className="submarine-lights" />
                        <div className="submarine-lights right" />
                    </div>

                    <div className="partner-portal">
                        <div className="partner-wrapper">
                            <img src={partnerLogo} />
                            <span className="partner-leagues mb-4">PARTNER LEAGUES</span>
                            <PrimaryButton onClick={loginFn}>
                                <span className="d-flex align-items-center">
                                    <Icon iconName="fa-key" />
                                    <span className="sign-in ml-2">Sign in with MLB Okta</span>
                                </span>
                            </PrimaryButton>
                        </div>
                    </div>

                    <div className="login-footer">
                        <div className="partners">
                            <div className="partner aa" />
                            <div className="partner pl" />
                            <div className="partner fl" />
                            <div className="partner al" />
                        </div>

                        <img src={footer} className="sm" />
                        <img src={footerXl} className="xl" />
                    </div>
                </div>
            ) : (
                <Redirect to={RouteConstants.BASE} />
            )}
        </div>
    );
};

export default withRouter(Login);
