import React from "react";
import PropTypes from "prop-types";
import BestLabelStyled from "./best-label-styled.component";
import styled from "styled-components";
import { RequiredInput } from "best-common-react";
import DisabledIconWrapper from "./disabled-icon-wrapper.component";

const RightLabel = styled.span`
    color: ${props => props.theme["mlb-red"]};
    font-weight: 400;
    font-size: 12px;
`;

const LabelHolder = styled.div.attrs(() => ({
    className: "d-flex justify-content-between",
}))`
    margin-top: 0.1875rem;
`;

const RequiredInputWithLabelStyled = ({
    rightLabel = null,
    children,
    label = "",
    requiredText = null,
    name,
    className = "",
    required = true,
    disabled = false,
}) => {
    const validateFunc = value => {
        if (!value) {
            // number would only fall in here if 0, otherwise it's not a number and is invalid
            return typeof value === typeof -1;
        } else {
            if (typeof value === typeof "") {
                return !!value.trim().length;
            } else if (value instanceof Array) {
                return !!value.length;
            } else if (typeof value === typeof {}) {
                return !!Object.keys(value).length;
            } else if (typeof value === typeof -1) {
                //default value for not set
                return !!(value !== -1);
            } else {
                return true;
            }
        }
    };
    return (
        <div className={className} id="required-input-with-label">
            <LabelHolder>
                <BestLabelStyled htmlFor={name} required={required}>
                    {label}
                </BestLabelStyled>
                {rightLabel ? <RightLabel> {rightLabel} </RightLabel> : null}
            </LabelHolder>
            {required ? (
                <RequiredInput validateFunc={validateFunc} requiredText={requiredText}>
                    {children}
                </RequiredInput>
            ) : (
                <DisabledIconWrapper disabled={disabled}>{children}</DisabledIconWrapper>
            )}
        </div>
    );
};

export default RequiredInputWithLabelStyled;
