import React from "react";
import PlayerProfile from "./player-profile.component";
import PlayerProfileProvider from "../../providers/player-profile/player-profile.provider";

const PlayerProfileContainer = () => {
    return (
        <PlayerProfileProvider>
            <PlayerProfile />
        </PlayerProfileProvider>
    );
};

export default PlayerProfileContainer;
