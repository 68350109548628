import React, { createContext, useContext } from "react";
import { Lookup } from "../../models/lookups/lookups.models";
import { Request, RequestsForm } from "../../models/requests/requests.models";

type RequestsContext = {
    state: RequestsState;
    dispatchRequests: React.Dispatch<RequestsAction>;
};

type RequestsState = {
    clubLookup?: Array<Lookup>;
    statusLkup?: Array<Lookup>;
    yearList?: Array<number>;
    requests?: Array<Request>;
    form?: RequestsForm;
    selectedClub?: Lookup;
    selectedStatus?: Lookup;
    selectedYear?: Lookup;
    showApproveRequestModal?: boolean;
    showAcceptRequestModal?: boolean;
    showDenyRequestModal?: boolean;
    selectedRequest?: Request;
};

interface RequestsAction extends RequestsState {
    type: string;
    property?: string;
    value?: any;
}

//generate list of years for dropdown
const yearRange = 40;
const getYearList = (range: number): Array<any> => {
    const thisYear = new Date().getFullYear();
    const yearList = Array(range)
        .fill("")
        .map((val, idx) => ({ value: (thisYear - idx).toString(), label: (thisYear - idx).toString() }));
    return [{ value: "0", label: "All Years" }, ...yearList];
};
const yearList = getYearList(yearRange);

const initialState: RequestsState = {
    clubLookup: [],
    requests: [],
    statusLkup: [],
    yearList: yearList,
    form: {
        statusId: -1,
        year: new Date().getFullYear(),
        clubInfoId: -1,
    },
    selectedClub: { id: "-1", value: "" },
    selectedStatus: { id: "0", value: "All Statuses", label: "All Statuses" },
    selectedYear: { value: new Date().getFullYear().toString(), label: new Date().getFullYear().toString() },
    showAcceptRequestModal: false,
    showApproveRequestModal: false,
    selectedRequest: { clubInfoId: 0, playerFirstName: null, playerLastName: null, posCode: null },
};

const RequestsActions = {
    INIT: "INIT",
    SET_REQUESTS: "SET_REQUESTS",
    SET_SELECTED_CLUB: "SET_SELECTED_CLUB",
    SET_SELECTED_YEAR: "SET_SELECTED_YEAR",
    SET_SELECTED_STATUS: "SET_SELECTED_STATUS",
    SET_SELECTED_REQUEST: "SET_SELECTED_REQUEST",
    SET_SELECTED_REQUEST_STATUS: "SET_SELECTED_REQUEST_STATUS",
    TOGGLE_APPROVE_REQUEST_MODAL: "TOGGLE_APPROVE_REQUEST_MODAL",
    TOGGLE_ACCEPT_REQUEST_MODAL: "TOGGLE_ACCEPT_REQUEST_MODAL",
    TOGGLE_DENY_REQUEST_MODAL: "TOGGLE_DENY_REQUEST_MODAL",
};

const RequestsContext = createContext<RequestsContext>({
    state: initialState,
    dispatchRequests: null,
});

const useRequests = (): RequestsContext => {
    const context = useContext<RequestsContext>(RequestsContext);

    if (context == undefined) {
        throw new Error(`useRequests must be used within a RequestsContext`);
    }
    return context;
};

export { RequestsContext, RequestsState, RequestsAction, initialState, RequestsActions, useRequests };
