import React, { useEffect, useContext, FC } from "react";
import { Modal, PrimaryOutlineButton, StickyFooterButtons } from "best-common-react";
import { useModal } from "./../../contexts/ModalContext";
import parse from "html-react-parser";

const ServerErrorModal: FC<Props> = ({}): JSX.Element => {
    const { showServerErrorModal, setShowServerErrorModal, genericBodyText, genericTitleText } = useModal();

    return (
        <div>
            <Modal show={showServerErrorModal}>
                <Modal.HeaderWithTitleAndClose
                    title={genericTitleText}
                    close={() => {
                        setShowServerErrorModal(false);
                    }}
                />

                <Modal.Body>
                    <div>{parse(genericBodyText)}</div>
                </Modal.Body>

                <Modal.Footer>
                    <StickyFooterButtons>
                        <PrimaryOutlineButton
                            onClick={() => {
                                setShowServerErrorModal(false);
                            }}
                        >
                            Close
                        </PrimaryOutlineButton>
                    </StickyFooterButtons>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ServerErrorModal;
