import React, { FC, useContext } from "react";
import styled from "styled-components";
import { PartnerLeagueClub } from "../../models/rosters/partnerLeagueClub.model";
import { EBIS_GLOBAL_SERVICES_URL } from "./../../constants/ApiConstants";
import HomeContext, { HomeActions } from "../../contexts/common/home.context";

interface Props {
    className: string;
    club: PartnerLeagueClub;
    selectClub: () => void;
}

const ClubCard: FC<Props> = ({ className, club, selectClub }) => {
    const Club = styled.div`
        height: 4.438rem;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0 0 4px 0 rgb(0, 0, 0, 0.2);
        border: solid 1px ${props => props.theme["LighterBlue"]};
        cursor: pointer;
    `;

    const ClubLogo = styled.div`
        width: 5rem;
        border-right: solid 1px ${props => props.theme["LighterBlue"]};
    `;

    const PlayerCount = styled.div`
        color: ${props => props.theme["LightBlue"]};
        font-size: 0.8rem;
    `;

    const LogoImg = styled.img`
        width: 5rem;
        position: relative;
        bottom: 0.55rem;
    `;

    const selectClubClick = () => {
        if (selectClub && selectClub !== null) {
            selectClub();
        }
    };

    const ClubInfo = styled.div`
        padding-left: 0.75rem;
    `;

    const { state } = useContext(HomeContext);

    return (
        <div
            className={className + " pl-0 mb-1 mb-lg-2" + (club.isSelected ? " card-selected" : "")}
            onClick={selectClubClick}
        >
            <Club className="d-flex card-info">
                <ClubLogo className="my-1">
                    <LogoImg
                        src={
                            club.clubInfo.logoFileId === null
                                ? state.leagueLookup.filter(state => parseInt(state.id) == club.clubInfo.leagueId)[0]
                                      .icon
                                : `${EBIS_GLOBAL_SERVICES_URL}/global/file/clubLogo/${club.clubInfo.logoFileId}`
                        }
                    />
                </ClubLogo>
                <ClubInfo>
                    <div>{club.clubInfo.clubName}</div>
                    <PlayerCount>{club.playerCount} players</PlayerCount>
                </ClubInfo>
            </Club>
        </div>
    );
};

export default ClubCard;
