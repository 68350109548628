import { AttributeHeader, Input, Theme as BestTheme } from "best-common-react";

export const Theme: any = {
    ...BestTheme,
    background: "#003567",
    LighterBlue: "#dee5ed",
    LightBlue: "#4c8cee",
    Blue: "#083988",
    DarkBlue: "#041e42",
    LightGreen: "#00a651",

    LightGray: "#f5f7fa",
    Gray: "#92a5ba",

    Dark: "#22356f",
};
