import React, { FC, useContext, useEffect } from "react";
import styled from "styled-components";
import { RouteConstants, PathnameToTitle } from "./../../constants/RouteConstants";
import { SecureRoute } from "@okta/okta-react";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";
import LeagueNavigator from "./league-navigator.component";
import { BestDropdown } from "best-common-react";
import RostersContainer from "../rosters/rosters.container";
import HistoryContainer from "../history/history.container";
import { useLocation } from "react-router";

import NewPlayerContainer from "../newplayer/newplayer.container";
import AmericanAssocationIcon from "../../assets/icons/american-association.png";
import AtlanticLeagueIcon from "../../assets/icons/atlantic-league.png";
import FrontierLeagueIcon from "../../assets/icons/frontier-league.png";
import PioneerLeagueIcon from "../../assets/icons/pioneer-league.png";
import { LookupApi } from "../../api/LookupApi";
import { getLookupValue } from "../../contexts/LookupsContext";
import HomeContext, { HomeActions } from "../../contexts/common/home.context";
import RequestsContainer from "../requests/requests.container";
import { useAuth } from "../../contexts/AuthContext";

const Home: FC = ({}): JSX.Element => {
    const { state, dispatchHome } = useContext(HomeContext);

    const { partnerUserDetails } = useAuth();
    const location = useLocation();

    useEffect(() => {
        LookupApi.getLookups("PARTNER_LEAGUE").then(partnerLeagues => {
            const partnerLeague = partnerLeagues["PARTNER_LEAGUE"];

            partnerLeague.filter(league => league.label === "American Association")[0].icon = AmericanAssocationIcon;
            partnerLeague.filter(league => league.label === "Atlantic League")[0].icon = AtlanticLeagueIcon;
            partnerLeague.filter(league => league.label === "Frontier League")[0].icon = FrontierLeagueIcon;
            partnerLeague.filter(league => league.label === "Pioneer League")[0].icon = PioneerLeagueIcon;

            //set initial league id if user is a league president
            let initialLeague = partnerLeagues["PARTNER_LEAGUE"][0];
            if (
                ((partnerUserDetails.isLeaguePresident && !partnerUserDetails.isBoc) ||
                    partnerUserDetails.isClubUser) &&
                partnerUserDetails.leagueIds.length > 0
            ) {
                const initialLeagueList = partnerLeague.filter(
                    league => Number(league.id) === partnerUserDetails.leagueIds[0],
                );
                if (initialLeagueList.length > 0) {
                    initialLeague = initialLeagueList[0];
                }
            }
            if (!state.initiated) {
                dispatchHome({
                    type: HomeActions.INIT,
                    league: initialLeague,
                    leagueLookup: partnerLeagues["PARTNER_LEAGUE"],
                });
            }
        });
    }, []);

    const LeftBar = styled.div`
        z-index: 2;
        background-color: ${props => props.theme["LightGray"]};
        width: 100%;

        @media (min-width: ${PixelBreakpointConstants.LARGE}px) {
            width: 5rem;
            background-color: white;
            height: 100vh;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            padding-top: 1rem;
        }
    `;

    const RightBar = styled.div`
        background-color: ${props => props.theme["LightGray"]};
        height: calc(100vh - 55px);
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
    `;

    const HomeWrapper = styled.div`
        z-index: 3;
        height: calc(100vh - 3.6em);
        position: fixed;
        top: 3.6rem;
        width: 100%;
    `;

    const PageTitle = styled.div`
        background-color: white;
        border-bottom: 1px solid ${props => props.theme["LighterBlue"]};
    `;

    const LeagueIcon = styled.img`
        height: 5rem;
    `;

    const SectionTitle = styled.span`
        font-family: helvetica;
    `;

    const LeagueName = styled.span`
        font-family: helvetica;
        position: relative;
        bottom: 0.5rem;
    `;

    const getLeaveNavigator = (league): JSX.Element => {
        return (
            <LeagueNavigator
                key={league.id}
                onClick={() => {
                    dispatchHome({
                        type: HomeActions.LEAGUE_CHANGED,
                        league: league,
                    });
                }}
                leagueId={league.id}
                currentLeagueId={state.league.id}
            >
                {league.icon !== null ? <LeagueIcon src={league.icon} /> : league.name}
            </LeagueNavigator>
        );
    };

    //use placeholder text until we get images
    return (
        <HomeWrapper className="d-flex flex-column flex-lg-row">
            <PageTitle className="d-block d-lg-none">
                <LeagueName className="pl-3">{PathnameToTitle[location.pathname]}</LeagueName>
            </PageTitle>

            <LeftBar className="d-flex flex-column px-0 pr-3 px-lg-0">
                {state.leagueLookup.map(getLeaveNavigator)}
            </LeftBar>
            <RightBar className="px-3 px-lg-0">
                <div className="d-block d-lg-none col-12 col-md-6 px-0">
                    <BestDropdown
                        name="leagues"
                        value={state.league}
                        title=""
                        options={state.leagueLookup}
                        onChange={value => {
                            dispatchHome({
                                type: HomeActions.LEAGUE_CHANGED,
                                league: value,
                            });
                        }}
                    />
                </div>
                <SecureRoute
                    path={RouteConstants.ROSTERS}
                    exact
                    render={props => <RostersContainer {...props} currentLeagueId={state.league.id} />}
                />
                <SecureRoute
                    path={RouteConstants.HISTORY}
                    exact
                    render={props => <HistoryContainer {...props} currentLeagueId={state.league.id} />}
                />
                <SecureRoute
                    path={RouteConstants.REQUESTS}
                    exact
                    render={props => <RequestsContainer {...props} currentLeagueId={state.league.id} />}
                />
                <SecureRoute
                    path={RouteConstants.NEW_PLAYER}
                    exact
                    render={props => <NewPlayerContainer {...props} currentLeagueId={state.league.id} />}
                />
            </RightBar>
        </HomeWrapper>
    );
};

export default Home;
