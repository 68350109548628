import React from "react";
import styled from "styled-components";
import { RequestsActions, useRequests } from "../../contexts/requests/requests.context";
import { Request } from "../../models/requests/requests.models";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeaderWithTitleAndClose,
    PrimaryOutlineButton,
    StickyFooterButtons,
} from "best-common-react";
import RequestsApi from "../../api/RequestsApi";
import { getLookupId } from "../../contexts/LookupsContext";
import { Lookup } from "../../models/lookups/lookups.models";
import { useAlert } from "../../contexts/AlertsContext";

interface Props {
    show: boolean;
    close: any;
    title: string;
    message: string;
    statusName: string;
}

const RequestActionModal: React.FC<Props> = ({ show, close, title, message, statusName }) => {
    const { state, dispatchRequests } = useRequests();
    const showAlert = useAlert();

    const ModalText = styled.div`
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.71;
        color: ${props => props.theme["dark-grey"]};
    `;

    const updateRequestStatus = (request: Request, statusLkup: Lookup[], statusName) => {
        request.statusId = getLookupId(statusName, statusLkup);

        if (request.type === 1) {
            // 1 is partner_trade type
            if (title === "Deny") {
                RequestsApi.saveRequest(request.clubInfoId, request).then(data => {
                    close();
                    showAlert(statusName, "success");
                    dispatchRequests({
                        type: RequestsActions.SET_SELECTED_REQUEST_STATUS,
                        value: request.statusId,
                    });
                });
            } else {
                RequestsApi.tradePlayer(request.clubInfoId, request).then(data => {
                    close();
                    showAlert(statusName, "success");
                    dispatchRequests({
                        type: RequestsActions.SET_SELECTED_REQUEST_STATUS,
                        value: request.statusId,
                    });
                });
            }
        } else {
            request.type = 2;
            RequestsApi.saveRequest(request.clubInfoId, request).then(data => {
                close();
                showAlert(statusName, "success");
                dispatchRequests({
                    type: RequestsActions.SET_SELECTED_REQUEST_STATUS,
                    value: request.statusId,
                });
            });
        }
    };

    return (
        <div>
            <Modal show={show}>
                <ModalHeaderWithTitleAndClose title={title + " Request"} close={close} />
                <ModalBody>
                    <ModalText>{message}</ModalText>
                </ModalBody>
                <ModalFooter>
                    <StickyFooterButtons>
                        <PrimaryOutlineButton
                            onClick={() => {
                                updateRequestStatus(state.selectedRequest, state.statusLkup, statusName);
                            }}
                        >
                            {title}
                        </PrimaryOutlineButton>
                        <PrimaryOutlineButton onClick={close}>Cancel</PrimaryOutlineButton>
                    </StickyFooterButtons>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default RequestActionModal;
