import React, { useEffect, FC } from "react";
import styled from "styled-components";
import { LookupApi } from "../../api/LookupApi";
import { RequestsActions, useRequests } from "../../contexts/requests/requests.context";
import { BestSelect, BestTable, PrimaryOutlineButton, AnchorLink } from "best-common-react";
import { Request } from "../../models/requests/requests.models";
import { Lookup } from "../../models/lookups/lookups.models";
import { getLookupValue } from "../../contexts/LookupsContext";
import RequestsApi from "../../api/RequestsApi";
import { Column } from "../../models/best-common-react/tables.component";
import { useAuth } from "../../contexts/AuthContext";
import { formatDate, ShortDate, ShortDateTime } from "../../modules/dates/dates";
import RequestActionModal from "./request-action.modal";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { LargeAndLargerView, MediumAndSmallerView } from "../../modules/view/views";
import PartnerTable from "./../common/partner-table.component";

interface Props {
    currentLeagueId: number;
}

interface RequestDisplayInfo {
    requestInfo: Request;
    status: string;
    player: string;
    position: string;
    org: string;
    requestDate: string;
    expiresDate: string;
    club: string;
}

const Requests: FC<Props> = ({ currentLeagueId }): JSX.Element => {
    const { state, dispatchRequests } = useRequests();
    const { canViewClubId, canEditClubId, canEditLeagueId, partnerUserDetails } = useAuth();

    useEffect(() => {
        if (currentLeagueId !== null && currentLeagueId !== undefined && currentLeagueId > 0) {
            Promise.all([
                LookupApi.getParentLookup("PARTNER_LEAGUE_CLUB", currentLeagueId),
                LookupApi.getLookups("PARTNER_REQUEST_STATUS"),
            ]).then(([clubs, statuses]) => {
                //add All options to clubs, status lookups, set lookup lists
                clubs.splice(0, 0, { id: "0", value: "All Teams", label: "All Teams" });
                statuses["PARTNER_REQUEST_STATUS"].splice(0, 0, {
                    id: "0",
                    value: "All Statuses",
                    label: "All Statuses",
                });
                if (
                    //if league president and not boc --> only show user's league
                    partnerUserDetails.isLeaguePresident &&
                    !partnerUserDetails.isBoc &&
                    partnerUserDetails.leagueIds.length > 0 &&
                    Number(currentLeagueId) !== partnerUserDetails.leagueIds[0]
                ) {
                    clubs = [];
                }

                if (
                    //if club user --> only show requests for user's club
                    partnerUserDetails.isClubUser &&
                    partnerUserDetails.clubIds.length > 0 &&
                    partnerUserDetails.leagueIds.length > 0
                ) {
                    if (Number(currentLeagueId) !== partnerUserDetails.leagueIds[0]) {
                        clubs = [];
                    } else {
                        clubs = clubs.filter(club => Number(club.id) === partnerUserDetails.clubIds[0]);
                    }
                }

                dispatchRequests({
                    type: RequestsActions.INIT,
                    clubLookup: clubs,
                    statusLkup: statuses["PARTNER_REQUEST_STATUS"],
                    selectedClub: clubs.length > 0 ? clubs[0] : { id: "-1", value: "" },
                    value: clubs.length > 0 ? Number(clubs[0].id) : -1,
                });
            });
        }
    }, []);

    useEffect(() => {
        if (
            currentLeagueId !== null &&
            currentLeagueId !== undefined &&
            currentLeagueId > 0 &&
            state.form.clubInfoId >= 0
        ) {
            RequestsApi.getRequests(currentLeagueId, state.form).then(data => {
                dispatchRequests({
                    type: RequestsActions.SET_REQUESTS,
                    requests: data,
                });
            });
        }
    }, [state.selectedClub, state.selectedStatus, state.selectedYear, state.selectedRequest]);

    const TableWrapper = styled.div`
        & .bcr-table-header {
            background: ${props => props.theme["LighterBlue"]};
        }
        &. bcr-header-cell {
            color: ${props => props.theme["Dark"]};
        }
    `;

    const GrayItalisized = styled.div`
        font-size: 12px;
        font-style: italic;
        color: ${props => props.theme["LighterBlue"]};
    `;

    const StatusHighlight = styled.div`
        color: ${props => props.theme["LightGreen"]};
    `;

    const ListStatusHighlight = styled(StatusHighlight)`
        font-size: 13px;
    `;

    const ListStatus = styled.div`
        font-size: 13px;
    `;

    const RequestActionButton = styled(PrimaryOutlineButton)`
        max-height: 70%;
        min-width: 70%;
        padding: 0px;
        text-align: center;
    `;

    const ListRequestActionButton = styled(PrimaryOutlineButton)`
        max-height: 70%;
        padding: 0px;
        min-width: 80%;
        float: right;
        margin-bottom: 8px;
    `;

    const RequestList = styled.div`
        overflow: auto;
        max-height: 73vh;
    `;

    const RequestItemHeader = styled.div`
        background-color: #ffffff;
        border: 1px solid ${props => props.theme["LighterBlue"]};
        display: flex;
        justify-content: space-between;
        padding: 12px;
    `;

    const RequestItemBody = styled.div`
        background-color: ${props => props.theme["LightGray"]};
        border: 1px solid ${props => props.theme["LighterBlue"]};
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        padding: 12px;
    `;

    const RightSection = styled.div`
        min-width: 150px;
    `;

    const openRequestActionModal = (requestAction: string, request: Request) => {
        switch (requestAction) {
            case "Approve":
                dispatchRequests({
                    type: RequestsActions.TOGGLE_APPROVE_REQUEST_MODAL,
                    showApproveRequestModal: true,
                    selectedRequest: request,
                });
                break;
            case "Accept":
                dispatchRequests({
                    type: RequestsActions.TOGGLE_ACCEPT_REQUEST_MODAL,
                    showAcceptRequestModal: true,
                    selectedRequest: request,
                });
                break;
            case "Deny":
                dispatchRequests({
                    type: RequestsActions.TOGGLE_DENY_REQUEST_MODAL,
                    showDenyRequestModal: true,
                    selectedRequest: request,
                });
                break;
            default:
                return;
        }
    };

    const closeApproveRequestModal = () => {
        dispatchRequests({
            type: RequestsActions.TOGGLE_APPROVE_REQUEST_MODAL,
            selectedRequest: { clubInfoId: 0, playerFirstName: null, playerLastName: null, posCode: null },
            showApproveRequestModal: false,
        });
    };

    const closeAcceptRequestModal = () => {
        dispatchRequests({
            type: RequestsActions.TOGGLE_ACCEPT_REQUEST_MODAL,
            selectedRequest: { clubInfoId: 0, playerFirstName: null, playerLastName: null, posCode: null },
            showAcceptRequestModal: false,
        });
    };

    const closeDenyRequestModal = () => {
        dispatchRequests({
            type: RequestsActions.TOGGLE_DENY_REQUEST_MODAL,
            selectedRequest: { clubInfoId: 0, playerFirstName: null, playerLastName: null, posCode: null },
            showDenyRequestModal: false,
        });
    };

    const getActionBtnLabel = statusLabel => {
        switch (statusLabel) {
            case "Submitted":
                return "Accept";
            case "Accepted":
                return "Approve";
            default:
                return null;
        }
    };

    const canClickActionButton = (buttonName, selectedClubId, leagueId) => {
        switch (buttonName) {
            case "Accept":
                return canEditLeagueId(Number(leagueId));
            case "Approve":
                return canEditClubId(selectedClubId);
            case "Deny":
                return canEditClubId(selectedClubId);
        }
    };

    const listRequestActionButtonFormat = requestInfo => {
        if (requestInfo !== null && requestInfo !== undefined) {
            const buttonName = getActionBtnLabel(getLookupValue(requestInfo.statusId, state.statusLkup));
            if (buttonName !== null && canClickActionButton(buttonName, requestInfo.clubInfoId, currentLeagueId)) {
                return (
                    <ListRequestActionButton
                        onClick={() => {
                            openRequestActionModal(buttonName, requestInfo);
                        }}
                    >
                        {buttonName}
                    </ListRequestActionButton>
                );
            }
        }
        return <div />;
    };

    const actionButtonFormatter = ({ row }) => {
        if (row && row.requestInfo !== null && row.requestInfo !== undefined) {
            const buttonName = getActionBtnLabel(getLookupValue(row.requestInfo.statusId, state.statusLkup));
            if (buttonName !== null && canClickActionButton(buttonName, row.requestInfo.clubInfoId, currentLeagueId)) {
                return (
                    <RequestActionButton
                        onClick={() => {
                            openRequestActionModal(buttonName, row.requestInfo);
                        }}
                    >
                        {buttonName}
                    </RequestActionButton>
                );
            }
        }
        return <div />;
    };
    const denyButtonFormatter = ({ row }) => {
        if (row && row.requestInfo !== null && row.requestInfo !== undefined) {
            const buttonName = "Deny";
            if (
                canClickActionButton(buttonName, row.requestInfo.clubInfoId, currentLeagueId) &&
                row.requestInfo.type === 1 &&
                row.requestInfo.statusId === 2
            ) {
                return (
                    <RequestActionButton
                        onClick={() => {
                            openRequestActionModal(buttonName, row.requestInfo);
                        }}
                    >
                        {buttonName}
                    </RequestActionButton>
                );
            }
        }
        return <div />;
    };

    const browserHistory = useHistory();

    const playerFormatter = ({ row, format }) => {
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", row.requestInfo.personId);
        return <AnchorLink onClick={() => browserHistory.push(`${route}`)}>{row.player}</AnchorLink>;
    };

    // the fromClub and toClub Formatters check is the request type is a partner_trade (type === 1)
    const fromClubFormatter = ({ row, format }) => {
        // for partner trades the fromClub is the club which made the request - row.partnerClub
        // in partner trades a partner league team is requesting to SEND a player
        const output = row.type === 1 ? row.partnerClub : row.club;
        return output;
    };
    const toClubFormatter = ({ row, format }) => {
        // for mlb requests the toClub is the club which made the request - row.club
        // in mlb requests, the mlb team is requesting to RECEIVE/SIGN a player
        const output = row.type === 1 ? row.club : row.partnerClub;
        return output;
    };

    const statusFormatter = ({ value }) => {
        switch (value) {
            case "Submitted":
            case "Accepted":
                // return <StatusHighlight>{value}</StatusHighlight>;
                return <StatusHighlight>Submitted</StatusHighlight>;
            default:
                return value;
        }
    };

    const typeFormatter = ({ value }) => {
        switch (value) {
            case 1:
                return "Partner Transfer";
            case 2:
                return "MLB Request";
            default:
                return "MLB Request";
        }
    };

    const listStatusFormatter = value => {
        switch (value) {
            case "Submitted":
            case "Accepted":
                return <ListStatusHighlight>{value}</ListStatusHighlight>;
            default:
                return <ListStatus>{value}</ListStatus>;
        }
    };

    const columns: Array<Column> = [
        { name: "", key: "requestInfo", formatter: actionButtonFormatter },
        { name: "", key: "requestInfo", formatter: denyButtonFormatter },
        { name: "Status", key: "status", formatter: statusFormatter, sortable: true },
        { name: "Type", key: "type", formatter: typeFormatter, sortable: true },
        { name: "Player", key: "player", width: 200, formatter: playerFormatter, sortable: true },
        { name: "Pos", key: "position", width: 50, sortable: true },
        { name: "Org", key: "org", width: 50, sortable: true },
        { name: "Request Date", key: "requestDate", width: 100, sortable: true },
        { name: "Expires", key: "expiresDate", sortable: true },
        { name: "From Club", key: "club", formatter: fromClubFormatter, sortable: true },
        { name: "To Club", key: "partnerClub", formatter: toClubFormatter, sortable: true },
    ];

    const renderRequestRows = (
        requests: Request[],
        statusLkups: Lookup[],
        clubLkup: Lookup[],
    ): RequestDisplayInfo[] => {
        if (requests !== null && requests !== undefined) {
            return requests.map(req => {
                return {
                    requestInfo: req,
                    status: getLookupValue(req.statusId, statusLkups),
                    player: req.player,
                    position: req.posCode,
                    org: req.orgCode,
                    requestDate: formatDate(req.submitted),
                    expiresDate: formatDate(req.expires, "MM/DD/YYYY - hh:mma"),
                    club: getLookupValue(req.clubInfoId, clubLkup),
                    partnerClub: req.partnerClub,
                    type: req.type,
                };
            });
        }
        return [];
    };

    const getRequestItem = (request: Request): JSX.Element => {
        return (
            <div key={request.partnerRequestId}>
                <RequestItemHeader>
                    <div>
                        <GrayItalisized>
                            {`Requested - `}
                            <ShortDate value={request.submitted} />
                            {` - ${request.orgCode}`}
                        </GrayItalisized>
                    </div>
                    <div>{listStatusFormatter(getLookupValue(request.statusId, state.statusLkup))}</div>
                </RequestItemHeader>
                <RequestItemBody>
                    <div>
                        <div>
                            <AnchorLink
                                onClick={() =>
                                    browserHistory.push(
                                        `${RouteConstants.PLAYER_PROFILE.replace(
                                            ":playerId",
                                            String(request.personId),
                                        )}`,
                                    )
                                }
                            >
                                {request.playerLastName + ", " + request.playerFirstName}
                            </AnchorLink>{" "}
                            - <span>{request.posCode} </span>
                        </div>
                        <div>{request.club}</div>
                    </div>
                    <RightSection>
                        <RightSection>{listRequestActionButtonFormat(request)}</RightSection>
                        <RightSection>
                            <GrayItalisized>
                                {request.expires === null ? null : `Expires - `}{" "}
                                <ShortDateTime value={request.expires} />
                            </GrayItalisized>
                        </RightSection>
                    </RightSection>
                </RequestItemBody>
            </div>
        );
    };

    const approveMessage = `Are you sure you want to approve the request for ${state.selectedRequest.playerFirstName} 
                    ${state.selectedRequest.playerLastName} - ${state.selectedRequest.posCode}?`;

    const acceptMessage = `Are you sure you want to accept the request for ${state.selectedRequest.playerFirstName} 
                    ${state.selectedRequest.playerLastName} - ${state.selectedRequest.posCode}? The request will expire 48
                    hours after it has been accepted.`;

    const denyMessage = `Are you sure you want to deny the trade for ${state.selectedRequest.playerFirstName} 
                    ${state.selectedRequest.playerLastName} - ${state.selectedRequest.posCode}? The trade will expire 48
                    hours after it has been submitted.`;

    return (
        <div>
            <RequestActionModal
                show={state.showApproveRequestModal}
                close={closeApproveRequestModal}
                title="Approve"
                message={approveMessage}
                statusName="Approved"
            />
            <RequestActionModal
                show={state.showAcceptRequestModal}
                close={closeAcceptRequestModal}
                title="Accept"
                message={acceptMessage}
                statusName="Accepted"
            />
            <RequestActionModal
                show={state.showDenyRequestModal}
                close={closeDenyRequestModal}
                title="Deny"
                message={denyMessage}
                statusName="Denied"
            />

            <div className="d-flex row mx-2 mb-2">
                <div className="col-lg-4 col-md-6 pt-lg-0 pt-md-0 pt-sm-2 my-3">
                    <BestSelect
                        value={state.selectedYear}
                        options={state.yearList}
                        onChange={val => {
                            dispatchRequests({ type: RequestsActions.SET_SELECTED_YEAR, selectedYear: val });
                        }}
                    />
                </div>
                <div className="col-lg-4 col-md-6 pt-lg-0 pt-md-0 pt-sm-2 my-3">
                    <BestSelect
                        // display 'Submitted' when filtering by submitted or accepted
                        value={state.selectedStatus.id === "2" ? state.statusLkup[1] : state.selectedStatus}
                        // remove accepted from options list
                        options={state.statusLkup.filter(val => val.id !== "2")}
                        onChange={val => {
                            dispatchRequests({
                                type: RequestsActions.SET_SELECTED_STATUS,
                                // if filtering by submitted status, filter by accepted instead
                                selectedStatus: val.id === "1" ? state.statusLkup[2] : val,
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 col-md-6 pt-lg-0 pt-md-0 pt-sm-2 my-3">
                    <BestSelect
                        value={state.selectedClub}
                        options={state.clubLookup}
                        onChange={val => {
                            dispatchRequests({ type: RequestsActions.SET_SELECTED_CLUB, selectedClub: val });
                        }}
                    />
                </div>
            </div>
            <LargeAndLargerView>
                <TableWrapper className="mx-4 thin-scroll">
                    <GrayItalisized>{state.requests.length} requests</GrayItalisized>
                    <PartnerTable
                        data={renderRequestRows(state.requests, state.statusLkup, state.clubLookup)}
                        columns={columns}
                        defaultSort="requestDate"
                        sortOrder="DESC"
                    />
                </TableWrapper>
            </LargeAndLargerView>

            <MediumAndSmallerView>
                <RequestList>{state.requests.map(getRequestItem)}</RequestList>
            </MediumAndSmallerView>
        </div>
    );
};

export default Requests;
