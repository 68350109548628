import React, { useReducer } from "react";
import PropTypes from "prop-types";
import PersonPartnerHistoryContext, {
    initialState,
    PersonPartnerHistoryAction,
    PersonPartnerHistoryActions,
    PersonPartnerHistoryState,
} from "../../contexts/history/history.context";

const PersonPartnerHistoryProvider: React.FC = ({ children }) => {
    const reducer: React.Reducer<PersonPartnerHistoryState, PersonPartnerHistoryAction> = (state, action) => {
        switch (action.type) {
            case PersonPartnerHistoryActions.LOOKUPS_LOADED:
                return {
                    ...state,
                    activityLookup: action.activityLookup,
                    selectedActivity: action.selectedActivity,
                    clubLookup: action.clubLookup,
                    selectedClub: action.selectedClub,
                    form: {
                        ...state.form,
                        [action.property]: action.value,
                    },
                    formLoaded: true,
                };
            case PersonPartnerHistoryActions.FORM_FIELD_CHANGED:
                return {
                    ...state,
                    form: {
                        ...state.form,
                        [action.property]: action.value,
                    },
                };
            case PersonPartnerHistoryActions.ACTIVITY_CHANGED:
                return {
                    ...state,
                    selectedActivity: action.selectedActivity,
                    form: {
                        ...state.form,
                        activityTypeId: Number(action.selectedActivity.id),
                    },
                };
            case PersonPartnerHistoryActions.CLUB_CHANGED:
                return {
                    ...state,
                    selectedClub: action.selectedClub,
                    form: {
                        ...state.form,
                        clubId: Number(action.selectedClub.id),
                    },
                };
            case PersonPartnerHistoryActions.HISTORY_CHANGED:
                return {
                    ...state,
                    history: action.history,
                };
            default:
                return { ...state };
        }
    };

    const [state, dispatchHistory] = useReducer(reducer, initialState);

    return (
        <PersonPartnerHistoryContext.Provider value={{ state, dispatchHistory }}>
            {children}
        </PersonPartnerHistoryContext.Provider>
    );
};

PersonPartnerHistoryProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PersonPartnerHistoryProvider;
