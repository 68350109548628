import React, { useContext, useEffect, useState } from "react";
import { BestSelect, Modal } from "best-common-react";
import styled from "styled-components";
import { PrimaryOutlineButtonStyled } from "../player-profile.component";
import { Player } from "../../../models/player/player.models";
import PlayerProfileContext from "../../../contexts/player-profile/player-profile.context";
import { Lookup } from "../../../models/lookups/lookups.models";
import RequestsApi from "../../../api/RequestsApi";
import { Request } from "../../../models/requests/requests.models";
import { useAlert } from "../../../contexts/AlertsContext";

export interface TradePlayerModalProps {
    player: Player;
    setShowModal: (show: boolean) => void;
    showModal: boolean;
}

const TableWrap = styled.div`
    font-size: 14px;
    padding: 20px;
    .merge-table-header {
        width: 20px;
    }
    table {
        width: auto;
        margin: 20px auto;
        border: 1px solid #c9c9c9;
        max-width: 100%;
    }
    td,
    th {
        width: 200px;
        border: 1px solid #c9c9c9;
        border-right: 1px solid #e6e7e8;
        border-bottom: 1px solid #e6e7e8;
        padding: 3px 6px 3px 6px;
        line-height: 23px;
        height: 32px;
    }
    td:first-child {
        border-left: 0;
        background-color: #f2f3f4;
    }
`;
const ModalBodyWrap = styled.div`
    overflow-x: scroll;
    min-height: 500px;
    display: flex;
    flex-direction: column;
`;

const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 30px;
`;

const CloseModalButton = styled.span`
    font-size: 18px;
    color: ${props => props.theme["Blue"]};
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

// using this to initialize clubOptions gets rid of a controlled / uncontrolled input warning
const initialLookup = {
    activeFlag: true,
    code: "",
    id: "",
    label: "",
    seqno: "",
    value: "",
};

const TradePlayerModal: React.FC<TradePlayerModalProps> = ({ player, setShowModal }) => {
    const playerProfileContext = useContext(PlayerProfileContext);
    const { lookups, conditionalLookups } = playerProfileContext.state;
    const [position, setPosition] = useState<Lookup>(player.playerPosition);
    const [clubOptions, setClubOptions] = useState<Array<Lookup>>([initialLookup]);
    const [toClub, setToClub] = useState<Lookup>(initialLookup);
    const showAlert = useAlert();

    const getClubList = () => {
        const clubList = [];
        if (conditionalLookups) {
            Object.keys(conditionalLookups.allClubs).forEach(key => {
                clubList.push(...conditionalLookups.allClubs[key]);
            });
        }
        const filteredClubList = clubList.filter(club => Number(club.id) !== player.partnerClubInfoId);
        setToClub(filteredClubList[0]);
        setClubOptions(filteredClubList);
    };

    const onCancel = () => {
        setShowModal(false);
    };
    const onSubmit = () => {
        const tradeRequest = {
            statusId: 2, // Accepted status
            player: `${player.lastName}, ${player.firstName}`,
            playerFirstName: player.firstName,
            playerLastName: player.lastName,
            posCode: player.position,
            club: player.partnerClubName,
            // receiving, approving club / "toClub"
            clubInfoId: Number(toClub.id),
            // requesting club / "fromClub" / the player's current club
            requestingClubInfoId: player.partnerClubInfoId,
            personId: player.personId,
            type: 1, // partner_trade typeId
        };

        RequestsApi.saveRequest(tradeRequest.clubInfoId, tradeRequest).then(data => {
            setShowModal(false);
            showAlert("Submitted", "success");
        });
    };

    useEffect(() => {
        getClubList();
    }, []);

    return (
        <>
            <Modal show={true} size="lg">
                <Modal.Header>
                    <HeaderWrap>
                        Transfer Player
                        <CloseModalButton className="fa fa-times-circle fa-xs" onClick={() => setShowModal(false)} />
                    </HeaderWrap>
                </Modal.Header>
                <Modal.Body>
                    <ModalBodyWrap>
                        <>
                            <TableWrap>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Player</th>
                                            <th>Pos</th>
                                            <th>From Club</th>
                                            <th>To Club</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{`${player.lastName}, ${player.firstName}`}</td>
                                            <td>
                                                {" "}
                                                <BestSelect
                                                    styles={{ singleValue: { color: "#495057" } }}
                                                    id="trade-player-pos"
                                                    options={lookups.PRO_PLAYER_POSITIONS}
                                                    value={position}
                                                    onChange={(pos: Lookup) => setPosition(pos)}
                                                    isDisabled={true}
                                                />
                                            </td>

                                            <td>{player.partnerClubName}</td>
                                            <td>
                                                {" "}
                                                <BestSelect
                                                    styles={{ singleValue: { color: "#495057" } }}
                                                    id="trade-player-to-club"
                                                    options={clubOptions}
                                                    value={toClub}
                                                    onChange={(club: Lookup) => setToClub(club)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableWrap>
                        </>
                    </ModalBodyWrap>
                </Modal.Body>

                <Modal.Footer>
                    <PrimaryOutlineButtonStyled onClick={() => onSubmit()}>
                        Submit Transfer Request
                    </PrimaryOutlineButtonStyled>
                    <PrimaryOutlineButtonStyled onClick={() => onCancel()}>Cancel</PrimaryOutlineButtonStyled>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TradePlayerModal;
function showAlert(statusName: any, arg1: string) {
    throw new Error("Function not implemented.");
}
