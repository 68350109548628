import React from "react";
import Login from "./login.component";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouteConstants } from "./../../constants/RouteConstants";
import { LoginCallback } from "@okta/okta-react";

const UnauthenticatedApp = ({}) => {
    return (
        <span>
            <Switch>
                <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
                <Route component={Login} path={RouteConstants.BASE} />
            </Switch>
        </span>
    );
};

export default UnauthenticatedApp;
