import React from "react";
import Responsive from "react-responsive";
import { PixelBreakpointConstants } from "../../constants/PixelBreakpointConstants";

// Responsive views based on current screen width
const SmallView = props => (
    <Responsive {...props} minWidth={PixelBreakpointConstants.SMALL} maxWidth={PixelBreakpointConstants.MEDIUM - 1} />
);
const MediumView = props => (
    <Responsive {...props} minWidth={PixelBreakpointConstants.MEDIUM} maxWidth={PixelBreakpointConstants.LARGE - 1} />
);
const LargeView = props => (
    <Responsive {...props} minWidth={PixelBreakpointConstants.LARGE} maxWidth={PixelBreakpointConstants.XLARGE - 1} />
);
const XLargeView = props => <Responsive {...props} minWidth={PixelBreakpointConstants.XLARGE} />;
const MediumAndSmallerView = props => <Responsive {...props} maxWidth={PixelBreakpointConstants.LARGE - 1} />;
const MediumAndLargerView = props => <Responsive {...props} minWidth={PixelBreakpointConstants.MEDIUM} />;
const LargeAndLargerView = props => <Responsive {...props} minWidth={PixelBreakpointConstants.LARGE} />;

export { SmallView, MediumView, LargeView, XLargeView, MediumAndSmallerView, MediumAndLargerView, LargeAndLargerView };
