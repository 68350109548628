import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";
import { Player, ClubLogo } from "../models/player/player.models";

const API_URL = `${SERVICES_URL}/player`;

export const PlayerApi = {
    checkNewPlayerMatches: (player: Player): Promise<Array<Player>> =>
        axios
            .post(`${API_URL}/matches`, player, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    getPlayer: (playerId: number): Promise<Player> =>
        axios.get(`${API_URL}/${playerId}`).then((response: { data: any }) => response.data),
    savePlayerInfo: (player: Player): Promise<Player> =>
        axios
            .post(`${API_URL}/info/${player.personId}`, player, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    getClubLogo: (clubInfoId: number): Promise<ClubLogo> =>
        axios.get(`${API_URL}/logo/${clubInfoId}`).then((response: { data: any }) => response.data),
};
