import React from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useLoading } from "../contexts/LoadingContext";
import { useModal } from "../contexts/ModalContext";
import axiosRetry from "axios-retry";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "./../constants/RouteConstants";

interface HttpInterceptorProps {
    children: React.ReactNode;
}

const HttpInterceptor: React.FC<HttpInterceptorProps> = ({ children }) => {
    const { logout, loggedIn, oktaLoginFunc } = useAuth();
    const { setLoading } = useLoading();
    const { setShowServerErrorModal, setGenericBodyText, setGenericTitleText } = useModal();
    const history = useHistory();

    axiosRetry(axios, {
        retries: 3, //attempts to retry
        retryCondition: error => {
            console.log("Error fetching from API will retry...");
            return !!error?.response && error.response.status === 403;
        }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
        retryDelay: retryCount => retryCount * 1000, // wait 1 second between retrys
    });

    let pendingRequest = 0;
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
        pendingRequest++;
        setLoading(true);
        const oktaTokenStr =
            localStorage.getItem(LocalStorageConstants.OKTA_TOKEN) || '{"accessToken": "", "idToken": "" }';

        const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

        if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
            config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
            config.headers["x-id-token"] = idToken.idToken;
        }
        return config;
    });

    axios.interceptors.response.use(
        (resp: AxiosResponse) => {
            pendingRequest--;
            if (!resp) return null;
            if (pendingRequest === 0) setLoading(false);
            return resp;
        },
        (err: AxiosError) => {
            pendingRequest--;
            if (pendingRequest === 0) setLoading(false);
            if (!!err.response) {
                if (err.response.status === 401 || err.response.status === 403) {
                    logout();
                } else if (err.response.status === 404) {
                    history.replace(RouteConstants.HOME);
                } else if (err.response.status === 465) {
                    // this is "Rules Service Exception"
                    //handle business rule errors
                } else {
                    if (err.response.status === 500) {
                        setGenericTitleText("Internal Server Error");
                    } else if (err.response.status === 460) {
                        setGenericTitleText("Concurrent Modification");
                    } else if (err.response.status === 461) {
                        setGenericTitleText("Document Not Found");
                    }

                    setGenericBodyText(err.response.data.message);
                    setShowServerErrorModal(true);
                }
            }

            return Promise.reject(err);
        },
    );

    return <div>{children}</div>;
};

export default HttpInterceptor;
