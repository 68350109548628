import React, { useState, useEffect } from "react";
import { BestTable } from "best-common-react";
import Moment from "moment";

interface Props {
    data: Array<any>;
    columns: Array<any>;
    defaultSort?: string;
    sortOrder?: any;
}

const PartnerTable: React.FC<Props> = ({ data, columns, defaultSort, sortOrder }): JSX.Element => {
    const [sortColumns, setSortColumns] = useState({
        key: defaultSort,
        direction: sortOrder ? sortOrder : BestTable.ASC,
        sortedRows: [],
    });

    const onSort = (key, direction) => {
        setSortColumns({ key: key, direction: direction, sortedRows: sortColumns.sortedRows });
    };

    useEffect(() => {
        const key = sortColumns.key;
        const direction = sortColumns.direction === BestTable.DESC ? -1 : 1;

        const sortedRows = Array.from(data);
        sortedRows.sort((a, b) => {
            if (typeof a[key] === "string" && typeof b[key] === "string") {
                let date1 = new Date(a[key]);
                let date2 = new Date(b[key]);
                switch (key) {
                    case "date": //format mm/dd/yyyy - hh:mma
                    case "expiresDate":
                        date1 = Moment(a[key], "MM/DD/YYYY - hh:mma").toDate();
                        date2 = Moment(b[key], "MM/DD/YYYY - hh:mma").toDate();
                    case "birthDate": //format mm/dd/yyyy
                    case "requestDate":
                        if (a[key] === "") {
                            return -direction;
                        } else if (b[key] === "") {
                            return direction;
                        } else if (date1 < date2) {
                            return -direction;
                        } else {
                            return direction;
                        }
                    default:
                        //if sorted column isn't a date column
                        if (a[key].toLowerCase() < b[key].toLowerCase()) {
                            return -direction;
                        } else {
                            return direction;
                        }
                }
            }
        });

        setSortColumns({ key: sortColumns.key, direction: sortColumns.direction, sortedRows: sortedRows });
    }, [data, sortColumns.key, sortColumns.direction]);

    return (
        <div>
            <BestTable
                data={sortColumns.sortedRows}
                columns={columns}
                sortColumn={sortColumns.key}
                sortDirection={sortColumns.direction}
                sortFunction={onSort}
            />
        </div>
    );
};

export default PartnerTable;
