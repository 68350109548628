import React, { createContext, useContext } from "react";
import { Lookup } from "../../models/lookups/lookups.models";
export type Home = {};

type HomeContext = {
    state: HomeState;
    dispatchHome: React.Dispatch<HomeAction>;
};

export type HomeState = {
    leagueLookup?: Array<Lookup>;
    league?: Lookup;
    initiated?: boolean;
};

export type HomeAction = {
    type: string;
    leagueLookup?: Array<Lookup>;
    league?: Lookup;
    initiated?: boolean;
};

export const initialState: HomeState = {
    leagueLookup: [],
    league: {},
    initiated: false,
};

const HomeContext = createContext<HomeContext>({
    state: initialState,
    dispatchHome: null,
});

export const HomeActions = {
    INIT: "INIT",
    LEAGUE_CHANGED: "LEAGUE_CHANGED",
};

export const useHomeHistory = (): HomeContext => {
    const context = useContext<HomeContext>(HomeContext);

    if (context == undefined) {
        throw new Error(`useHistory must be used within a HomeContext`);
    }
    return context;
};

export default HomeContext;
