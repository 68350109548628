import React, { useState, useContext } from "react";
import { LookupApi } from "../../api/LookupApi";
import PlayerProfileContext, { PlayerProfileActions } from "../../contexts/player-profile/player-profile.context";
import { Collapse, Input, FormColumn4Wide, DatePickerInput, BestSelect, BestRadio } from "best-common-react";
import PlayerProfileProvider from "../../providers/player-profile/player-profile.provider";
import RequiredInputWithLabelStyled from "../common/required-input-with-label-styled.component";
import BestLabelStyled from "../common/best-label-styled.component";
import { AuthContext } from "../../contexts/AuthContext";
import styled from "styled-components";
import CollapseStyled from "../common/collapse-styled.component";
import DisabledIconWrapper from "../common/disabled-icon-wrapper.component";
import { PersonType } from "../../models/player/player.models";

const DatePickerInputStyled = styled(DatePickerInput)`
    &&&& {
        color: #495057;
    }
`;

const BioInformation = ({}) => {
    const playerProfileContext = useContext(PlayerProfileContext);
    const { player, lookups, userCanEditProfile } = playerProfileContext.state;
    const {
        birthDistrictLabel,
        birthDistricts,
        hasSector,
        hasDropdown,
        birthCities,
        birthSectors,
    } = playerProfileContext.state.conditionalLookups;

    const { partnerUserDetails: userRoleInfo } = useContext(AuthContext);

    return (
        <CollapseStyled
            title="Bio Information"
            startsOpen={true}
            iconAfterTitle={false}
            expandIcon="fa-plus-circle"
            collapseIcon="fa-minus-circle"
            styles={{ borderColor: "#dee5ed" }}
        >
            <div className="row">
                {/* Last Name */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="input"
                        label="Last Name"
                        required={userCanEditProfile && player.personTypeId === PersonType.PARTNER_PLAYER}
                    >
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            value={player.lastName !== null ? player.lastName : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { lastName: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* First Name */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="input"
                        label="First Name"
                        required={userCanEditProfile && player.personTypeId === PersonType.PARTNER_PLAYER}
                    >
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            value={player.firstName !== null ? player.firstName : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { firstName: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Middle Name */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="mname" label="Middle Name" required={false}>
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            name="mname"
                            value={player.middleName !== null ? player.middleName : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { middleName: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Extended Last */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="elname" label="Extended Last" required={false}>
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            name="elname"
                            value={player.extendedLastName !== null ? player.extendedLastName : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { extendedLastName: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
            </div>
            <div className="row">
                {/* Roster First Name */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="input"
                        label="Roster First Name"
                        required={userCanEditProfile && player.personTypeId === PersonType.PARTNER_PLAYER}
                    >
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            value={player.rosterFirstName !== null ? player.rosterFirstName : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { rosterFirstName: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Suffix */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="suffix" label="Suffix" required={false}>
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            name="suffix"
                            value={player.suffix !== null ? player.suffix : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { suffix: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Birth Date */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="profile-date-of-birth"
                        label="Birth Date"
                        required={userCanEditProfile && player.personTypeId === PersonType.PARTNER_PLAYER}
                    >
                        <DatePickerInputStyled
                            styles={{
                                icon: {
                                    color: userCanEditProfile ? "#4c8cee" : "#f2f3f4",
                                    hoverColor: userCanEditProfile ? "#041E42" : "#f2f3f4",
                                },
                            }}
                            value={player.dateOfBirthAsString}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { dateOfBirthAsString: event },
                                });
                            }}
                            showTimeSelect={false}
                            minDate={new Date("1/1/1910")}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="profile-birth-country"
                        label="Birth Country"
                        required={userCanEditProfile && player.personTypeId === PersonType.PARTNER_PLAYER}
                        disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            name="profile-birth-country"
                            options={lookups.COUNTRIES}
                            value={player.birthCountrySelected}
                            isDisabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: {
                                        birthCountryId: parseInt(value.id),
                                        birthCountrySelected: value,
                                        birthDistrictId: null,
                                        birthDistrict: "",
                                        birthDistrictSelected: {},
                                        birthCity: "",
                                        birthCityId: null,
                                        birthCitySelected: {},
                                        birthProvince: "",
                                        birthProvinceId: null,
                                        birthProvinceSelected: {},
                                        birthSector: "",
                                        birthSectorId: null,
                                        birthSectorSelected: {},
                                        birthStateSelected: {},
                                        birthStateId: null,
                                    },
                                });

                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { birthSectors: [], birthCities: [], birthDistricts: [] },
                                });

                                if (parseInt(value.id) === 1 || parseInt(value.id) === 2) {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { hasSector: false, hasDropdown: false, birthDistrictLabel: "" },
                                    });
                                } else {
                                    const districtTypeId = parseInt(lookups.COUNTRY_ADDRESS_SUBLEVEL[value.id].value);
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: {
                                            hasSector: lookups.COUNTRIES_WITH_SECTOR.some(i => i.id === value.id),
                                            hasDropdown: lookups.COUNTRIES_WITH_DROPDOWNS.some(i => i.id === value.id),
                                            birthDistrictLabel: lookups.ADDRESS_SUBLEVELS[districtTypeId].value,
                                        },
                                    });
                                }

                                LookupApi.getParentLookup("DISTRICTS", value.id).then(data => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { birthDistricts: data },
                                    });
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
            </div>

            <div className="row">
                {/* Birth District Text Input */}
                {!hasDropdown && player.birthCountryId !== 1 && player.birthCountryId !== 2 ? (
                    <FormColumn4Wide>
                        <RequiredInputWithLabelStyled
                            name="birthdistrict"
                            label={"Birth " + birthDistrictLabel}
                            required={false}
                        >
                            <Input
                                style={{ color: "#495057" }}
                                type="text"
                                name="birthdistrict"
                                value={player.birthDistrict !== null ? player.birthDistrict : ""}
                                onChange={event => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: { birthDistrict: event.target.value },
                                    });
                                }}
                                disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                            />
                        </RequiredInputWithLabelStyled>
                    </FormColumn4Wide>
                ) : (
                    <></>
                )}

                {/* Birth District Select Input */}
                {hasDropdown ? (
                    <FormColumn4Wide>
                        <RequiredInputWithLabelStyled
                            name="birth-district-select"
                            label={"Birth " + birthDistrictLabel}
                            required={false}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                name="birth-district-select"
                                isDisabled={
                                    !userCanEditProfile ||
                                    Object.keys(birthDistricts).length <= 0 ||
                                    player.personTypeId !== PersonType.PARTNER_PLAYER
                                }
                                options={birthDistricts}
                                value={player.birthDistrictSelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: {
                                            birthDistrictSelected: value,
                                            birthDistrictId: parseInt(value.id),
                                            birthCitySelected: {},
                                            birthCity: "",
                                            birthCityId: null,
                                            birthSectorSelected: {},
                                            birthSector: "",
                                            birthSectorId: null,
                                        },
                                    });

                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { birthCities: [], birthSectors: [] },
                                    });

                                    LookupApi.getParentLookup("CITIES", value.id).then(data => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                            value: { birthCities: data },
                                        });
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    </FormColumn4Wide>
                ) : (
                    <></>
                )}

                {/* Birth City */}
                <FormColumn4Wide>
                    {hasDropdown ? (
                        <RequiredInputWithLabelStyled
                            name="birth-city-select"
                            label="Birth City"
                            required={false}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                name="birth-city-select"
                                isDisabled={
                                    !userCanEditProfile ||
                                    Object.keys(birthCities).length <= 0 ||
                                    player.personTypeId !== PersonType.PARTNER_PLAYER
                                }
                                options={birthCities}
                                value={player.birthCitySelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: {
                                            birthCitySelected: value,
                                            birthCityId: parseInt(value.id),
                                            birthSectorSelected: {},
                                            birthSector: "",
                                            birthSectorId: null,
                                        },
                                    });

                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { birthSectors: [] },
                                    });

                                    LookupApi.getParentLookup("SECTORS", value.id).then(data => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                            value: { birthSectors: data },
                                        });
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <>
                            <RequiredInputWithLabelStyled name="birthCity" label="Birth City" required={false}>
                                <Input
                                    style={{ color: "#495057" }}
                                    type="text"
                                    name="birthCity"
                                    value={player.birthCity !== null ? player.birthCity : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { birthCity: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    )}
                </FormColumn4Wide>

                {/* Birth State */}
                <FormColumn4Wide>
                    {player.birthCountryId === 1 ? (
                        <RequiredInputWithLabelStyled
                            name="birth-state-select"
                            label="Birth State"
                            required={false}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                name="birth-state-select"
                                isDisabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                                options={lookups.USSTATES}
                                value={player.birthStateSelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: { birthStateSelected: value, birthStateId: parseInt(value.id) },
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <></>
                    )}
                    {player.birthCountryId === 2 ? (
                        <RequiredInputWithLabelStyled
                            name="birth-province-select"
                            label="Birth Province"
                            required={false}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                name="birth-province-select"
                                isDisabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                                options={lookups.PROVINCES}
                                value={player.birthProvinceSelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: {
                                            birthProvinceSelected: value,
                                            birthProvinceId: parseInt(value.id),
                                        },
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <></>
                    )}
                    {hasSector && hasDropdown ? (
                        <RequiredInputWithLabelStyled
                            name="birth-sector-select"
                            label="Birth Sector"
                            required={false}
                            disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                name="birth-sector-select"
                                isDisabled={
                                    !userCanEditProfile ||
                                    Object.keys(birthSectors).length <= 0 ||
                                    player.personTypeId !== PersonType.PARTNER_PLAYER
                                }
                                options={birthSectors}
                                value={player.birthSectorSelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: { birthSectorSelected: value, birthSectorId: parseInt(value.id) },
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <></>
                    )}
                    {hasSector && !hasDropdown ? (
                        <>
                            <RequiredInputWithLabelStyled name="birthSector" label="Birth Sector" required={false}>
                                <Input
                                    style={{ color: "#495057" }}
                                    type="text"
                                    name="birthSector"
                                    value={player.birthSector !== null ? player.birthSector : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { birthSector: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile || player.personTypeId !== PersonType.PARTNER_PLAYER}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}
                </FormColumn4Wide>
                {(player.birthCountrySelected.value === "United States" ||
                    player.birthCountrySelected.value === "Canada") && <FormColumn4Wide />}
                {/* Visa Required? */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="visa" label="Visa Required?" required={true}>
                        <label className="mr-3">
                            <BestRadio
                                name="visaFlag"
                                value={true}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: { visaFlag: true },
                                    });
                                }}
                                defaultChecked={player.visaFlag}
                            />
                            Yes
                        </label>
                        <label className="mr-3">
                            <BestRadio
                                name="visaFlag"
                                value={false}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: { visaFlag: false, currentVisaFlag: false },
                                    });
                                }}
                                defaultChecked={!player.visaFlag}
                            />
                            No
                        </label>
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
                {/* Visa Obtained For Current Year? */}
                {player.visaFlag && (
                    <FormColumn4Wide>
                        <RequiredInputWithLabelStyled
                            name="currentvisa"
                            label="Visa Obtained For Current Year?"
                            required={true}
                        >
                            <label className="mr-3">
                                <BestRadio
                                    name="currentVisaFlag"
                                    value={true}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { currentVisaFlag: true },
                                        });
                                    }}
                                    defaultChecked={player.currentVisaFlag}
                                />
                                Yes
                            </label>
                            <label className="mr-3">
                                <BestRadio
                                    name="currentVisaFlag"
                                    value={false}
                                    defaultChecked={!player.currentVisaFlag}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { currentVisaFlag: false },
                                        });
                                    }}
                                />
                                No
                            </label>
                        </RequiredInputWithLabelStyled>
                    </FormColumn4Wide>
                )}
            </div>
        </CollapseStyled>
    );
};

export default BioInformation;
