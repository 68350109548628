import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import { RouteConstants } from "./../../constants/RouteConstants";
import Navigation from "./../navigation/navigation.component";
import HomeContainer from "./../common/home.container";
import HomeProvider from "../../providers/common/home.provider";
import PlayerProfileContainer from "../player-profile/player-profile.container";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";
import styled from "styled-components";

const AuthenticatedApp = ({}) => {
    const location = useLocation();

    const NonLeftBarWrapper = styled.div`
        background-color: ${props => props.theme["LightGray"]};
        height: calc(100vh - 3.438em);
        flex: 1;
        overflow-y: auto;
        position: fixed;
        top: 3.438rem;
        width: 100%;
    `;

    return (
        <div>
            {(location.pathname === "/" || location.pathname.includes(RouteConstants.LOGIN)) && (
                <Redirect to={RouteConstants.HISTORY} />
            )}

            <Navigation />

            <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
            <HomeProvider>
                <SecureRoute path={RouteConstants.HOME} component={HomeContainer} />

                {location.pathname.includes("/player/") ? (
                    <NonLeftBarWrapper isNonLeftBar={location.pathname.includes("/player/")}>
                        <SecureRoute path={RouteConstants.PLAYER_PROFILE} component={PlayerProfileContainer} />
                    </NonLeftBarWrapper>
                ) : (
                    <></>
                )}
            </HomeProvider>
        </div>
    );
};

export default AuthenticatedApp;
