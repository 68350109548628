import React, { useContext } from "react";
import PlayerProfileContext, { PlayerProfileActions } from "../../contexts/player-profile/player-profile.context";
import { Collapse, BestLabel } from "best-common-react";
import FileAttachments from "./../common/file-attachment.component";
import CollapseStyled from "../common/collapse-styled.component";

const PlayerFiles: React.FC = ({}): JSX.Element => {
    const { state, dispatchPlayerProfile } = useContext(PlayerProfileContext);
    const { userCanEditProfile, player } = state;

    const attachedCallback = () => {
        dispatchPlayerProfile({
            type: PlayerProfileActions.ATTACHED_FILE,
            fileAttachments: player.fileAttachments,
        });
    };

    return (
        <CollapseStyled
            title="Attached File(s)"
            startsOpen={true}
            iconAfterTitle={false}
            expandIcon="fa-plus-circle"
            collapseIcon="fa-minus-circle"
            styles={{ borderColor: "#dee5ed" }}
        >
            <FileAttachments
                editable={userCanEditProfile}
                files={player.fileAttachments}
                attachedCallback={attachedCallback}
                deleteCallback={attachedCallback}
            />
        </CollapseStyled>
    );
};

export default PlayerFiles;
