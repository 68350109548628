import React from "react";
import PersonPartnerHistoryProvider from "./../../providers/history/history.provider";
import History from "./history.component";

const PersonPartnerHistoryContainer = ({ currentLeagueId }) => {
    return (
        <PersonPartnerHistoryProvider>
            <History currentLeagueId={currentLeagueId} />
        </PersonPartnerHistoryProvider>
    );
};

export default PersonPartnerHistoryContainer;
