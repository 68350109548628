import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";
import { Lookup } from "../models/lookups/lookups.models";

const API_URL = `${SERVICES_URL}/lookups`;

export const LookupApi = {
    getLookups: (lookups: string): Promise<Map<string, Array<Lookup>>> =>
        axios
            .post(`${API_URL}/list`, lookups, { headers: { "Content-Type": "text/plain" } })
            .then((response: { data: any }) => response.data),
    getParentLookup: (code: string, parentId: number): Promise<Array<Lookup>> =>
        axios.get(`${API_URL}/${code}/${parentId}`).then(response => response.data),
};
