import React, { useState, useContext } from "react";
import styled from "styled-components";
import { LookupApi } from "../../api/LookupApi";
import PlayerProfileContext, { PlayerProfileActions } from "../../contexts/player-profile/player-profile.context";
import {
    Collapse,
    BestLabel,
    Input,
    BestLabelWithRight,
    FormColumn4Wide,
    BestDropdown,
    NumberInput,
    BestSelect,
    RequiredInput,
    ZipCodeInput,
} from "best-common-react";
import FormHelper from "../common/form-helper";
import { Player } from "../../models/player/player.models";
import { Lookup } from "../../models/lookups/lookups.models";
import { AuthContext } from "../../contexts/AuthContext";
import BestLabelStyled from "../common/best-label-styled.component";
import RequiredInputWithLabelStyled from "../common/required-input-with-label-styled.component";
import CollapseStyled from "../common/collapse-styled.component";
import DisabledIconWrapper from "../common/disabled-icon-wrapper.component";

const Title = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    color: #92a5ba;
    font-weight: 300;
    margin-top: 8px;
    @media (min-width: 768px) {
        margin-top: 0px;
    }
`;

const Text = styled.div`
    display: inline-block;
    font-size: 14px;
    padding: 0.5rem 0.25rem;
`;

const PhoneNumberWrapper = styled.div`
    display: flex;
    align-items: center;
    .form-group {
        width: 100%;
    }
`;

const TitleHidden = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    color: #92a5ba;
    font-weight: 300;
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const getDialCode = (player: Player, lookups: any): string => {
    const dialCodes: Array<Lookup> = lookups.COUNTRY_DIAL_CODES;
    if (player.primaryPhoneCountryId === null) {
        return "";
    }
    let dialCode = "";
    dialCodes.forEach((lk, index) => {
        if (parseInt(lk.id) === player.primaryPhoneCountryId) {
            dialCode = lk.value;
        }
    });
    if (dialCode !== "") {
        dialCode = "+" + dialCode;
    }
    return dialCode;
};

const ContactInformation = ({}) => {
    const playerProfileContext = useContext(PlayerProfileContext);
    const { player, lookups, userCanEditProfile } = playerProfileContext.state;
    const {
        addressHasDropdown,
        addressDistrictLabel,
        addressDistricts,
        addressHasZip,
        addressCities,
        addressHasSector,
        addressSectors,
    } = playerProfileContext.state.conditionalLookups;

    const [districts, setDistricts] = useState([]);

    const { partnerUserDetails: userRoleInfo } = useContext(AuthContext);

    const isEmailValid = value => (value && value !== "" ? FormHelper.isValidEmail(value) : true);

    return (
        <CollapseStyled
            title="Contact Information"
            startsOpen={true}
            iconAfterTitle={false}
            expandIcon="fa-plus-circle"
            collapseIcon="fa-minus-circle"
            styles={{ borderColor: "#dee5ed" }}
        >
            <div className="row">
                <FormColumn4Wide>
                    <Title>Primary Phone</Title>
                    {/* Primary Phone Type */}
                    <RequiredInputWithLabelStyled
                        name="profile-phone-type-select"
                        label="Type"
                        required={false}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            name="profile-phone-type-select"
                            title="Type"
                            required={false}
                            options={lookups.PRO_PLAYER_PHONE_TYPES}
                            value={player.primaryPhoneType}
                            isDisabled={!userCanEditProfile}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { primaryPhoneType: value, primaryPhoneTypeId: parseInt(value.id) },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                    {/* Primary Phone Country Code */}
                    <RequiredInputWithLabelStyled
                        name="profile-phone-country-select"
                        label="Country Code"
                        required={userCanEditProfile && player.primaryPhoneTypeId !== null}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            id="profile-phone-country-select"
                            isDisabled={!userCanEditProfile}
                            options={lookups.COUNTRIES}
                            value={player.primaryPhoneCountry}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: {
                                        primaryPhoneCountry: value,
                                        primaryPhoneCountryId: parseInt(value.id),
                                    },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>

                    {/* Primary Phone Number */}
                    <RequiredInputWithLabelStyled
                        name="phonenum"
                        label="Phone Number"
                        required={userCanEditProfile && player.primaryPhoneTypeId !== null}
                    >
                        <PhoneNumberWrapper>
                            <Text>{getDialCode(player, lookups)}</Text>
                            <RequiredInput
                                validateFunc={value => {
                                    return player.primaryPhoneTypeId === null || value !== "";
                                }}
                            >
                                <Input
                                    style={{ color: "#495057" }}
                                    id="phonenum"
                                    type="text"
                                    name="phonenum"
                                    value={player.primaryPhoneNumber !== null ? player.primaryPhoneNumber : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { primaryPhoneNumber: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile}
                                />
                            </RequiredInput>
                        </PhoneNumberWrapper>
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
                <FormColumn4Wide>
                    <Title>Primary Email</Title>
                    {/* Primary Email Type */}
                    <BestLabelStyled htmlFor="profile-email-type-select">Type</BestLabelStyled>
                    <DisabledIconWrapper disabled={!userCanEditProfile}>
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            id="profile-email-type-select"
                            isDisabled={!userCanEditProfile}
                            options={lookups.EMAILTYPES}
                            value={player.primaryEmailType}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { primaryEmailType: value, primaryEmailTypeId: parseInt(value.id) },
                                });
                            }}
                        />
                    </DisabledIconWrapper>
                    {/* Primary Email Address */}
                    <RequiredInputWithLabelStyled
                        name="emailLabel"
                        label="Email"
                        required={userCanEditProfile && player.primaryEmailTypeId !== null}
                        rightLabel={isEmailValid(player.primaryEmail) ? "" : "Invalid Email"}
                    >
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            name="emailLabel"
                            value={player.primaryEmail !== null ? player.primaryEmail : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { primaryEmail: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
                <FormColumn4Wide>
                    <Title>Primary Address</Title>
                    {/* Primary Address Type */}
                    <RequiredInputWithLabelStyled
                        name="profile-address-type-select"
                        label="Type"
                        required={false}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            name="profile-address-type-select"
                            isDisabled={!userCanEditProfile}
                            options={lookups.PLAYERADDRESSTYPES}
                            value={player.primaryAddressType}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { primaryAddressType: value, primaryAddressTypeId: parseInt(value.id) },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                    {/* Primary Address 1 */}
                    <RequiredInputWithLabelStyled
                        name="primary-address-input"
                        label="Address"
                        required={userCanEditProfile && player.primaryAddressTypeId !== null}
                    >
                        <Input
                            style={{ color: "#495057" }}
                            type="text"
                            name="primary-address-input"
                            value={player.primaryAddress1 !== null ? player.primaryAddress1 : ""}
                            onChange={event => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { primaryAddress1: event.target.value },
                                });
                            }}
                            disabled={!userCanEditProfile}
                        />
                    </RequiredInputWithLabelStyled>
                    {/* Primary Address 2 */}
                    <Input
                        style={{ color: "#495057" }}
                        type="text"
                        value={player.primaryAddress2 !== null ? player.primaryAddress2 : ""}
                        onChange={event => {
                            playerProfileContext.dispatchPlayerProfile({
                                type: PlayerProfileActions.DATA_CHANGED,
                                value: { primaryAddress2: event.target.value },
                            });
                        }}
                        disabled={!userCanEditProfile}
                    />
                    {/* Primary Address 3 */}
                    <Input
                        style={{ color: "#495057" }}
                        type="text"
                        value={player.primaryAddress3 !== null ? player.primaryAddress3 : ""}
                        onChange={event => {
                            playerProfileContext.dispatchPlayerProfile({
                                type: PlayerProfileActions.DATA_CHANGED,
                                value: { primaryAddress3: event.target.value },
                            });
                        }}
                        disabled={!userCanEditProfile}
                    />
                </FormColumn4Wide>
                <FormColumn4Wide>
                    <TitleHidden>&nbsp;</TitleHidden>
                    {/* Primary Address Country */}
                    <RequiredInputWithLabelStyled
                        name="primary-address-country"
                        label="Country"
                        required={userCanEditProfile && player.primaryAddressTypeId !== null}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            id="primary-address-country"
                            styles={{ singleValue: { color: "#495057" } }}
                            options={lookups.COUNTRIES}
                            value={player.primaryAddressCountry}
                            isDisabled={!userCanEditProfile}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: {
                                        primaryAddressCountry: value,
                                        primaryAddressCountryId: parseInt(value.id),
                                        primaryAddressDistrictId: null,
                                        primaryAddressDistrict: "",
                                        primaryAddressDisrictSelected: {},
                                        primaryAddressCity: "",
                                        primaryAddressCityId: null,
                                        primaryAddressCitySelected: {},
                                        primaryAddressProvince: "",
                                        primaryAddressProvinceId: null,
                                        primaryAddressProvinceSelected: {},
                                        primaryAddressSector: "",
                                        primaryAddressSectorId: null,
                                        primaryAddressSectorSelected: {},
                                        primaryAddressStateSelected: {},
                                        primaryAddressStateId: null,
                                        primaryAddressState: "",
                                    },
                                });

                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                    value: { addressSectors: [], addressCities: [], addressDistricts: [] },
                                });

                                if (parseInt(value.id) === 1 || parseInt(value.id) === 2) {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: {
                                            addressHasSector: false,
                                            addressHasDropdown: false,
                                            addressHasZip: lookups.COUNTRIES_WITH_ZIP.some(i => i.id === value.id),
                                            addressDistrictLabel: "",
                                        },
                                    });
                                } else {
                                    const districtTypeId = parseInt(lookups.COUNTRY_ADDRESS_SUBLEVEL[value.id].value);
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: {
                                            addressHasSector: lookups.COUNTRIES_WITH_SECTOR.some(
                                                i => i.id === value.id,
                                            ),
                                            addressHasDropdown: lookups.COUNTRIES_WITH_DROPDOWNS.some(
                                                i => i.id === value.id,
                                            ),
                                            addressHasZip: lookups.COUNTRIES_WITH_ZIP.some(i => i.id === value.id),
                                            addressDistrictLabel: lookups.ADDRESS_SUBLEVELS[districtTypeId].value,
                                        },
                                    });
                                }

                                LookupApi.getParentLookup("DISTRICTS", value.id).then(data => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { addressDistricts: data },
                                    });
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                    {addressHasDropdown ? (
                        <RequiredInputWithLabelStyled
                            name="profile-address-district-select"
                            label={addressDistrictLabel}
                            required={false}
                            disabled={!userCanEditProfile}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                isDisabled={!userCanEditProfile || Object.keys(addressDistricts).length <= 0}
                                options={addressDistricts}
                                value={player.primaryAddressDistrictSelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: {
                                            primaryAddressDistrictSelected: value,
                                            primaryAddressDistrictId: parseInt(value.id),
                                        },
                                    });

                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { addressCities: [], addressSectors: [] },
                                    });

                                    LookupApi.getParentLookup("CITIES", value.id).then(data => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                            value: { addressCities: data },
                                        });
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <></>
                    )}

                    {!addressHasDropdown &&
                    player.primaryAddressCountryId &&
                    player.primaryAddressCountryId !== 1 &&
                    player.primaryAddressCountryId !== 2 ? (
                        <>
                            <RequiredInputWithLabelStyled
                                name="addDistrict"
                                label={addressDistrictLabel}
                                required={false}
                            >
                                <Input
                                    style={{ color: "#495057" }}
                                    type="text"
                                    name="addDistrict"
                                    value={player.primaryAddressDistrict !== null ? player.primaryAddressDistrict : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { primaryAddressDistrict: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}
                    {/* Primary Address City */}
                    {addressHasDropdown ? (
                        <RequiredInputWithLabelStyled
                            name="primary-address-city-select"
                            label="City"
                            required={userCanEditProfile && player.primaryAddressTypeId !== null}
                            disabled={!userCanEditProfile}
                        >
                            <BestSelect
                                styles={{ singleValue: { color: "#495057" } }}
                                isDisabled={!userCanEditProfile || Object.keys(addressCities).length <= 0}
                                options={addressCities}
                                value={player.primaryAddressCitySelected}
                                onChange={value => {
                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.DATA_CHANGED,
                                        value: {
                                            primaryAddressCitySelected: value,
                                            primaryAddressCityId: parseInt(value.id),
                                        },
                                    });

                                    playerProfileContext.dispatchPlayerProfile({
                                        type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                        value: { addressSectors: [] },
                                    });

                                    LookupApi.getParentLookup("SECTORS", value.id).then(data => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.SET_CONDITIONAL_LOOKUPS,
                                            value: { addressSectors: data },
                                        });
                                    });
                                }}
                            />
                        </RequiredInputWithLabelStyled>
                    ) : (
                        <></>
                    )}

                    {!addressHasDropdown ? (
                        <>
                            <RequiredInputWithLabelStyled name="addCity" label="City" required={false}>
                                <Input
                                    style={{ color: "#495057" }}
                                    type="text"
                                    name="addCity"
                                    value={player.primaryAddressCity !== null ? player.primaryAddressCity : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { primaryAddressCity: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}

                    {player.primaryAddressCountryId === 1 ? (
                        <>
                            <RequiredInputWithLabelStyled
                                name="primary-address-state-select"
                                label="State"
                                required={false}
                                disabled={!userCanEditProfile}
                            >
                                <BestSelect
                                    styles={{ singleValue: { color: "#495057" } }}
                                    name="primary-address-state-select"
                                    isDisabled={!userCanEditProfile}
                                    options={lookups.USSTATES}
                                    value={player.primaryAddressStateSelected}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: {
                                                primaryAddressStateSelected: value,
                                                primaryAddressStateId: parseInt(value.id),
                                            },
                                        });
                                    }}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}

                    {player.primaryAddressCountryId === 2 ? (
                        <>
                            <RequiredInputWithLabelStyled
                                name="primary-address-province-select"
                                label="Province"
                                required={false}
                                disabled={!userCanEditProfile}
                            >
                                <BestSelect
                                    styles={{ singleValue: { color: "#495057" } }}
                                    name="primary-address-province-select"
                                    isDisabled={!userCanEditProfile}
                                    options={lookups.PROVINCES}
                                    value={player.primaryAddressProvinceSelected}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: {
                                                primaryAddressProvinceSelected: value,
                                                primaryAddressProvinceId: parseInt(value.id),
                                            },
                                        });
                                    }}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}

                    {addressHasDropdown && addressHasSector ? (
                        <>
                            <RequiredInputWithLabelStyled
                                name="primary-address-sector-select"
                                label="Sector"
                                required={false}
                                disabled={!userCanEditProfile}
                            >
                                <BestSelect
                                    styles={{ singleValue: { color: "#495057" } }}
                                    name="primary-address-sector-select"
                                    isDisabled={!userCanEditProfile}
                                    options={addressSectors}
                                    value={player.primaryAddressSectorSelected}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: {
                                                primaryAddressSectorSelected: value,
                                                primaryAddressSectorId: parseInt(value.id),
                                            },
                                        });
                                    }}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}

                    {!addressHasDropdown && addressHasSector ? (
                        <>
                            <RequiredInputWithLabelStyled name="addSector" label="Sector" required={false}>
                                <Input
                                    style={{ color: "#495057" }}
                                    type="text"
                                    name="addSector"
                                    value={player.primaryAddressSector !== null ? player.primaryAddressSector : ""}
                                    onChange={event => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { primaryAddressSector: event.target.value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}

                    {/* Primary Address Zip Code */}
                    {addressHasZip ? (
                        <>
                            <RequiredInputWithLabelStyled name="addZip" label="Zip Code" required={false}>
                                <ZipCodeInput
                                    style={{ color: "#495057" }}
                                    value={player.primaryAddressZip !== null ? player.primaryAddressZip : ""}
                                    name="addZip"
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { primaryAddressZip: value },
                                        });
                                    }}
                                    disabled={!userCanEditProfile}
                                />
                            </RequiredInputWithLabelStyled>
                        </>
                    ) : (
                        <></>
                    )}
                </FormColumn4Wide>
            </div>
        </CollapseStyled>
    );
};

export default ContactInformation;
