import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";

const Header = styled.div.attrs(() => ({
    className: "d-flex mb-2",
}))`
    &&& {
        border-bottom: 1px solid ${props => props.styles.borderColor};
        box-shadow: 0px 1px 0px #ffffff;
    }
`;

const Title = styled.div.attrs(() => ({
    className: "ml-2",
}))`
    &&& {
        width: 100%;
        font-family: Helvetica;
        font-size: 1.125rem;
        color: ${props => props.theme["LightBlue"]};
    }
`;

const CollapseStyled = ({
    startsOpen,
    expandIcon,
    collapseIcon,
    iconAfterTitle,
    emptyText,
    title,
    children,
    styles,
}) => {
    const [isOpen, setIsOpen] = useState(startsOpen);
    const Theme = useContext(ThemeContext);
    const DefaultStyle = {
        borderColor: Theme["primary-blue"],
        titleColor: Theme["primary-blue"],
    };
    const CompStyles = { ...DefaultStyle, ...styles };
    const getTitle = () => {
        return <Title styles={CompStyles}>{title}</Title>;
    };
    const getIcon = () => {
        if (emptyText && emptyText.length && (!children || children.length === 0)) {
            return <span className="empty-text">{emptyText}</span>;
        } else if (isOpen) {
            return <Icon styles={{ color: "#4c8cee" }} iconName={collapseIcon} />;
        } else {
            return <Icon styles={{ color: "#4c8cee" }} iconName={expandIcon} />;
        }
    };

    return (
        <div className="best-collapse">
            <Header className="d-flex mb-2" onClick={() => setIsOpen(!isOpen)} styles={CompStyles}>
                {iconAfterTitle ? getTitle() : ""}
                {getIcon()} {iconAfterTitle ? "" : getTitle()}
            </Header>
            {isOpen && children}
        </div>
    );
};

CollapseStyled.defaultProps = {
    startsOpen: true,
    expandIcon: "fa-plus-circle",
    collapseIcon: "fa-minus-circle",
    iconAfterTitle: false,
    emptyText: "",
    styles: {},
};

CollapseStyled.propTypes = {
    /** children of the collapse */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    /** Icon to use when not collapsed */
    collapseIcon: PropTypes.string,
    /** Text to show (instead of icon) if there are no children */
    emptyText: PropTypes.string,
    /** Icon to use when collapsed */
    expandIcon: PropTypes.string,
    /** Should the collapse icon be after the title */
    iconAfterTitle: PropTypes.bool,
    /** Should the collapse start open */
    startsOpen: PropTypes.bool,
    /** styles override */
    styles: PropTypes.object,
    /** title of the collapse */
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default CollapseStyled;
