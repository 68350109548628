import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";

const API_URL = `${SERVICES_URL}`;

export const UsersApi = {
    getUserInfo: (): Promise<any> => axios.get(`${API_URL}/user`).then(response => response.data),
    getAllUsers: (): Promise<any> => axios.get(`${API_URL}/all`).then(response => response.data),
    assignRole: (role, userId): Promise<any> =>
        axios.post(`${API_URL}/${userId}/assignRole/${role}`).then(response => response.data),
};
