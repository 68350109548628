import React from "react";

export const getLookupValue = (id, list) => {
    if (id && id !== null && list && list !== null) {
        const result = list.filter(lkup => Number(lkup.id) === Number(id));
        if (result.length > 0) {
            return result[0].label;
        }
    }

    return null;
};

export const getLookupId = (desc, list) => {
    if (desc && desc !== null && list && list !== null) {
        const result = list.filter(lkup => lkup.label === desc);
        if (result.length > 0) {
            return result[0].id;
        }
    }
};

export const getLookup = (lookups, lkupCode) => {
    if (lookups && lookups !== null) {
        if (lookups.indexOf(lkupCode) > -1) {
            return lookups[lkupCode];
        }
    }

    return [];
};
