import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

type LoadingContext = {
    isLoading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
};

const LoadingContext = createContext<LoadingContext>({ isLoading: false, setLoading: null });

const LoadingContextProvider: React.FC = ({ children }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};

const useLoading = (): LoadingContext => {
    const context = useContext<LoadingContext>(LoadingContext);
    if (context === undefined) {
        throw new Error(`useLoading must be used within a LoadingContextProvider`);
    }
    return context;
};

export { LoadingContextProvider, useLoading };
