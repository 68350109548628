import React from "react";
import RequestsProvider from "../../providers/requests/requests.provider";
import Requests from "./requests.component";

const RequestsContainer = ({ currentLeagueId }) => {
    return (
        <RequestsProvider>
            <Requests currentLeagueId={currentLeagueId} />
        </RequestsProvider>
    );
};

export default RequestsContainer;
