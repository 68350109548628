import React from "react";
import styled from "styled-components";

const LeagueNavigator = ({ onClick, currentLeagueId, leagueId, children }) => {
    const LeagueButton = styled.div`
        width: 100%;
        height: 5rem;
        padding: 0 0;
        cursor: pointer;

        &.selected {
            background-color: ${props => props.theme["Blue"]};
            color: white;
        }
    `;

    return (
        <LeagueButton
            onClick={onClick}
            className={"d-none d-lg-block " + (leagueId === currentLeagueId ? "selected" : "")}
        >
            {children}
        </LeagueButton>
    );
};

export default LeagueNavigator;
