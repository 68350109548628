const FormHelper = {
    // Used to determine if something is defined. Useful for checking if required fields have been entered
    isDefined: obj => {
        if (obj === null || obj === undefined) {
            return false;
        } else if (obj.hasOwnProperty("value") && obj.hasOwnProperty("label")) {
            // Object is a dropdown item, so we want to check if it has a value entered
            // We do a truthy/falsy comparison because when a dropdown is "Blank"
            // There is still an object selected, usually
            // { value: 0, label: "" }
            // So value and label will both be falsy
            // We care about not having a blank label though, because the ids (value) are generally unknown
            return !!obj.label;
        } else {
            // We have a regular object
            return !!obj;
        }
    },

    isAnyDefined: objs => {
        if (objs === null || objs === undefined) {
            return false;
        } else {
            return objs.some(FormHelper.isDefined);
        }
    },

    isAllDefined: objs => {
        if (objs === null || objs === undefined) {
            return false;
        } else {
            return objs.every(FormHelper.isDefined);
        }
    },

    areAllPropertiesDefined: obj => {
        let result = true;
        if (obj !== null || obj !== undefined) {
            Object.keys(obj).forEach(prop => {
                if (!FormHelper.isDefined(obj[prop])) {
                    result = false;
                }
            });
        } else {
            result = false;
        }

        return result;
    },

    isValidEmail: obj => {
        // eslint-disable-next-line no-useless-escape
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(obj.toString());
    },

    validateObject: (objToValidate, objKeysList) => {
        let result = true;
        objKeysList.forEach(key => {
            if (!FormHelper.isDefined(objToValidate[key])) {
                result = false;
            }
        });
        return result;
    },
};
export default FormHelper;
