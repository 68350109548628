import { FileAttachment } from "./../common/file-attachments.model";

export interface Player {
    personId?: number;
    personTypeId?: number;
    partnerClubInfoId: number;
    firstName: string;
    rosterFirstName?: string;
    lastName: string;
    birthCountryId: number;
    birthCountry?: string;
    dateOfBirthAsString: string;
    extendedLastName?: string;
    middleName?: string;
    position?: string;
    updatedTsAsString?: string;

    throwingSide?: string;
    battingSide?: string;
    suffix?: string;
    birthCitySelected?: any;
    birthCity?: string;
    birthCityId?: number;
    birthStateSelected?: any;
    birthState?: string;
    birthStateId?: number;
    birthProvinceSelected?: any;
    birthProvince?: string;
    birthProvinceId?: number;
    birthSectorSelected?: any;
    birthSector?: string;
    birthSectorId?: number;
    birthDistrictSelected?: any;
    birthDistrict?: string;
    birthDistrictId?: number;
    uniformNumber?: number;
    weight?: number;
    playerPosition?: any;
    playerPositionId?: number;
    height?: number;
    feetSelected?: any;
    inchesSelected?: any;
    primaryPhoneTypeId?: number;
    primaryPhoneType?: any;
    primaryPhoneCountryId?: number;
    primaryPhoneCountry?: any;
    primaryPhoneNumber?: string;
    primaryEmailTypeId?: number;
    primaryEmailType?: any;
    primaryEmail?: string;
    primaryAddressTypeId?: number;
    primaryAddressType?: any;
    primaryAddress1?: string;
    primaryAddress2?: string;
    primaryAddress3?: string;
    primaryAddressCountrySelected?: any;
    primaryAddressCountryId?: number;
    primaryAddressCountry?: any;
    primaryAddressStateSelected?: any;
    primaryAddressState?: string;
    primaryAddressStateId?: number;
    primaryAddressProvinceSelected?: any;
    primaryAddressProvince?: string;
    primaryAddressProvinceId?: number;
    primaryAddressSectorSelected?: any;
    primaryAddressSector?: string;
    primaryAddressSectorId?: number;
    primaryAddressDistrictSelected?: any;
    primaryAddressDistrict?: any;
    primaryAddressDistrictId?: number;
    primaryAddressZip?: number;
    primaryAddressCitySelected?: any;
    primaryAddressCity?: string;
    primaryAddressCityId?: number;
    birthCountrySelected?: any;

    partnerClubName?: string;
    partnerLeagueId?: number;
    partnerLeague?: string;

    mlbClubInfoId?: number;
    mlbClubName?: string;

    mlbOrgId?: number;
    mlbOrgCode?: string;
    mlbOrgName?: string;

    fileAttachments?: Array<FileAttachment>;

    logoFileId?: number;

    visaFlag?: boolean;
    currentVisaFlag?: boolean;
}

export interface ClubLogo {
    partnerClubInfoId: number;
    fileIdentifier: string;
}

export const PersonType = {
    PARTNER_PLAYER: 8,
};
