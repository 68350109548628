import React, { createContext, useContext, useState } from "react";
import { AlertConstants } from "best-common-react";

type Alert = {
    id: string;
    type: string;
    text: string;
};

type AlertsContext = {
    alerts: Array<Alert>;
    addAlert: (alert: Alert) => void;
    removeAlert: (id: number) => void;
    addAlerts: (alerts: Array<Alert>) => void;
};

const AlertsContext = createContext<AlertsContext>({
    alerts: [],
    addAlert: null,
    removeAlert: null,
    addAlerts: null,
});

const getAlertId = () => {
    return (
        "_" +
        Math.random()
            .toString(36)
            .substr(2, 9)
    );
};

const AlertsProvider: React.FC = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    const addAlert = (alert: Alert) => {
        const newAlerts = [...alerts];
        if (!alert.id) {
            alert.id = getAlertId();
        }
        newAlerts.push(alert);
        setAlerts(newAlerts);
    };

    const addAlerts = (alerts: Array<Alert>) => {
        const newAlerts: Array<Alert> = [];
        alerts.forEach(alert => {
            if (!alert.id) {
                alert.id = getAlertId();
            }
            newAlerts.push(alert);
        });

        setAlerts(newAlerts);
    };

    const removeAlert = (id: number) => {
        if (id) {
            const newAlerts = alerts.filter(alert => alert.id !== id);
            setAlerts(newAlerts);
        }
    };

    return (
        <AlertsContext.Provider value={{ alerts, addAlert, removeAlert, addAlerts }}>{children}</AlertsContext.Provider>
    );
};

const useAlertContext = (): AlertsContext => {
    const context = useContext<AlertsContext>(AlertsContext);
    if (context === undefined) {
        throw new Error(`useAlertsContext must be used within a AlertsContextProvider`);
    }
    return context;
};

const useAlert = () => {
    const alertContext = useContext(AlertsContext);
    const { addAlert } = alertContext;

    return (message: string, messageType: string = AlertConstants.TYPES.SUCCESS) => {
        addAlert({
            id: null,
            type: messageType,
            text: message,
        });
    };
};

type AlertType = {
    message: string;
    messageType: string;
};

const useAlerts = () => {
    const alertContext = useContext(AlertsContext);
    const { addAlerts } = alertContext;

    return (alerts: Array<AlertType>) => {
        const alertsToAdd = alerts.map(alert => {
            return { id: null, type: alert.messageType, text: alert.message };
        });
        addAlerts(alertsToAdd);
    };
};

export { AlertsContext, AlertsProvider, useAlert, useAlertContext, useAlerts };
