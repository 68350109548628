import React, { FC, useContext, useEffect } from "react";
import styled from "styled-components";
import { DatePickerInput, BestSelect, Input, BestTable, Checkbox, AnchorLink } from "best-common-react";
import { Column } from "../../models/best-common-react/tables.component";
import { LookupApi } from "../../api/LookupApi";
import { LargeAndLargerView, MediumAndSmallerView } from "../../modules/view/views";
import PartnerLeagueApi from "../../api/PartnerLeagueApi";
import PersonPartnerHistoryContext, { PersonPartnerHistoryActions } from "../../contexts/history/history.context";
import { PersonPartnerHistory } from "../../models/history/history.models";
import { formatDate, ShortDate } from "../../modules/dates/dates";
import { RouteConstants } from "../../constants/RouteConstants";
import { useHistory } from "react-router";
import PartnerTable from "./../common/partner-table.component";

interface Props {
    currentLeagueId: number;
}

const RangeCheckboxWrapper = styled.div`
    .bcr-checkbox-wrapper {
        float: right;
    }
`;
const TableWrapper = styled.div`
    & .bcr-table-header {
        background: ${props => props.theme["LighterBlue"]};
    }
    &. bcr-header-cell {
        color: ${props => props.theme["Dark"]};
    }
`;
const GrayItalisized = styled.div`
    font-size: 12px;
    font-style: italic;
    color: ${props => props.theme["Gray"]};
`;

const HistoryList = styled.div`
    overflow: auto;
    max-height: 73vh;
`;

const HistoryItem = styled.div`
    background-color: #ffffff;
    border: 1px solid ${props => props.theme["LighterBlue"]};
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 12px;
`;

interface HistoryDisplayInfo {
    date: Date;
    displayName: string;
    positionCode: string;
    clubName: string;
    description: string;
    playerId: number;
}

const History: FC<Props> = ({ currentLeagueId }): JSX.Element => {
    const { state, dispatchHistory } = useContext(PersonPartnerHistoryContext);

    const browserHistory = useHistory();

    useEffect(() => {
        if (currentLeagueId !== null && currentLeagueId !== undefined) {
            Promise.all([
                LookupApi.getLookups("PARTNER_ACTIVITY_TYPES"),
                LookupApi.getParentLookup("PARTNER_LEAGUE_CLUB", currentLeagueId),
            ]).then(([lookups, clubLookup]) => {
                clubLookup.splice(0, 0, { id: "0", value: "All Teams", label: "All Teams" });
                dispatchHistory({
                    type: PersonPartnerHistoryActions.LOOKUPS_LOADED,
                    activityLookup: lookups["PARTNER_ACTIVITY_TYPES"],
                    // populate activity dropdown with "All Activity"
                    selectedActivity: lookups["PARTNER_ACTIVITY_TYPES"].find(lk => Number(lk.id) === 1),
                    clubLookup: clubLookup,
                    selectedClub: clubLookup[0],
                    property: "leagueId",
                    value: currentLeagueId,
                });
            });
        }
    }, []);

    useEffect(() => {
        if (state.formLoaded) {
            PartnerLeagueApi.getHistory(state.form).then(history => {
                console.log("history: ", history);
                dispatchHistory({
                    type: PersonPartnerHistoryActions.HISTORY_CHANGED,
                    history: history,
                });
            });
        }
    }, [state.form]);

    const getHistoryItem = (history: PersonPartnerHistory): JSX.Element => {
        return (
            <HistoryItem key={history.personPartnerHistoryId}>
                <div>
                    <div>
                        <AnchorLink
                            onClick={() =>
                                browserHistory.push(
                                    `${RouteConstants.PLAYER_PROFILE.replace(":playerId", String(history.playerId))}`,
                                )
                            }
                        >
                            {history.displayName}
                        </AnchorLink>{" "}
                        - <span>{history.positionCode} </span>
                    </div>
                    <GrayItalisized>{history.clubName}</GrayItalisized>
                    <div>{history.description}</div>
                </div>
                <div>
                    <GrayItalisized>
                        <ShortDate value={history.activityDt} />
                    </GrayItalisized>
                </div>
            </HistoryItem>
        );
    };

    const PlayerFormatter = ({ row, format }) => {
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", row.playerId);
        return <AnchorLink onClick={() => browserHistory.push(`${route}`)}>{row.displayName}</AnchorLink>;
    };

    const columns: Array<Column> = [
        { name: "Date", key: "date", sortable: true },
        { name: "Player", key: "displayName", formatter: PlayerFormatter, sortable: true },
        { name: "Pos", key: "positionCode", sortable: true },
        { name: "Club", key: "clubName", sortable: true },
        { name: "Description", key: "description", sortable: true },
    ];

    const renderHistoryRows = (historyData): Array<HistoryDisplayInfo> => {
        if (historyData !== null && historyData !== undefined && historyData.length > 0) {
            return historyData.map(hist => {
                return {
                    date: formatDate(hist.activityDt, "MM/DD/YYYY - hh:mma"),
                    displayName: hist.displayName,
                    positionCode: hist.positionCode,
                    clubName: hist.clubName,
                    description: hist.description === "Request Accepted" ? "Request Submitted" : hist.description,
                    playerId: hist.playerId,
                };
            });
        }
        return [];
    };

    return (
        <div>
            <div className="d-flex row mt-2">
                <RangeCheckboxWrapper className="col-lg-4 col-md-6 mr-sm-4">
                    <Checkbox
                        label="range"
                        onChange={event => {
                            dispatchHistory({
                                type: PersonPartnerHistoryActions.FORM_FIELD_CHANGED,
                                property: "range",
                                value: event.target.checked,
                            });
                        }}
                        checked={state.form.range}
                    />
                </RangeCheckboxWrapper>
            </div>
            <div className="d-flex row mx-lg-2 mx-0 mb-4">
                {state.form.range ? (
                    <>
                        <div className="col-lg-2 col-md-3 pt-lg-0 pt-md-0 pt-sm-2 px-0 px-lg-3">
                            <DatePickerInput
                                value={state.form.dateFrom}
                                onChange={newVal => {
                                    dispatchHistory({
                                        type: PersonPartnerHistoryActions.FORM_FIELD_CHANGED,
                                        property: "dateFrom",
                                        value: newVal,
                                    });
                                }}
                            />
                        </div>
                        <div className="col-lg-2 col-md-3 pt-lg-0 pt-md-0 pt-sm-2 px-0 px-lg-3">
                            <DatePickerInput
                                value={state.form.dateTo}
                                onChange={newVal => {
                                    dispatchHistory({
                                        type: PersonPartnerHistoryActions.FORM_FIELD_CHANGED,
                                        property: "dateTo",
                                        value: newVal,
                                    });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div className="col-lg-4 col-md-6 pt-lg-0 pt-md-0 pt-sm-2 px-0 px-lg-3">
                        <DatePickerInput
                            value={state.form.selectedDate}
                            onChange={newVal => {
                                dispatchHistory({
                                    type: PersonPartnerHistoryActions.FORM_FIELD_CHANGED,
                                    property: "selectedDate",
                                    value: newVal,
                                });
                            }}
                        />
                    </div>
                )}
                <div className="col-lg-2 col-md-6 pt-lg-0 pt-md-0 pt-sm-2 px-0 px-lg-3">
                    <BestSelect
                        value={state.selectedActivity !== null ? state.selectedActivity : ""}
                        placeholder="All Activity"
                        onChange={newVal => {
                            dispatchHistory({
                                type: PersonPartnerHistoryActions.ACTIVITY_CHANGED,
                                selectedActivity: newVal,
                            });
                        }}
                        options={state.activityLookup}
                    />
                </div>
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2 px-0 px-lg-3">
                    <BestSelect
                        value={state.selectedClub !== null ? state.selectedClub : ""}
                        placeholder="All Teams"
                        onChange={newVal => {
                            dispatchHistory({
                                type: PersonPartnerHistoryActions.CLUB_CHANGED,
                                selectedClub: newVal,
                            });
                        }}
                        options={state.clubLookup}
                    />
                </div>
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2 px-0 px-lg-3">
                    <Input
                        type="text"
                        placeholder="type to search history"
                        value={state.form.searchText !== null ? state.form.searchText : ""}
                        onChange={event => {
                            dispatchHistory({
                                type: PersonPartnerHistoryActions.FORM_FIELD_CHANGED,
                                property: "searchText",
                                value: event.target.value,
                            });
                        }}
                    />
                </div>
            </div>
            <GrayItalisized className="mx-lg-4">{state.history.length} items</GrayItalisized>
            <LargeAndLargerView>
                <TableWrapper className="mx-4 thin-scroll">
                    <PartnerTable
                        data={renderHistoryRows(state.history)}
                        columns={columns}
                        defaultSort="date"
                        sortOrder={BestTable.DESC}
                    />
                </TableWrapper>
            </LargeAndLargerView>
            <MediumAndSmallerView>
                <HistoryList>{state.history.map(getHistoryItem)}</HistoryList>
            </MediumAndSmallerView>
        </div>
    );
};

export default History;
