import { DateTimeFormatter } from "best-common-react";
import React from "react";
import Moment from "moment";

const ShortDate = (props, value) => <DateTimeFormatter {...props} dateValue={value} format="MM/DD/YYYY" />;
const ShortDateTime = (props, value) => <DateTimeFormatter {...props} dateValue={value} format="MM/DD/YYYY - hh:mma" />;

export { ShortDate, ShortDateTime };

const calculateMaxDate = () => {
    const dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    return Moment(dateObj);
};

export const formatDate = (date, formatStr = "MM/DD/YYYY") => {
    return !date ? "" : Moment(new Date(date)).format(formatStr);
};

export const formatDateStr = (dateStr, formatStr = "MM/DD/YYYY") => {
    return !dateStr ? "" : Moment(dateStr).format(formatStr);
};

export const maxFormDate = calculateMaxDate();
export const minFormDate = Moment(new Date("1/1/1910").valueOf());
export const isSameOrBefore = (dateStr1, dateStr2) => Moment(dateStr1).isSameOrBefore(dateStr2);
