const CLIENT_ID = process.env.OKTA_CLIENT_ID;
const ISSUER = process.env.OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = process.env.OKTA_REDIRECT_URI;

export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ["openid", "profile", "email", "offline_access"],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
        tokenManager: {
            expireEarlySeconds: 60,
        },
    },
};
