import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

type ModalContext = {
    showServerErrorModal: boolean;
    setShowServerErrorModal: Dispatch<SetStateAction<boolean>>;
    genericBodyText: string;
    setGenericBodyText: Dispatch<SetStateAction<string>>;
    genericTitleText: string;
    setGenericTitleText: Dispatch<SetStateAction<string>>;
};

const ModalContext = createContext<ModalContext>({
    showServerErrorModal: false,
    setShowServerErrorModal: null,
    genericBodyText: "",
    setGenericBodyText: null,
    genericTitleText: "",
    setGenericTitleText: null,
});

const useModal = (): ModalContext => {
    const context = useContext<ModalContext>(ModalContext);
    if (context === undefined) {
        throw new Error(`useModal must be used within a ModalContextProvider`);
    }
    return context;
};

const ModalProvider: React.FC = ({ children }) => {
    const [showServerErrorModal, setShowServerErrorModal] = useState<boolean>(false);
    const [genericBodyText, setGenericBodyText] = useState<string>("");
    const [genericTitleText, setGenericTitleText] = useState<string>("");
    return (
        <ModalContext.Provider
            value={{
                showServerErrorModal,
                setShowServerErrorModal,
                genericBodyText,
                setGenericBodyText,
                genericTitleText,
                setGenericTitleText,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export { ModalProvider, useModal };
