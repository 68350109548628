import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";
import { Request, RequestsForm } from "../models/requests/requests.models";

const API_URL = `${SERVICES_URL}/request`;

const RequestsApi = {
    getRequests: (leagueId: number, form: RequestsForm): Promise<Request[]> =>
        axios
            .post(`${API_URL}/${leagueId}`, form, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    saveRequest: (clubInfoId: number, form: Request): Promise<Request> =>
        axios
            .post(`${API_URL}/save/${clubInfoId}`, form, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
    tradePlayer: (clubInfoId: number, form: Request): Promise<Request> =>
        axios
            .post(`${API_URL}/trade/${clubInfoId}`, form, { headers: { "Content-Type": "application/json" } })
            .then((response: { data: any }) => response.data),
};

export default RequestsApi;
