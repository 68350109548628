import React, { useContext } from "react";
import { FileAttachment, DeleteIcon } from "best-common-react";
import { FilesApi } from "./../../api/FilesApi";
import styled from "styled-components";
import { saveAs } from "file-saver";
import PlayerProfileContext, { PlayerProfileActions } from "../../contexts/player-profile/player-profile.context";

const FileAttachments: FC = ({ files, attachedCallback, deleteCallback, editable }): JSX.Element => {
    const playerProfileContext = useContext(PlayerProfileContext);
    const { userCanEditProfile } = playerProfileContext.state;
    const uploadFile = (file: any) => {
        if (file && file !== null) {
            const formData = new FormData();
            formData.append("document", file[0]);
            FilesApi.uploadFile(formData).then(data => {
                files.push(data);

                if (attachedCallback && attachedCallback !== null) {
                    attachedCallback();
                }
            });
        }
    };

    const getFile = (event, fileName, fileIdentifier, fileAttachmentId, contentType) => {
        event.preventDefault();
        FilesApi.downloadFile(fileIdentifier).then(response => {
            if (!!response) {
                const blob = new Blob([response], { type: contentType });
                saveAs(blob, fileName);
            }
        });
    };

    const DeleteDocument = (title, fileAttachmentId) => {
        const index = files.findIndex(doc => doc.fileAttachmentId === fileAttachmentId);
        if (index >= 0 && index < files.length) {
            files.splice(index, 1);

            if (deleteCallback && deleteCallback !== null) {
                deleteCallback();
            }
        }
    };

    const AttachmentWrapper = styled.span`
        .react-grid-Container.react-grid-Container.react-grid-Container {
            visibility: ${props => props.visibility};
            margin-bottom: 16px;
        }
    `;

    const FileNameFormatter = ({ row }) => {
        return (
            <button
                className="btn btn-link"
                onClick={event =>
                    getFile(event, row.fileName, row.fileIdentifier, row.fileAttachmentId, row.contentType)
                }
            >
                {row.fileName}
            </button>
        );
    };

    const DeleteFormatter = ({ row }) => {
        if (userCanEditProfile) {
            return <DeleteIcon deleteFunc={() => DeleteDocument(row.title, row.fileAttachmentId)} />;
        }
        return null;
    };

    const cols = [
        {
            key: "fileName",
            name: "File Name",
            width: 250,
            formatter: FileNameFormatter,
        },
        {
            key: "fileSizeAsString",
            name: "Size",
        },
        {
            key: "createdTsAsString",
            name: "Uploaded",
        },
        {
            key: "createdUser",
            name: "",
        },
        {
            key: "delete",
            name: "",
            width: 40,
            formatter: DeleteFormatter,
        },
    ];

    return (
        <AttachmentWrapper visibility={files.length > 0 ? "visible" : "hidden"} className="file-attachment-wrapper">
            <FileAttachment
                editable={editable}
                height={24 + files.length * 35}
                onFileAdd={uploadFile}
                files={files}
                cols={cols}
                headerRowHeight={24}
            />
        </AttachmentWrapper>
    );
};

export default FileAttachments;
