import React from "react";
import HomeProvider from "./../../providers/common/home.provider";
import Home from "./home.component";

const HomeContainer = ({ currentLeagueId }) => {
    return (
        <>
            {/* Moved <HomeProvider> to AuthenticatedApp to prevent
            the selected league from being reset when navigating
            to the player profile.*/}
            <Home />
        </>
    );
};

export default HomeContainer;
