import React from "react";
import RostersProvider from "./../../providers/rosters/rosters.provider";
import Rosters from "./rosters.component";

const RostersContainer = ({ currentLeagueId }) => {
    return (
        <RostersProvider>
            <Rosters currentLeagueId={currentLeagueId} />
        </RostersProvider>
    );
};

export default RostersContainer;
