import React, { createContext, useContext } from "react";
export type Rosters = {};
import { Lookup } from "../../models/lookups/lookups.models";
import { LeagueClubRoster } from "../../models/rosters/LeagueClubRoster.model";
import { ClubInfo } from "../../models/clubInfo.model";
import { Player } from "../../models/player/player.models";

type RostersContext = {
    state: RostersState;
    dispatchRosters: React.Dispatch<RostersAction>;
};

export type RostersState = {
    divisionLkup?: Array<Lookup>;
    rosters?: LeagueClubRoster;
    selectedClub?: Lookup;
    selectedClubRoster?: Array<Player>;
    clubLookup?: Array<Lookup>;
    selectedPlayer?: Player;
    showRemovePlayerModal?: boolean;
    formDirty?: boolean;
    userCanEditContactInfo?: boolean;
};

export interface RostersAction extends RostersState {
    type: string;
    value?: any;
}

export const initialState: RostersState = {
    divisionLkup: [],
    rosters: {
        clubs: [],
        contactInfo: {
            contactEmail: null,
            contactName: null,
            contactPhone: null,
        },
    },
    selectedClub: { id: "0", value: "" },
    selectedClubRoster: [],
    clubLookup: [],
    selectedPlayer: {
        firstName: null,
        lastName: null,
        position: null,
        partnerClubInfoId: null,
        dateOfBirthAsString: null,
        birthCountryId: null,
    },
    formDirty: false,
    showRemovePlayerModal: false,
    userCanEditContactInfo: false,
};

const RostersContext = createContext<RostersContext>({
    state: initialState,
    dispatchRosters: null,
});

export const RostersActions = {
    INIT: "INIT",
    SET_ROSTERS: "SET_ROSTERS",
    LOAD_CLUB_LOOKUP: "LOAD_CLUB_LOOKUP",
    SET_SELECTED_CLUB: "SET_CLUB_INFO",
    SET_SELECTED_CLUB_ROSTER: "SET_SELECTED_CLUB_ROSTER",
    SET_SELECTED_PLAYER: "SET_SELECTED_PLAYER",
    TOGGLE_REMOVE_PLAYER_MODAL: "TOGGLE_REMOVE_PLAYER_MODAL",
    DATA_CHANGED: "DATA_CHANGED",
    CONTACT_SAVED: "CONTACT_SAVED",
};

export const useRosters = (): RostersContext => {
    const context = useContext<RostersContext>(RostersContext);

    if (context == undefined) {
        throw new Error(`useRosters must be used within a RostersContext`);
    }
    return context;
};

export default RostersContext;
