import React from "react";
import UnauthenticatedApp from "./UnauthenticatedApp";
import AuthenticatedApp from "./AuthenticatedApp";
import { useAuth } from "./../../contexts/AuthContext";

const ProtectedContents: React.FC = ({}) => {
    const { loggedIn } = useAuth();
    return loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />; //add authenticatedapp here once okta is set up
};

export default ProtectedContents;
