import React, { useReducer } from "react";
import NewPlayerContext, {
    initialState,
    NewPlayerAction,
    NewPlayerActions,
    NewPlayerState,
} from "./../../contexts/newplayer/newplayer.context";
import PropTypes from "prop-types";
import { formatDate, formatDateStr } from "../../modules/dates/dates";

const NewPlayerProvider = ({ children }) => {
    const reducer: React.Reducer<NewPlayerState, NewPlayerAction> = (state, action) => {
        switch (action.type) {
            case NewPlayerActions.INITIAL_DATA_LOADED:
                return {
                    ...state,
                    clubLookup: action.clubLookup,
                    countryLookup: action.countryLookup,
                    leagueId: action.leagueId,
                };
            case NewPlayerActions.CLUB_CHANGED:
                return {
                    ...state,
                    selectedClub: action.selectedClub,
                    playerInfo: {
                        ...state.playerInfo,
                        partnerClubInfoId: Number(action.selectedClub.id),
                    },
                };
            case NewPlayerActions.COUNTRY_CHANGED:
                return {
                    ...state,
                    selectedCountry: action.selectedCountry,
                    playerInfo: {
                        ...state.playerInfo,
                        birthCountryId: Number(action.selectedCountry.id),
                        birthCountry: action.selectedCountry.value,
                    },
                };
            case NewPlayerActions.FORM_FIELD_CHANGED:
                return {
                    ...state,
                    playerInfo: {
                        ...state.playerInfo,
                        [action.property]: action.value,
                    },
                };
            case NewPlayerActions.DATE_FIELD_CHANGED:
                return {
                    ...state,
                    playerInfo: {
                        ...state.playerInfo,
                        [action.property]: formatDateStr(action.value, "YYYY-MM-DD"),
                    },
                };
            case NewPlayerActions.DISPLAY_MATCHES_CHANGED:
                return {
                    ...state,
                    displayMatchModal: action.value,
                };
            case NewPlayerActions.MATCHES_FOUND:
                return {
                    ...state,
                    displayMatchModal: true,
                    playerMatches: action.value,
                };
            case NewPlayerActions.SELECT_MATCH:
                return {
                    ...state,
                    selectedPlayerId: action.value,
                };
            default:
                return { ...state };
        }
    };

    const [state, dispatchNewPlayer] = useReducer(reducer, initialState);

    return (
        <NewPlayerContext.Provider value={{ newPlayerState: state, dispatchNewPlayer }}>
            {children}
        </NewPlayerContext.Provider>
    );
};

NewPlayerProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default NewPlayerProvider;
