import axios from "axios";
import { SERVICES_URL } from "../constants/ApiConstants";

const API_URL = `${SERVICES_URL}/file`;

export const FilesApi = {
    uploadFile: (formData: FormData): Promise<any> =>
        axios
            .post(`${API_URL}`, formData, { headers: { "content-type": "multipart/form-data" } })
            .then((response: { data: any }) => response.data),

    downloadFile: (fileIdentifier): Promise<any> =>
        axios
            .get(`${API_URL}/${fileIdentifier}`, { responseType: "arraybuffer" })
            .then((response: { data: any }) => response.data),
};
