import React, { useEffect, useContext, FC } from "react";
import {
    LightButton,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeaderWithTitleAndClose,
    PrimaryOutlineButton,
    StickyFooterButtons,
} from "best-common-react";
import { useNewPlayer, NewPlayerActions } from "../../contexts/newplayer/newplayer.context";
import PlayerMatchModalBodyContent from "./player-match-modal-body.component";
import { PersonType, Player } from "../../models/player/player.models";
import PlayerProfileContext, { initialState } from "../../contexts/player-profile/player-profile.context";
import { RouteConstants } from "../../constants/RouteConstants";
import { useHistory } from "react-router";
import { useAlert } from "../../contexts/AlertsContext";
import { PartnerRosterApi } from "../../api/PartnerRosterApi";

interface Props {
    show: boolean;
    close: any;
    matches: Array<Player>;
    currentLeagueId: number;
}

export type MatchMetaData = {
    hasEbisId: boolean;
    onEbisRoster: boolean;
    onPartnerRoster: boolean;
    hasHardMatch: boolean;
    matches: Array<PlayerMatch>;
};

export interface PlayerMatch extends Player {
    fullName: string;
    onRosterOf?: string;
}

const PlayerMatchModal: React.FC<Props> = ({ show, close, matches, currentLeagueId }) => {
    const { newPlayerState: newPlayerState, dispatchNewPlayer } = useNewPlayer();
    const playerProfileContext = useContext(PlayerProfileContext);
    const history = useHistory();
    const matchMetaData: MatchMetaData = {
        hasEbisId: false,
        onEbisRoster: false,
        onPartnerRoster: false,
        hasHardMatch: false,
        matches: [],
    };

    const playerMatches: Array<PlayerMatch> = [];

    const createNewPlayer = (playerInfo: Player): void => {
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", RouteConstants.NEW_PROFILE_SUFFIX);
        playerProfileContext.state = initialState;
        playerProfileContext.state.player.partnerClubInfoId = playerInfo.partnerClubInfoId;
        playerProfileContext.state.player.lastName = playerInfo.lastName;
        playerProfileContext.state.player.firstName = playerInfo.firstName;
        playerProfileContext.state.player.dateOfBirthAsString = playerInfo.dateOfBirthAsString;
        playerProfileContext.state.player.partnerLeagueId = currentLeagueId;
        playerProfileContext.state.player.birthCountry = playerInfo.birthCountry;
        playerProfileContext.state.player.birthCountryId = playerInfo.birthCountryId;
        playerProfileContext.state.player.birthCountrySelected = playerInfo.birthCountrySelected;
        playerProfileContext.state.player.personTypeId = PersonType.PARTNER_PLAYER;
        history.push(route);
    };

    playerMatches.push({
        ...newPlayerState.playerInfo,
        fullName: newPlayerState.playerInfo.lastName + ", " + newPlayerState.playerInfo.firstName,
    });

    if (matches && matches.matches && matches.matches.length > 0) {
        matchMetaData.hasHardMatch = matches.hasHardMatch;

        matches.matches.forEach((match, number) => {
            let onRosterOf = "";
            if (match.personId && match.personId !== null) matchMetaData.hasEbisId = true;
            if (match.partnerClubInfoId && match.partnerClubInfoId !== null) {
                matchMetaData.onPartnerRoster = true;
                onRosterOf = match.partnerClubName;
            }
            if (match.mlbClubInfoId && match.mlbClubInfoId !== null) {
                matchMetaData.onEbisRoster = true;
                onRosterOf = match.mlbClubName;
            }

            if (
                match.hardMatch === true &&
                newPlayerState.isDefaulted === false &&
                matchMetaData.onPartnerRoster !== true
            ) {
                newPlayerState.selectedPlayerId = match.personId;
                newPlayerState.isDefaulted = true;
            }
            playerMatches.push({
                ...match,
                fullName: match.lastName + ", " + match.firstName,
                onRosterOf: onRosterOf,
                matchPercent: Math.trunc(100.0 * match.matchScore) + "%",
            });
        });
    }

    const onClose = () => {
        newPlayerState.selectedPlayerId = undefined;
        newPlayerState.isDefaulted = false;
        close();
    };

    const showAlert = useAlert();
    const addPlayerToRoster = (clubInfoId, playerId) => {
        PartnerRosterApi.addPlayerToRoster(clubInfoId, playerId).then(addedPlayer => {
            showAlert("Saved", "success");
            dispatchNewPlayer({ type: NewPlayerActions.DISPLAY_MATCHES_CHANGED, value: false });
        });
    };

    return (
        <div>
            <Modal show={show} onClose={onClose} size="lg">
                <ModalHeaderWithTitleAndClose title="The info entered matches an existing player" close={onClose} />
                <ModalBody>
                    <PlayerMatchModalBodyContent matchData={playerMatches} matchMetaData={matchMetaData} />
                </ModalBody>

                <ModalFooter>
                    <StickyFooterButtons>
                        {(!newPlayerState.selectedPlayerId || newPlayerState.selectedPlayerId === null) &&
                            !matchMetaData.hasHardMatch && (
                                <PrimaryOutlineButton onClick={event => createNewPlayer(newPlayerState.playerInfo)}>
                                    Continue Creating New Player
                                </PrimaryOutlineButton>
                            )}
                        {newPlayerState.selectedPlayerId && (
                            <PrimaryOutlineButton
                                onClick={() => {
                                    addPlayerToRoster(newPlayerState.selectedClub.id, newPlayerState.selectedPlayerId);
                                    history.push(
                                        RouteConstants.PLAYER_PROFILE.replace(
                                            ":playerId",
                                            newPlayerState.selectedPlayerId,
                                        ),
                                    );
                                }}
                            >
                                Add to Roster
                            </PrimaryOutlineButton>
                        )}
                        <PrimaryOutlineButton onClick={onClose}>Cancel</PrimaryOutlineButton>
                    </StickyFooterButtons>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PlayerMatchModal;
