import React, { useReducer } from "react";
import PropTypes from "prop-types";
import {
    RequestsContext,
    initialState,
    RequestsState,
    RequestsAction,
    RequestsActions,
} from "../../contexts/requests/requests.context";

const RequestsProvider: React.FC = ({ children }) => {
    const reducer: React.Reducer<RequestsState, RequestsAction> = (state, action) => {
        switch (action.type) {
            case RequestsActions.INIT:
                return {
                    ...state,
                    clubLookup: action.clubLookup,
                    statusLkup: action.statusLkup,
                    selectedClub: action.selectedClub,
                    form: {
                        ...state.form,
                        clubInfoId: action.value,
                    },
                };
            case RequestsActions.SET_REQUESTS:
                return {
                    ...state,
                    requests: action.requests,
                };
            case RequestsActions.SET_SELECTED_CLUB:
                return {
                    ...state,
                    selectedClub: action.selectedClub,
                    form: {
                        ...state.form,
                        clubInfoId: Number(action.selectedClub.id),
                    },
                };
            case RequestsActions.SET_SELECTED_STATUS:
                return {
                    ...state,
                    selectedStatus: action.selectedStatus,
                    form: {
                        ...state.form,
                        statusId: Number(action.selectedStatus.id),
                    },
                };
            case RequestsActions.SET_SELECTED_YEAR:
                return {
                    ...state,
                    selectedYear: action.selectedYear,
                    form: {
                        ...state.form,
                        year: Number(action.selectedYear.value),
                    },
                };
            case RequestsActions.SET_SELECTED_REQUEST:
                return {
                    ...state,
                    selectedRequest: action.selectedRequest,
                };
            case RequestsActions.SET_SELECTED_REQUEST_STATUS:
                return {
                    ...state,
                    selectedRequest: {
                        ...state.selectedRequest,
                        statusId: action.value,
                    },
                };
            case RequestsActions.TOGGLE_ACCEPT_REQUEST_MODAL:
                return {
                    ...state,
                    showAcceptRequestModal: action.showAcceptRequestModal,
                    selectedRequest: action.selectedRequest,
                };
            case RequestsActions.TOGGLE_APPROVE_REQUEST_MODAL:
                return {
                    ...state,
                    showApproveRequestModal: action.showApproveRequestModal,
                    selectedRequest: action.selectedRequest,
                };
            case RequestsActions.TOGGLE_DENY_REQUEST_MODAL:
                return {
                    ...state,
                    showDenyRequestModal: action.showDenyRequestModal,
                    selectedRequest: action.selectedRequest,
                };
            default:
                return { ...state };
        }
    };

    const [state, dispatchRequests] = useReducer(reducer, initialState);

    return <RequestsContext.Provider value={{ state, dispatchRequests }}>{children}</RequestsContext.Provider>;
};

RequestsProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default RequestsProvider;
