import React, { createContext, useContext } from "react";
import { PersonPartnerHistory, PersonPartnerHistoryForm } from "../../models/history/history.models";
import { Lookup } from "../../models/lookups/lookups.models";
import { BestTable } from "best-common-react";

export type History = {};

type PersonPartnerHistoryContext = {
    state: PersonPartnerHistoryState;
    dispatchHistory: React.Dispatch<PersonPartnerHistoryAction>;
};

const getInitialDateFrom = (): Date => {
    const today: Date = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
};

export type PersonPartnerHistoryState = {
    clubLookup?: Array<Lookup>;
    activityLookup?: Array<Lookup>;
    selectedActivity?: Lookup;
    selectedClub?: Lookup;
    history?: Array<PersonPartnerHistory>;
    form?: PersonPartnerHistoryForm;
    formLoaded?: boolean;
};

export const initialState: PersonPartnerHistoryState = {
    clubLookup: [],
    activityLookup: [],
    selectedActivity: { id: "1", value: "All Activity" },
    selectedClub: { id: "0", value: "All Teams" },
    history: [],
    formLoaded: false,
    sortColumns: { key: "date", direction: BestTable.ASC },
    form: {
        range: true,
        selectedDate: new Date(),
        dateFrom: getInitialDateFrom(),
        dateTo: new Date(),
        activityTypeId: 1,
        clubId: 0,
        leagueId: null,
        searchText: null,
    },
};

const PersonPartnerHistoryContext = createContext<PersonPartnerHistoryContext>({
    state: initialState,
    dispatchHistory: null,
});

export const PersonPartnerHistoryActions = {
    LOOKUPS_LOADED: "INITIAL_DATA_LOADED",
    HISTORY_CHANGED: "HISTORY_CHANGED",
    ACTIVITY_CHANGED: "ACTIVITY_CHANGED",
    CLUB_CHANGED: "CLUB_CHANGED",
    FORM_FIELD_CHANGED: "FORM_FIELD_CHANGED",
};

export interface PersonPartnerHistoryAction extends PersonPartnerHistoryState {
    type?: string;
    property?: string;
    value?: any;
}

export const usePersonPartnerHistory = (): PersonPartnerHistoryContext => {
    const context = useContext<PersonPartnerHistoryContext>(PersonPartnerHistoryContext);

    if (context == undefined) {
        throw new Error(`useHistory must be used within a PersonPartnerHistoryContext`);
    }
    return context;
};

export default PersonPartnerHistoryContext;
