import React from "react";
import styled from "styled-components";
import { BestLabel } from "best-common-react";

const BestLabelStyled = styled(BestLabel)`
    font-size: 12px;
    color: ${props => props.theme["Gray"]};
    font-family: Helvetica;
    margin-top: 8px;
`;

export default BestLabelStyled;
