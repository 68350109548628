import React, { useEffect, useContext, FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PartnerRosterApi } from "../../api/PartnerRosterApi";
import { BestTable, Icon, PrimaryOutlineButton, BestSelect, AnchorLink } from "best-common-react";
import { Column } from "../../models/best-common-react/tables.component";
import RostersContext, { RostersActions } from "../../contexts/rosters/rosters.context";
import RemovePlayerModal from "./remove-player.modal";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { formatDate, formatDateStr } from "../../modules/dates/dates";
import { Player } from "../../models/player/player.models";
import { LargeAndLargerView, MediumAndSmallerView } from "../../modules/view/views";
import PartnerTable from "./../common/partner-table.component";

interface Props {
    currentLeagueId: number;
}

interface PlayerDisplayInfo {
    displayName: string;
    position: string;
    clubName: string;
    batThrowSide: string;
    birthDate: string;
}

const RosterTable: FC<Props> = ({ currentLeagueId }): JSX.Element => {
    const { state, dispatchRosters } = useContext(RostersContext);

    const history = useHistory();

    const { canEditClubId, canEditLeagueId } = useAuth();

    const browserHistory = useHistory();

    useEffect(() => {
        if (
            state.selectedClub.id !== null &&
            state.selectedClub.id !== undefined &&
            Number(state.selectedClub.id) > 0
        ) {
            PartnerRosterApi.getClubRoster(Number(state.selectedClub.id)).then(data => {
                dispatchRosters({
                    type: RostersActions.SET_SELECTED_CLUB_ROSTER,
                    selectedClubRoster: data,
                });
            });
        }
    }, [state.selectedClub]);

    const TableWrapper = styled.div`
        & .bcr-table-header {
            background: ${props => props.theme["LighterBlue"]};
        }
        &. bcr-header-cell {
            color: ${props => props.theme["Dark"]};
        }
    `;

    const GrayItalisized = styled.div`
        font-size: 12px;
        font-style: italic;
        color: ${props => props.theme["Gray"]};
    `;

    const RosterList = styled.div`
        overflow: auto;
        max-height: 80vh;
        max-width: 100%;
    `;

    const RosterListItem = styled.div`
        border: 1px solid ${props => props.theme["LighterBlue"]};
        margin-bottom: 2px;
        display: flex;
        max-width: 100%;
        margin-left: 0vw;
    `;

    const RosterListRow = styled.div`
        justify-content: space-between;
        background-color: white;
        padding: 4px;
        min-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    `;

    const RosterListDeleteSection = styled.div`
        background-color: ${props => props.theme["LightBlue"]};
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 5%;
        &:hover {
            .fa-deleteIcon {
                color: ${props => props.theme["background"]};
            }
        }
        .fa-deleteIcon {
            color: white;
        }
    `;

    const closeRemovePlayerModal = () => {
        const emptyPlayer = {
            firstName: null,
            lastName: null,
            position: null,
            partnerClubInfoId: null,
            dateOfBirthAsString: null,
            birthCountryId: null,
        };
        dispatchRosters({
            type: RostersActions.TOGGLE_REMOVE_PLAYER_MODAL,
            showRemovePlayerModal: false,
            selectedPlayer: emptyPlayer,
        });
    };

    const openRemovePlayerModal = player => {
        dispatchRosters({
            type: RostersActions.TOGGLE_REMOVE_PLAYER_MODAL,
            selectedPlayer: player,
            showRemovePlayerModal: true,
        });
    };

    const trashIconFormatter = ({ value }) =>
        canEditClubId(Number(state.selectedClub.id)) ? (
            <Icon iconName="fa-trash" onClick={() => openRemovePlayerModal(value)} />
        ) : null;

    const addNewPlayerToRoster = state => {
        if (state && state.selectedClub != null && state.selectedClub != undefined) {
            history.push(RouteConstants.NEW_PLAYER, state.selectedClub);
        }
    };

    const playerFormatter = ({ row, format }) => {
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", row.playerInfo.personId);
        return <AnchorLink onClick={() => browserHistory.push(`${route}`)}>{row.displayName}</AnchorLink>;
    };

    const getRosterItems = (player: Player) => {
        return (
            <RosterListItem className="row" key={player.personId}>
                <RosterListDeleteSection className="col">
                    {canEditClubId(Number(state.selectedClub.id)) ? (
                        <Icon
                            iconName="fa-trash"
                            className="fa-deleteIcon"
                            onClick={() => openRemovePlayerModal(player)}
                        />
                    ) : null}
                </RosterListDeleteSection>
                <div className="col">
                    <RosterListRow className="row">
                        <div>
                            <AnchorLink
                                onClick={() =>
                                    browserHistory.push(
                                        `${RouteConstants.PLAYER_PROFILE.replace(
                                            ":playerId",
                                            String(player.personId),
                                        )}`,
                                    )
                                }
                            >
                                {player.lastName +
                                    ", " +
                                    (player.rosterFirstName === null ? player.firstName : player.rosterFirstName)}
                            </AnchorLink>
                            {" - "}
                            <span>{player.position} </span>
                        </div>
                        <div>
                            <GrayItalisized>{player.battingSide + "/" + player.throwingSide}</GrayItalisized>
                        </div>
                    </RosterListRow>
                    <RosterListRow className="row">
                        <div>{player.partnerClubName}</div>
                        <div>
                            <GrayItalisized>{formatDateStr(player.dateOfBirthAsString)}</GrayItalisized>
                        </div>
                    </RosterListRow>
                </div>
            </RosterListItem>
        );
    };

    const columns: Array<Column> = [
        { name: "", key: "playerInfo", width: 50, formatter: trashIconFormatter },
        { name: "Player", key: "displayName", formatter: playerFormatter, sortable: true },
        { name: "Pos", key: "position", sortable: true },
        { name: "Club", key: "clubName", sortable: true },
        { name: "Bats/Throws", key: "batThrowSide", sortable: true },
        { name: "Birth Date", key: "birthDate", sortable: true },
    ];

    const renderClubRosterData = (roster: Array<Player>): Array<PlayerDisplayInfo> => {
        if (roster !== null && roster !== undefined && roster.length > 0) {
            return roster.map(player => {
                return {
                    playerInfo: player,
                    displayName:
                        player.lastName +
                        ", " +
                        (player.rosterFirstName === null ? player.firstName : player.rosterFirstName),
                    position: player.position,
                    clubName: player.partnerClubName,
                    batThrowSide: player.battingSide + "/" + player.throwingSide,
                    birthDate: formatDateStr(player.dateOfBirthAsString),
                };
            });
        }
        return [];
    };

    return (
        <div className="mx-3">
            <RemovePlayerModal show={state.showRemovePlayerModal} close={closeRemovePlayerModal} />
            <div className="d-flex row mb-2">
                <div className="col-12 col-md-4">
                    <BestSelect
                        name="clubs"
                        options={state.clubLookup}
                        placeholder="Club"
                        value={state.selectedClub}
                        onChange={value =>
                            dispatchRosters({ type: RostersActions.SET_SELECTED_CLUB, selectedClub: value })
                        }
                    />
                </div>
                <PrimaryOutlineButton
                    className="align-self-end"
                    disabled={!canEditClubId(Number(state.selectedClub.id))}
                    onClick={() => {
                        addNewPlayerToRoster(state);
                    }}
                >
                    New Player
                </PrimaryOutlineButton>
            </div>
            <LargeAndLargerView>
                <TableWrapper className="thin-scroll">
                    <GrayItalisized>{renderClubRosterData(state.selectedClubRoster).length} Players</GrayItalisized>
                    <PartnerTable
                        data={renderClubRosterData(state.selectedClubRoster)}
                        columns={columns}
                        defaultSort="displayName"
                    />
                </TableWrapper>
            </LargeAndLargerView>

            <MediumAndSmallerView>
                <GrayItalisized>{state.selectedClubRoster.length} Players</GrayItalisized>
                <RosterList>
                    {state.selectedClubRoster.sort((a, b) => (a.lastName > b.lastName ? 1 : -1)).map(getRosterItems)}
                </RosterList>
            </MediumAndSmallerView>
        </div>
    );
};

export default RosterTable;
