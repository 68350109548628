import React, { createContext, Dispatch, useContext } from "react";
import { Lookup } from "../../models/lookups/lookups.models";
import { Player } from "../../models/player/player.models";

type NewPlayerContext = {
    newPlayerState: NewPlayerState;
    dispatchNewPlayer: Dispatch<NewPlayerAction>;
};

export type NewPlayerState = {
    selectedClub?: Lookup | any;
    selectedCountry?: Lookup | any;
    leagueId?: number;
    clubLookup?: Array<Lookup>;
    countryLookup?: Array<Lookup>;
    playerInfo?: Player;
    playerMatches?: Array<Player>;
    displayMatchModal?: boolean;
    isDefaulted?: boolean;
};

export const initialState: NewPlayerState = {
    selectedClub: " ",
    selectedCountry: " ",
    leagueId: null,
    clubLookup: [],
    countryLookup: [],
    displayMatchModal: false,
    isDefaulted: false,
    playerInfo: {
        partnerClubInfoId: null,
        birthCountryId: null,
        dateOfBirthAsString: "",
        firstName: "",
        lastName: "",
    },
};

const NewPlayerContext = createContext<NewPlayerContext>({
    newPlayerState: initialState,
    dispatchNewPlayer: null,
});

export const NewPlayerActions = {
    INITIAL_DATA_LOADED: "INITIAL_DATA_LOADED",
    CLUB_CHANGED: "CLUB_CHANGED",
    DATA_CHANGED: "DATA_CHANGED",
    COUNTRY_CHANGED: "COUNTRY_CHANGED",
    FORM_FIELD_CHANGED: "FORM_FIELD_CHANGED",
    DATE_FIELD_CHANGED: "DATE_FIELD_CHANGED",
    DISPLAY_MATCHES_CHANGED: "DISPLAY_MATCHES_CHANGED",
    MATCHES_FOUND: "MATCHES_FOUND",
    SELECT_MATCH: "SELECT_MATCH",
};

export interface NewPlayerAction extends NewPlayerState {
    type?: string;
    property?: string;
    value?: any;
}

export const useNewPlayer = (): NewPlayerContext => {
    const context = useContext<NewPlayerContext>(NewPlayerContext);

    if (context == undefined) {
        throw new Error(`useNewPlayer must be used within a NewPlayerContext`);
    }
    return context;
};

export default NewPlayerContext;
