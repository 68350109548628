import React, { useState, useContext } from "react";
import PartnerLogo from "./../../assets/partner-league.png";
import NavigationItem from "./navigation-item.component";
import { Icon } from "best-common-react";
import { NavigationLogo } from "best-common-react";
import styled from "styled-components";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";
import { RouteConstants } from "./../../constants/RouteConstants";
import { AuthContext } from "./../../contexts/AuthContext";
import PartnerLeagueApi from "./../../api/PartnerLeagueApi";
import frontendGitInfo from "../../gitInfo.txt";

const Navigation = ({}) => {
    const { userId, logout } = useContext(AuthContext);
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const clickMobileNav = () => {
        setIsMobileOpen(false);
    };

    let gitInfo = frontendGitInfo;

    if (gitInfo === undefined) gitInfo = "";

    const builds = [{ name: "frontend", build: gitInfo.indexOf(" -> ") > 0 ? gitInfo.split(" -> ")[1] : gitInfo }];

    PartnerLeagueApi.getBackEndVersion().then(response => {
        let gitInfo;
        let buildVersion;
        let buildTime;
        if (response.hasOwnProperty("build")) {
            if (response.build.hasOwnProperty("version")) {
                buildVersion = `${response.build.version}`;
            }
            if (response.build.hasOwnProperty("time")) {
                buildTime = `${response.build.time}`;
            }
        }
        if (response.hasOwnProperty("git") && response.git.hasOwnProperty("commit")) {
            gitInfo = `${response.git.branch}-${response.git.commit.id}`;
        }

        const backendInfo = [buildVersion, buildTime, gitInfo].join(" | ");
        if (backendInfo) {
            builds.push({ name: "backend", build: backendInfo });
        }
    });

    const MobileNavWrapper = styled.div`
        height: 100vh;
        width: 100%;
        background-color: ${props => props.theme["DarkBlue"]};
    `;

    const MobileUserBar = styled.div`
        background-color: ${props => props.theme["Blue"]};
        color: ${props => props.theme["LightBlue"]};
        width: 100%;
        height: 2.5rem;
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        padding-top: 0.5rem;
        border-top: 1px solid black;
    `;

    const NavigationWrapper = styled.div`
        z-index: 4;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
        position: fixed;
        width: 100%;
    `;

    const UserBar = styled.div`
        height: 1rem;
        font-size: 0.75rem;
        text-align: end;
        padding-right: 0.5rem;
        font-family: helvetica;
    `;

    const UserNameWrapper = styled.span`
        position: relative;
        bottom: 0.4rem;
    `;

    const NavigationBar = styled.div`
        background-color: ${props => props.theme["DarkBlue"]};
        height: 2.438rem;
    `;

    const Logo = styled(NavigationLogo)`
        position: relative;
        z-index: 3;
        filter: drop-shadow(0 4px 3px rgba(4, 30, 66, 0.4));

        @media (max-width: ${PixelBreakpointConstants.LARGE - 1}px) {
            top: 0.25rem;
            width: 2.875rem;
            height: 2.875rem;
        }

        @media (min-width: ${PixelBreakpointConstants.LARGE}px) {
            width: 3.78rem;
            height: 3.78rem;
            margin-left: 8px;
            top: -0.5rem;
        }
    `;

    const Spacer = styled.div`
        width: 32px;
        @media (min-width: ${PixelBreakpointConstants.LARGE}px) {
            display: none;
        }
    `;

    const OpenIcon = styled(Icon)`
        padding-top: 7px;
        color: white;
        :hover {
            color: white;
        }
    `;

    const CloseIcon = styled(OpenIcon)`
        padding-top: 8px;
    `;

    const DropdownButton = styled.a`
        position: relative;
        bottom: 0.3rem;
        font-family: helvetica;
    `;

    const DropdownIcon = styled(Icon)`
        position: relative;
        top: 2px;
        color: #002e6d;
    `;

    return (
        <NavigationWrapper>
            <UserBar className="d-none d-lg-block">
                <UserNameWrapper>
                    <span className="dropdown">
                        <DropdownButton
                            className="btn btn-sm btn-link"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="mr-1">{userId && userId !== null ? userId() : ""}</span>
                            <DropdownIcon iconName="fa-angle-down" />
                        </DropdownButton>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <span className="dropdown-item" onClick={logout}>
                                logout
                            </span>
                        </div>
                    </span>
                </UserNameWrapper>
            </UserBar>
            <NavigationBar className="d-flex justify-content-between justify-content-lg-start">
                <div
                    className="d-block d-lg-none ml-3 mt-1"
                    style={{ width: "15px" }}
                    onClick={() => {
                        setIsMobileOpen(isMobileOpen === false);
                    }}
                >
                    {isMobileOpen === false ? <OpenIcon iconName="fa-bars" /> : <CloseIcon iconName="fa-times" />}
                </div>
                <Logo logo={PartnerLogo} builds={builds} />
                <Spacer>&nbsp;</Spacer>
                <div style={{ marginLeft: "12px" }} className="d-none d-lg-flex">
                    <NavigationItem name="History" link={RouteConstants.HISTORY} onClick={null} />
                    <NavigationItem name="Rosters" link={RouteConstants.ROSTERS} onClick={null} />
                    <NavigationItem name="Requests" link={RouteConstants.REQUESTS} onClick={null} />
                    <NavigationItem name="New Player" link={RouteConstants.NEW_PLAYER} onClick={null} />
                </div>
            </NavigationBar>

            {isMobileOpen === true && (
                <MobileNavWrapper className="d-flex flex-column d-lg-none">
                    <MobileUserBar>
                        <span>{userId && userId !== null ? userId() : ""}</span>
                    </MobileUserBar>

                    <NavigationItem name="History" link={RouteConstants.HISTORY} onClick={clickMobileNav} />
                    <NavigationItem name="Rosters" link={RouteConstants.ROSTERS} onClick={clickMobileNav} />
                    <NavigationItem name="Requests" link={RouteConstants.REQUESTS} onClick={clickMobileNav} />
                    <NavigationItem name="New Player" link={RouteConstants.NEW_PLAYER} onClick={clickMobileNav} />
                    <NavigationItem name="Logout" onClick={logout} link="" />
                </MobileNavWrapper>
            )}
        </NavigationWrapper>
    );
};

export default Navigation;
