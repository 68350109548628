import React from "react";
import { NumberInput } from "best-common-react";

const UniformInput = ({ value, onChange, ...rest }) => {
    const handleChange = value => {
        value = value.substring(0, 2);
        onChange(value);
    };

    return <NumberInput onChange={handleChange} value={value} {...rest} />;
};

export default UniformInput;
