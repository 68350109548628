import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import styled from "styled-components";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";

const NavigationItem = ({ name, link, onClick }) => {
    const StyledLink = styled(Link).attrs({})`
        color: white;
        :hover {
            color: #92a5ba;
            text-decoration: none;
        }
    `.withComponent(({ ...props }) => <Link {...props} />);

    const location = useLocation();

    const NavItem = styled.div`
        cursor: pointer;
        color: white;
        padding: 0.5rem 2rem 0 2rem;
        font-family: Helvetica;

        background-color: ${props => (props.active ? props.theme["Blue"] : props.theme["DarkBlue"])};

        @media (max-width: ${PixelBreakpointConstants.LARGE - 1}px) {
            border-top: 1px solid ${props => props.theme["Blue"]};
            height: 2.5rem;
        }
    `;

    const click = () => {
        if (onClick && onClick !== null) {
            onClick();
        }
    };

    return (
        <NavItem
            active={link === location.pathname}
            onClick={() => {
                click();
            }}
        >
            <StyledLink active={link == location.pathname ? "true" : "false"} to={link}>
                {name}
            </StyledLink>
        </NavItem>
    );
};

export default NavigationItem;
