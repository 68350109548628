import React, { Dispatch, createContext } from "react";
import { FileAttachment } from "../../models/common/file-attachments.model";
import { Lookup } from "../../models/lookups/lookups.models";
import { Player } from "../../models/player/player.models";

type PlayerProfileContext = {
    state: PlayerProfileState;
    dispatchPlayerProfile: Dispatch<PlayerProfileAction>;
};

type PlayerConditionalLookups = {
    birthDistrictLabel?: string;
    birthDistricts?: Array<Lookup>;
    birthCities?: Array<Lookup>;
    birthSectors?: Array<Lookup>;
    hasSector?: boolean;
    hasDropdown?: boolean;
    addressDistrictLabel?: string;
    addressDistricts?: Array<Lookup>;
    addressCities?: Array<Lookup>;
    addressSectors?: Array<Lookup>;
    addressHasSector?: boolean;
    addressHasDropdown?: boolean;
    addressHasZip?: boolean;
    allClubs?: Array<Array<Lookup>>;
};

export type PlayerProfileState = {
    player?: Player;
    lookups?: any;
    conditionalLookups?: PlayerConditionalLookups;
    formDirty?: boolean;
    playerLoaded?: boolean;
    userCanEditProfile?: boolean;
    displayCloseConfirmModal?: boolean;
};

export const PlayerProfileActions = {
    DATA_CHANGED: "DATA_CHANGED",
    LOOKUPS_LOADED: "LOOKUPS_LOADED",
    INIT_PLAYER: "INIT_PLAYER",
    SET_CONDITIONAL_LOOKUPS: "SET_CONDITIONAL_LOOKUPS",
    ATTACHED_FILE: "ATTACHED_FILE",
    PLAYER_SAVED: "PLAYER_SAVED",
    NEW_PLAYER: "NEW_PLAYER",
    CHANGE_CONFIRM_MODAL: "CHANGE_CONFIRM_MODAL",
};

export interface PlayerProfileAction extends PlayerProfileState {
    type?: string;
    property?: string;
    value?: any;
    fileAttachments?: Array<FileAttachment>;
}

export const initialState: PlayerProfileState = {
    player: {
        personId: 0,
        partnerClubInfoId: 0,
        lastName: "",
        firstName: "",
        middleName: "",
        extendedLastName: "",
        feetSelected: {},
        inchesSelected: {},
        rosterFirstName: "",
        suffix: "",
        playerPosition: {},
        playerPositionId: null,
        position: "",
        battingSide: "",
        throwingSide: "",
        birthCountrySelected: {},
        dateOfBirthAsString: "",
        birthCountryId: null,
        birthStateId: null,
        birthStateSelected: {},
        birthDistrictId: null,
        birthDistrict: "",
        birthDistrictSelected: {},
        birthCity: "",
        birthCityId: null,
        birthCitySelected: {},
        birthProvince: "",
        birthProvinceId: null,
        birthProvinceSelected: {},
        birthSector: "",
        birthSectorId: null,
        birthSectorSelected: {},
        uniformNumber: null,
        primaryAddressZip: null,
        fileAttachments: [],
        primaryEmail: "",
        primaryEmailTypeId: null,
        primaryEmailType: {},
        weight: null,
        primaryPhoneNumber: "",
        primaryPhoneType: {},
        primaryPhoneTypeId: null,
        primaryPhoneCountry: {},
        primaryAddressTypeId: null,
        primaryAddressType: {},
        primaryAddressCountry: {},
        primaryAddressDistrictSelected: {},
        primaryAddressCitySelected: {},
        primaryAddressStateSelected: {},
        primaryAddressStateId: null,
        primaryAddressState: "",
        primaryAddressProvinceSelected: {},
        primaryAddressSectorSelected: {},
        primaryAddressCity: null,
        primaryAddress1: null,
        primaryAddress2: null,
        primaryAddress3: null,
    },
    playerLoaded: false,
    lookups: {},
    conditionalLookups: {
        birthDistrictLabel: "",
        birthDistricts: [],
        birthCities: [],
        birthSectors: [],
        hasSector: false,
        hasDropdown: false,
        addressDistrictLabel: "",
        addressDistricts: [],
        addressCities: [],
        addressSectors: [],
        addressHasSector: false,
        addressHasDropdown: false,
        addressHasZip: false,
    },
    formDirty: false,
    userCanEditProfile: false,
    displayCloseConfirmModal: false,
};

const PlayerProfileContext = createContext<PlayerProfileContext>({
    state: initialState,
    dispatchPlayerProfile: null,
});

export default PlayerProfileContext;
