import React, { useEffect, useContext, FC, useState } from "react";
import { useLocation } from "react-router";
import { PlayerApi } from "../../api/PlayerApi";
import { DatePickerInput, BestSelect, PrimaryButton, Input, RequiredInputWithLabel } from "best-common-react";
import { LookupApi } from "../../api/LookupApi";
import NewPlayerContext, { NewPlayerActions, useNewPlayer } from "../../contexts/newplayer/newplayer.context";
import { PersonType, Player } from "../../models/player/player.models";
import PlayerMatchModal from "./player-match.modal";
import { useAuth } from "./../../contexts/AuthContext";
import { RouteConstants } from "../../constants/RouteConstants";
import { useHistory } from "react-router-dom";
import { PixelBreakpointConstants } from "./../../constants/PixelBreakpointConstants";
import PlayerProfileContext, { initialState } from "../../contexts/player-profile/player-profile.context";
import RequiredInputWithLabelStyled from "../common/required-input-with-label-styled.component";
import styled from "styled-components";

interface Props {
    currentLeagueId: number;
}

const NewPlayer: FC<Props> = ({ currentLeagueId }): JSX.Element => {
    const { newPlayerState: newPlayerState, dispatchNewPlayer } = useNewPlayer();

    const location = useLocation();
    const { partnerUserDetails } = useAuth();
    const history = useHistory();
    const playerProfileContext = useContext(PlayerProfileContext);

    const PrimaryButtonStyled = styled(PrimaryButton)`
        &&&& {
            font-family: Helvetica;
            font-size: 14px;
            font-weight: bold;
            height: 26px;
            color: #ffffff;
            background-color: ${props => props.theme["LightBlue"]};
            padding: 0px 15px 0px 15px;
            border-radius: 6px;
            border: solid 1px ${props => props.theme["LightBlue"]};
            :disabled {
                background-color: ${props => props.theme["LightGray"]};
                border-color: ${props => props.theme["LighterBlue"]};
                color: ${props => props.theme["LighterBlue"]};
            }
        }
    `;

    const ButtonWrapper = styled.div`
        display: flex;
        flex-direction: row-reverse;
    `;

    useEffect(() => {
        if (currentLeagueId !== null && currentLeagueId !== undefined) {
            Promise.all([
                LookupApi.getParentLookup("PARTNER_LEAGUE_CLUB", currentLeagueId),
                LookupApi.getLookups("COUNTRIES"),
            ]).then(([clubLookup, lookups]) => {
                lookups["COUNTRIES"].shift();
                if (
                    partnerUserDetails.isLeaguePresident &&
                    !partnerUserDetails.isBoc &&
                    partnerUserDetails.leagueIds.length > 0 &&
                    Number(currentLeagueId) !== partnerUserDetails.leagueIds[0]
                ) {
                    clubLookup = [];
                } else if (
                    partnerUserDetails.isClubUser &&
                    partnerUserDetails.leagueIds.length > 0 &&
                    partnerUserDetails.clubIds.length > 0
                ) {
                    if (partnerUserDetails.leagueIds[0] === Number(currentLeagueId)) {
                        clubLookup = clubLookup.filter(club => Number(club.id) === partnerUserDetails.clubIds[0]);
                        console.log("club user and league selected: ", clubLookup);
                    } else {
                        console.log("club user and league not selected");
                        clubLookup = [];
                    }
                }
                dispatchNewPlayer({
                    type: NewPlayerActions.INITIAL_DATA_LOADED,
                    clubLookup: clubLookup,
                    countryLookup: lookups["COUNTRIES"],
                    leagueId: currentLeagueId,
                });
            });
        }

        if (location.state != null && location.state != undefined) {
            dispatchNewPlayer({
                type: NewPlayerActions.CLUB_CHANGED,
                selectedClub: location.state,
            });
        }
    }, []);

    const setDisplayMatchModal = (value: boolean): void => {
        dispatchNewPlayer({
            type: NewPlayerActions.DISPLAY_MATCHES_CHANGED,
            value: value,
        });
    };

    const createNewPlayer = (playerInfo: Player): void => {
        const route = RouteConstants.PLAYER_PROFILE.replace(":playerId", RouteConstants.NEW_PROFILE_SUFFIX);
        playerProfileContext.state = initialState;
        playerProfileContext.state.player.partnerClubInfoId = playerInfo.partnerClubInfoId;
        playerProfileContext.state.player.lastName = playerInfo.lastName;
        playerProfileContext.state.player.firstName = playerInfo.firstName;
        playerProfileContext.state.player.dateOfBirthAsString = playerInfo.dateOfBirthAsString;
        playerProfileContext.state.player.partnerLeagueId = currentLeagueId;
        playerProfileContext.state.player.birthCountry = playerInfo.birthCountry;
        playerProfileContext.state.player.birthCountryId = playerInfo.birthCountryId;
        playerProfileContext.state.player.birthCountrySelected = playerInfo.birthCountrySelected;
        playerProfileContext.state.player.personTypeId = PersonType.PARTNER_PLAYER;
        history.push(route);
    };

    const checkForMatches = (playerInfo: Player): void => {
        PlayerApi.checkNewPlayerMatches(newPlayerState.playerInfo).then(matchList => {
            if (matchList.matches.length > 0) {
                dispatchNewPlayer({
                    type: NewPlayerActions.MATCHES_FOUND,
                    value: matchList,
                });
            } else {
                createNewPlayer(playerInfo);
            }
        });
    };

    const isCreateButtonDisabled = (playerInfo: Player): boolean => {
        return (
            playerInfo.partnerClubInfoId === null ||
            playerInfo.birthCountryId === null ||
            playerInfo.dateOfBirthAsString === "" ||
            playerInfo.firstName === "" ||
            playerInfo.lastName === ""
        );
    };

    return (
        <div>
            <div className="d-flex row mx-2 mb-4">
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2">
                    <RequiredInputWithLabelStyled name="new-player-club-select" label="Club">
                        <BestSelect
                            id="new-player-club-select"
                            value={newPlayerState.selectedClub}
                            placeholder="Select a Team"
                            isMulti={false}
                            onChange={newVal => {
                                dispatchNewPlayer({
                                    type: NewPlayerActions.CLUB_CHANGED,
                                    selectedClub: newVal,
                                });
                            }}
                            options={newPlayerState.clubLookup}
                        />
                    </RequiredInputWithLabelStyled>
                </div>
            </div>
            <div className="d-flex row mx-2 mb-4">
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2">
                    <RequiredInputWithLabelStyled name="first-name" label="First Name">
                        <Input
                            type="text"
                            placeholder=""
                            value={newPlayerState.playerInfo.firstName}
                            onChange={event => {
                                dispatchNewPlayer({
                                    type: NewPlayerActions.FORM_FIELD_CHANGED,
                                    property: "firstName",
                                    value: event.target.value,
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </div>
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2">
                    <RequiredInputWithLabelStyled name="last-name" label="Last Name">
                        <Input
                            type="text"
                            placeholder=""
                            value={newPlayerState.playerInfo.lastName}
                            onChange={event => {
                                dispatchNewPlayer({
                                    type: NewPlayerActions.FORM_FIELD_CHANGED,
                                    property: "lastName",
                                    value: event.target.value,
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </div>
                <div className="col-lg-3 col-md-6 pt-lg-0 pt-md-3 pt-sm-2">
                    <RequiredInputWithLabelStyled name="new-player-country-select" label="Birth Country">
                        <BestSelect
                            id="new-player-country-select"
                            value={newPlayerState.selectedCountry}
                            placeholder="Select a Country"
                            isMulti={false}
                            onChange={newVal => {
                                dispatchNewPlayer({
                                    type: NewPlayerActions.COUNTRY_CHANGED,
                                    selectedCountry: newVal,
                                });
                            }}
                            options={newPlayerState.countryLookup}
                        />
                    </RequiredInputWithLabelStyled>
                </div>
                <div className="col-lg-2 col-md-3 pt-lg-0 pt-md-0 pt-sm-2">
                    <RequiredInputWithLabelStyled name="new-player-date-of-birth" label="Birth Date">
                        <DatePickerInput
                            value={newPlayerState.playerInfo.dateOfBirthAsString}
                            minDate={new Date("1/1/1910")}
                            onChange={newVal => {
                                dispatchNewPlayer({
                                    type: NewPlayerActions.DATE_FIELD_CHANGED,
                                    property: "dateOfBirthAsString",
                                    value: newVal,
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </div>
            </div>
            <div className="d-flex row mx-2 mb-4">
                <div className="col-lg-9 col-md-8 pt-lg-0 pt-md-3 pt-sm-2" />
                <div className="col-lg-2 col-md-3 pt-lg-0 pt-md-0 pt-sm-2">
                    <ButtonWrapper>
                        <PrimaryButtonStyled
                            onClick={event => checkForMatches(newPlayerState.playerInfo)}
                            disabled={isCreateButtonDisabled(newPlayerState.playerInfo)}
                        >
                            Create New Player
                        </PrimaryButtonStyled>
                    </ButtonWrapper>
                </div>
            </div>
            <PlayerMatchModal
                show={newPlayerState.displayMatchModal}
                close={() => setDisplayMatchModal(false)}
                matches={newPlayerState.playerMatches}
                currentLeagueId={currentLeagueId}
            />
        </div>
    );
};

export default NewPlayer;
