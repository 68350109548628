import { datadogRum } from "@datadog/browser-rum";
import { getBranch } from "./constants/BranchConstants";

import App from "./App";
import React from "react";
import ReactDom from "react-dom";

// initialize DataDog APM for non-local
if (process.env.DATADOG_ENABLED === "true") {
    console.log("Datadog RUM enabled.");
    datadogRum.init({
        applicationId: process.env.DATADOG_APP_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: "us5.datadoghq.com",
        service: "partner",
        env: process.env.ENV,
        version: `${getBranch()}-${process.env.COMMITHASH}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
    });

    if (process.env.DATADOG_SESSION_REPLAY_ENABLED === "true") {
        datadogRum.startSessionReplayRecording();
    }
}
ReactDom.render(<App />, document.getElementById("app-container"));
