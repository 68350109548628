import React, { useState, useContext } from "react";
import styled from "styled-components";
import PlayerProfileContext, { PlayerProfileActions } from "../../contexts/player-profile/player-profile.context";
import {
    Collapse,
    BestLabel,
    FormColumn4Wide,
    RequiredInputWithLabel,
    BestDropdown,
    FormColumn2Wide,
    NumberInput,
    BestSelect,
} from "best-common-react";
import { AuthContext } from "../../contexts/AuthContext";
import RequiredInputWithLabelStyled from "../common/required-input-with-label-styled.component";
import CollapseStyled from "../common/collapse-styled.component";
import UniformInput from "../common/uniform-input.component";
import DisabledIconWrapper from "../common/disabled-icon-wrapper.component";

const BaseballInformation = ({}) => {
    const playerProfileContext = useContext(PlayerProfileContext);
    const { player, lookups, userCanEditProfile } = playerProfileContext.state;
    const { partnerUserDetails: userRoleInfo } = useContext(AuthContext);

    const sideOptions = [
        { value: "Left", label: "Left", id: 0 },
        { value: "Right", label: "Right", id: 1 },
        { value: "Switch", label: "Switch", id: 2 },
    ];

    const feetOptions = [
        { value: 4, label: "4 '", id: 4 },
        { value: 5, label: "5 '", id: 5 },
        { value: 6, label: "6 '", id: 6 },
        { value: 7, label: "7 '", id: 7 },
    ];

    const inchesOptions = [
        { value: 0, label: '0 "', id: 0 },
        { value: 1, label: '1 "', id: 1 },
        { value: 2, label: '2 "', id: 2 },
        { value: 3, label: '3 "', id: 3 },
        { value: 4, label: '4 "', id: 4 },
        { value: 5, label: '5 "', id: 5 },
        { value: 6, label: '6 "', id: 6 },
        { value: 7, label: '7 "', id: 7 },
        { value: 8, label: '8 "', id: 8 },
        { value: 9, label: '9 "', id: 9 },
        { value: 10, label: '10 "', id: 10 },
        { value: 11, label: '11 "', id: 11 },
    ];

    const updateHeight = (feet, inches) => {
        const f = feet === undefined ? 0 : feet;
        const i = inches === undefined ? 0 : inches;
        playerProfileContext.dispatchPlayerProfile({
            type: PlayerProfileActions.DATA_CHANGED,
            value: { height: f * 12 + i },
        });
    };

    return (
        <CollapseStyled
            title="Baseball Information"
            startsOpen={true}
            iconAfterTitle={false}
            expandIcon="fa-plus-circle"
            collapseIcon="fa-minus-circle"
            styles={{ borderColor: "#dee5ed" }}
        >
            <div className="row">
                {/* Position */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="profile-height-position"
                        label="Position"
                        required={userCanEditProfile}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            id="profile-height-position"
                            isDisabled={!userCanEditProfile}
                            options={lookups.PRO_PLAYER_POSITIONS}
                            isMulti={false}
                            value={player.playerPosition}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { playerPosition: value, playerPositionId: value.id },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Uniform number */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="uninum" label="Uniform #" required={false}>
                        <UniformInput
                            style={{ color: "#495057" }}
                            value={player.uniformNumber !== null ? player.uniformNumber : ""}
                            name="uninum"
                            disabled={!userCanEditProfile}
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { uniformNumber: value },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Bats */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="profile-height-bats"
                        label="Bats"
                        required={userCanEditProfile}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            id="profile-height-bats"
                            options={sideOptions}
                            isDisabled={!userCanEditProfile}
                            value={
                                player.battingSide === ""
                                    ? {}
                                    : { value: player.battingSide, label: player.battingSide }
                            }
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { battingSide: value.value },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>

                {/* Throws */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled
                        name="profile-height-throws"
                        label="Throws"
                        required={userCanEditProfile}
                        disabled={!userCanEditProfile}
                    >
                        <BestSelect
                            styles={{ singleValue: { color: "#495057" } }}
                            id="profile-height-throws"
                            options={sideOptions}
                            isDisabled={!userCanEditProfile}
                            value={
                                player.throwingSide === ""
                                    ? {}
                                    : { value: player.throwingSide, label: player.throwingSide }
                            }
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { throwingSide: value.value },
                                });
                            }}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
            </div>
            <div className="row">
                {/* Height */}
                <FormColumn4Wide>
                    <div className="row">
                        <FormColumn2Wide>
                            <RequiredInputWithLabelStyled
                                name="profile-height-select"
                                label="Height"
                                required={userCanEditProfile}
                                disabled={!userCanEditProfile}
                            >
                                <BestSelect
                                    styles={{ singleValue: { color: "#495057" } }}
                                    id="profile-height-select"
                                    options={feetOptions}
                                    value={player.feetSelected}
                                    isDisabled={!userCanEditProfile}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { feetSelected: value },
                                        });
                                        updateHeight(value.id, player.inchesSelected.id);
                                    }}
                                />
                            </RequiredInputWithLabelStyled>
                        </FormColumn2Wide>
                        <FormColumn2Wide>
                            <RequiredInputWithLabelStyled
                                name="profile-height-inches"
                                label="&nbsp;"
                                required={false}
                                disabled={!userCanEditProfile}
                            >
                                <BestSelect
                                    styles={{ singleValue: { color: "#495057" } }}
                                    name="profile-height-inches"
                                    options={inchesOptions}
                                    value={player.inchesSelected}
                                    isDisabled={!userCanEditProfile}
                                    onChange={value => {
                                        playerProfileContext.dispatchPlayerProfile({
                                            type: PlayerProfileActions.DATA_CHANGED,
                                            value: { inchesSelected: value },
                                        });
                                        updateHeight(player.feetSelected.id, value.id);
                                    }}
                                />
                            </RequiredInputWithLabelStyled>
                        </FormColumn2Wide>
                    </div>
                </FormColumn4Wide>

                {/* Weight */}
                <FormColumn4Wide>
                    <RequiredInputWithLabelStyled name="profile-weight" label="Weight" required={userCanEditProfile}>
                        <NumberInput
                            style={{ color: "#495057" }}
                            value={player.weight !== null ? player.weight : ""}
                            name="profile-weight"
                            onChange={value => {
                                playerProfileContext.dispatchPlayerProfile({
                                    type: PlayerProfileActions.DATA_CHANGED,
                                    value: { weight: value },
                                });
                            }}
                            disabled={!userCanEditProfile}
                        />
                    </RequiredInputWithLabelStyled>
                </FormColumn4Wide>
            </div>
        </CollapseStyled>
    );
};

export default BaseballInformation;
