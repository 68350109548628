export const RouteConstants = {
    BASE: "/",
    HOME: "/home",
    LOGIN: "/login",
    LOGIN_CALLBACK: "/callback",

    ROSTERS: "/home/rosters",
    HISTORY: "/home/history",
    REQUESTS: "/home/requests",
    NEW_PLAYER: "/home/new-player",
    PLAYER_PROFILE: "/player/:playerId",
    NEW_PROFILE_SUFFIX: "new",
};

export const PathnameToTitle = {
    "/home/rosters": "Rosters",
    "/home/history": "History",
    "/home/requests": "Requests",
    "/home/new-player": "New Player",
};
